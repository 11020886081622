<template>
    <div>
        <h1 class="text-h6">
            {{ $t('MyDocuments.title') }}
        </h1>

        <v-data-iterator
            v-if="!loading"
            :items="allDocuments"
            :items-per-page="allDocuments.length"
            hide-default-footer
        >
            <template #default="{ items }">
                <v-row>
                    <v-col 
                        v-for="item in items.map(item => item.raw)"
                        :key="item.id"
                        cols="12"
                        md="3"
                    >
                        <my-document-item
                            v-if="item.type !== 'extra'"
                            :document="item"
                            @delete="remove(item)"
                            @download="download(item)"
                            @mark="load"
                        />
                        <v-card v-else color="grey-lighten-4" @click="addDocument">
                            <v-card-text v-if="!addedOpen">
                                <div class="px-6 py-16 text-center">
                                    <div class="d-inline-block pa-6 rounded-full bg-white">
                                        <v-icon size="4rem" color="grey-lighten-4">mdi-plus</v-icon>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-text v-else style="min-height: 17.5rem">
                                <span class="text-subtitle-1 d-block text-center">
                                    {{ $t('MyDocuments.btn.chooseDocumentType') }}
                                </span>
                                <v-btn
                                    color="accent"
                                    class="mb-4 mt-6"
                                    size="small"
                                    variant="flat"
                                    block
                                    @click.stop="$router.push({name: 'testament-tool'})"
                                >
                                    <v-icon start>mdi-gavel</v-icon>
                                    {{ $t('OneWord.testament') }}
                                </v-btn>
                                <v-btn
                                    color="accent"
                                    class="mb-4"
                                    size="small"
                                    variant="flat"
                                    block
                                    @click.stop="$router.push({name: 'patient-decree-tool'})"
                                >
                                    <v-icon start>mdi-file-sign</v-icon>
                                    {{ $t('AppUserNav.patientenverfuegung') }}
                                </v-btn>
                                <v-btn
                                    color="accent"
                                    class="mb-4"
                                    size="small"
                                    variant="flat"
                                    block
                                    @click.stop="$router.push({name: 'advance-care-directive-tool'})"
                                >
                                    <v-icon start>mdi-file-account-outline</v-icon>
                                    {{ $t('AppUserNav.advance_care_directive') }}
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <template v-else>
            <v-row>
                <v-col v-for="index in 8" :key="index" cols="12" md="3">
                    <v-skeleton-loader type="card" class="h-40" />
                    <v-skeleton-loader type="actions" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import MyDocumentItem from './MyDocumentItem';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'MyDocuments',
    components: {MyDocumentItem},
    data() {
        return {
            documents: [],
            loading: false,
            addedOpen: false,
            items: [
                {title: this.$t('AppUserNav.testament'), icon: 'mdi-gavel', to: '/testament'},
                {
                    title: this.$t('AppUserNav.patientenverfuegung'),
                    icon: 'mdi-file-sign',
                    to: '/patientenverfuegung',
                },
                {title: this.$t('AppUserNav.todesanzeige'), icon: 'mdi-candle', to: '/home'},
            ],
        };
    },
    computed: {
        allDocuments() {
            let documents = this.documents;
            documents.push({
                type: 'extra',
            });
            return documents;
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        load(reloading) {
            if (!reloading) this.loading = true;
            return axios.get('/webapi/user/my-documents').then(response => {
                this.documents = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        download(item) {
            window.open(item.download_url, '_blank');

            setTimeout(() => {
                this.load(true);
            }, 1000);
        },
        remove(item) {
            let itemName = null;

            switch (item.type) {
            case 'testament':
                itemName = `${this.$t('OneWord.testament')} ${item.person_name}`;
                break;
            case 'patient_decree':
                itemName = `${this.$t('OneWord.patientDecree')} ${item.person_name}`;
                break;
            case 'advance_care_directive':
                itemName = `${this.$t('AdvanceCareDirectiveTool.title')} ${item.person_name}`;
                break;
            }

            this.$root.$confirm(this.$t('OneWord.delete'), this.$t('ConfirmDelete', {parameter: itemName})).
                then(confirm => {
                    if (confirm) {
                        if (item.type === 'testament') {
                            axios.delete(`/webapi/testaments/${item.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: itemName}));
                                this.load();
                            });
                        }

                        if (item.type === 'patient_decree') {
                            axios.delete(`/webapi/patient-decree/${item.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: itemName}));
                                this.load();
                            });
                        }

                        if (item.type === 'advance_care_directive') {
                            axios.delete(`/webapi/advance-care-directive/${item.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: itemName}));
                                this.load();
                            });
                        }
                    }
                });
        },
        addDocument() {
            this.addedOpen = true;
        },
    },
};
</script>

<style scoped>

</style>
