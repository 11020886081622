const initialState = {
    user: {},
};

const state = _.cloneDeep(initialState);

const getters = {
    user: state => state.user,
    thousandsSeparatorSymbol: state => {
        switch (state.user.number_format) {
        case 'de-DE':
            return '.';
        case 'en-GB':
            return ',';
        case 'fr-CH':
            return ' ';
        default:
            return '\'';
        }
    },
};

const actions = {
    load({commit}) {
        return axios.get('/webapi/user').then(response => {
            commit('load', response.data.data);
        }).catch(() => {
            commit('set', {});
        });
    },
    set({commit}, user) {
        commit('set', user);
    },
    update({commit}, user) {
        return axios.put(`/webapi/users/${user.id}`, user).then(response => {
            commit('update', response.data.data);
        });
    },
};

const mutations = {
    load(state, data) {
        state.user = data;
    },
    set(state, user) {
        state.user = user;
    },
    update(state, user) {
        state.user = user;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
