<template>
    <v-btn-toggle 
        v-model="selectedLocale"
        class="border border-da-primary"
        density="compact"
        color="primary"
        mandatory
    >
        <v-btn 
            v-for="language in availableLanguages"
            :key="language.locale"
            :value="language.locale"
            :data-cy="'language-selector-' + language.lang"
        >
            <template v-if="$vuetify.display.smAndDown">
                <span class="uppercase">{{ language.lang }}</span>
            </template>
            <template v-else>
                {{ language.label }}
            </template>
        </v-btn>
    </v-btn-toggle>
</template>

<script>
import {mapGetters} from 'vuex';

/**
 * Language/Locale Selector Component
 * 
 * Will return the selected language in the given format. Usage:
 * <language-selector v-model="lang" format="locale" separator="-" />
 * 
 * Only available languages of tenant will be used. Alternatively,
 * displayed languages can be determined by 'languages' property.
 */
export default {
    name: 'LanguageSelector',
    props: {
        format: {
            type: String,
            default: 'locale',
            validator(value) {
                // lang = 2 characters ('de')
                // locale = locale string ('de-CH')
                return ['locale', 'lang'].includes(value);
            }
        },
        separator: {
            type: String,
            default: '_',
            validator(value) {
                // use: underscore for backend, hyphen for frontend
                return ['-', '_'].includes(value);
            }
        },
        languages: {
            type: Object,
            required: false,
        },
        modelValue: String,
    },
    data() {
        return {
            selectedLocale: 'de-CH',
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLanguages',
        ]),
        availableLanguages() {
            let languages = this.languages || this.getLanguages();
            return _.filter([
                {lang: 'de', locale: 'de-CH', label: this.$t('OneWord.lang.german')},
                {lang: 'en', locale: 'en-GB', label: this.$t('OneWord.lang.english')},
                {lang: 'fr', locale: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {lang: 'it', locale: 'it-CH', label: this.$t('OneWord.lang.italian')},
            ], language => _.get(languages, language.lang, false));
        },
    },
    watch: {
        selectedLocale(value) {
            if (value) {
                if (this.format === 'lang') {
                    this.$emit('update:modelValue', value.substring(0, 2));
                } else {
                    this.$emit('update:modelValue', value.replace('-', this.separator));
                }
            }
        },
    },
    mounted() {
        // make sure selected locale is in the frontend format ('xx-XX')
        this.selectedLocale = this.modelValue.replace('_', '-');
    },
};
</script>

<style scoped>

</style>