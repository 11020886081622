<template>
    <v-sheet v-if="profile" data-cy="affiliate-info" color="white" class="affiliate-info pa-3 text-black">
        <v-list-subheader class="text-uppercase -ml-1">{{ $t('AffiliateInfo.title') }}</v-list-subheader>
        <template v-if="isWhiteLabel">
            <div class="text-center">
                <div
                    class="mx-auto my-4 h-12 w-32 rounded-full border border-solid border-gray-300 bg-cover bg-center bg-no-repeat md:size-32"
                    :style="{ backgroundImage: `url('${personImage}')` }"
                />
                <div class="font-weight-bold text-no-wrap text-truncate">
                    {{ profile.first_name }} {{ profile.last_name }}
                </div>
                <div class="text-no-wrap text-truncate text-xs">
                    <a :href="mail">{{ profile.email }}</a>
                </div>
                <div v-if="profile.phone_formatted" class="whitespace-no-wrap text-xs">
                    <a :href="`tel:${profile.phone}`">{{ profile.phone_formatted }}</a>
                </div>
                <div v-else class="whitespace-no-wrap text-xs">
                    <a :href="`tel:${profile.mobile_phone}`">{{ profile.mobile_phone_formatted }}</a>
                </div>
            </div>
        </template>
        <template v-else>
            <img :src="profileImage" :alt="profile.company_name" class="max-h-48 object-contain px-4 py-2">

            <hr class="my-5">

            <v-row align="center" justify="space-around" class="mx-3" no-gutters>
                <v-col sm="4">
                    <div
                        v-if="personImage"
                        class="h-12 w-20 rounded-full border border-solid border-gray-300 bg-cover bg-center bg-no-repeat md:size-20"
                        :style="{ backgroundImage: `url('${personImage}')` }"
                    />
                    <div
                        v-else
                        class="size-20 rounded-full border border-solid border-gray-300 bg-contain bg-center bg-no-repeat"
                    >
                        <i class="mdi mdi-account ml-4 mt-4 w-20 text-gray-400" style="font-size: 3rem" />
                    </div>
                </v-col>
                <v-col cols="8" class="pl-2">
                    <div class="font-weight-bold text-no-wrap text-truncate">
                        {{ profile.first_name }} {{ profile.last_name }}
                    </div>
                    <div class="text-no-wrap text-truncate text-xs">
                        <a :href="mail">{{ profile.email }}</a>
                    </div>
                    <div v-if="profile.phone_formatted" class="whitespace-no-wrap text-xs">
                        <a :href="`tel:${profile.phone}`">{{ profile.phone_formatted }}</a>
                    </div>
                    <div v-else class="whitespace-no-wrap text-xs">
                        <a :href="`tel:${profile.mobile_phone}`">{{ profile.mobile_phone_formatted }}</a>
                    </div>
                </v-col>
            </v-row>
        </template>
    </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from "@/utils/event-bus";

export default {
    name: 'AffiliateInfo',
    computed: {
        ...mapGetters('affiliateProfile', {
            profile: 'profile',
        }),
        ...mapGetters('env', {
            isWhiteLabel: 'isWhiteLabel',
        }),
        profileImage() {
            return _.get(this.profile, 'logo.url', 'default-image.png');
        },
        personImage() {
            return _.get(this.profile, 'person_image.url');
        },
        phone() {
            return 'tel:' + this.profile.phone;
        },
        mail() {
            return 'mailto:' + this.profile.email;
        },
    },
    mounted() {
        this.loadSponsor();

        EventBus.$on('logged-in', () => {
            this.loadSponsor();
        });
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadSponsor: 'load',
        }),
    },
};
</script>

<style scoped lang="scss">
.v-image {
  border-radius: 50%;
}
</style>
