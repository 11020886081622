<template>
    <v-dialog v-model="isDialogOpen" width="600">
        <vee-form ref="personForm" data-cy="v-dialog-person" @submit="save()">
            <v-card>
                <v-toolbar class="text-white" color="accent" dense flat>
                    <v-toolbar-title>{{ $t("AccountPersons.dialogAddPersonTitle") }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-row>
                        <v-col>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="person.salutation"
                                :label="$t('OneWord.salutation.label')"
                                name="salutation"
                            >
                                <v-select
                                    v-bind="componentField"
                                    :error-messages="errors"
                                    :items="salutation"
                                    :label="$t('OneWord.salutation.label')"
                                    variant="underlined"
                                    data-cy="v-select-salutation"
                                />
                            </vee-field>
                        </v-col>
                        <v-col>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="person.last_name"
                                :label="$t('OneWord.lastName')"
                                rules="required"
                                name="last_name"
                            >
                                <v-text-field
                                    v-bind="componentField"
                                    :error-messages="errors"
                                    :label="$t('OneWord.lastName')"
                                    data-cy="v-textfield-lastname"
                                />
                            </vee-field>
                        </v-col>
                        <v-col>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="person.first_name"
                                :label="$t('OneWord.lastName')"
                                rules="required"
                                name="first_name"
                            >
                                <v-text-field
                                    v-bind="componentField"
                                    :error-messages="errors"
                                    :label="$t('OneWord.firstName')"
                                    data-cy="v-textfield-firstname"
                                />
                            </vee-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="person.email"
                                :label="$t('OneWord.email')"
                                rules="required|email"
                                name="email"
                            >
                                <v-text-field
                                    v-bind="componentField"
                                    :error-messages="errors"
                                    :label="$t('OneWord.email')"
                                    data-cy="v-textfield-email"
                                    type="email"
                                />
                            </vee-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn variant="text" @click="isDialogOpen = false">
                        {{ $t("OneWord.cancel") }}
                    </v-btn>
                    <v-btn :loading="saving" type="submit">
                        {{ $t("OneWord.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </vee-form>
    </v-dialog>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import { EventBus } from "@/utils/event-bus";

export default {
    name: "AccountPersonEdit",
    components: { VeeForm, VeeField },
    props: {
        item: Object,
        account: Object,
        open: Boolean,
    },
    data() {
        return {
            person: {},
            isDialogOpen: false,
            saving: false,
        };
    },
    computed: {
        salutation() {
            return [
                { title: this.$t("OneWord.salutation.m"), value: "Herr" },
                { title: this.$t("OneWord.salutation.f"), value: "Frau" },
                { title: this.$t("OneWord.salutation.d"), value: "" },
            ];
        },
    },
    watch: {
        item(value) {
            this.person = value;
        },
        open(value) {
            if (this.$refs.personForm) {
                this.$refs.personForm.resetForm();
            }

            this.isDialogOpen = value;
        },
        isDialogOpen(value) {
            this.$emit("update:open", value);

            if (value === false) {
                this.saving = false;
                this.person = {};
            }
        },
    },
    methods: {
        save() {
            this.saving = true;

            let isUpdate = _.get(this.person, 'id');

            axios({
                url: isUpdate ? `/webapi/accounts/${this.account.id}/contacts/${this.person.id}` : `/webapi/accounts/${this.account.id}/contacts`,
                method: isUpdate ? 'put' : 'post',
                data: this.person,
            })
                .then(() => {
                    EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                    this.isDialogOpen = false;
                    this.$emit('saved');
                })
                .catch(error => {
                    if (_.has(error, 'response.data.errors.email')) {
                        EventBus.$emit("error", _.get(error, 'response.data.errors.email.0'));
                    } else {
                        EventBus.$emit("error", _.get(error, "response.data.message"));
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },
    },
};
</script>

<style scoped></style>