<template>
    <div>
        <v-toolbar flat color="transparent" class="text-h6">
            {{ $t('AppAdminNav.websiteRanking') }}
            <v-spacer />
            <v-btn
                v-if="hasOwnItem"
                color="primary"
                variant="flat"
                class="hidden-xs"
                @click="openDetails(ownItem)"
            >
                {{ $t('AdminWebsiteRanking.openOwnRanking') }}
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-data-table
                v-model:sort-by="sortBy"
                :headers="headers"
                :row-props="itemClass"
                :items="itemsFiltered"
                :loading="loading"
                :search="search"
                disable-pagination
                hide-default-footer
                items-per-page="-1"
                @click:row="clickOnRow"
            >
                <template #top>
                    <v-row no-gutters>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="filter"
                                :items="serviceCategories"
                                :label="$t('AdminWebsiteRanking.filterCategories')"
                                chips
                                clearable
                                density="compact"
                                hide-details
                                item-title="name"
                                item-value="id"
                                multiple
                                prepend-icon="mdi-filter-variant"
                                class="pa-4"
                                variant="solo"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="search"
                                :label="$t('OneWord.search')"
                                class="pa-4"
                                clearable
                                density="compact"
                                prepend-icon="mdi-magnify"
                                variant="solo"
                            />
                        </v-col>
                    </v-row>
                </template>
                <template #item.url="{value}">
                    <v-btn
                        v-if="value"
                        color="primary"
                        variant="flat"
                        size="small"
                        @click.stop="openUrl(value)"
                    >
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
                <template #item.total="{value}">
                    <v-chip v-if="value > 20" variant="flat" color="green-lighten-1">{{ value }}</v-chip>
                    <v-chip v-else-if="value <= 20 && value > 10" variant="flat" color="orange">{{ value }}</v-chip>
                    <v-chip v-else variant="flat" color="red-lighten-2">{{ value }}</v-chip>
                </template>
                <template #item.checked_at="{value}">
                    {{ localizeDate(value, 'L') }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {localizeDate} from '@/composables/Helpers';
import {EventBus} from "@/utils/event-bus";

export default {
    name: 'AdminWebsiteRanking',
    data: () => {
        return {
            items: [],
            serviceCategories: [],
            loading: false,
            filter: null,
            search: null,
            sortBy: [
                {key: 'total', order: 'desc'},
            ]
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        headers() {
            return [
                {title: this.$t('AdminWebsiteRanking.tableHeader.companyName'), value: 'account.company_name', sortable: true},
                {title: this.$t('AdminWebsiteRanking.tableHeader.link'), value: 'url'},
                {title: this.$t('AdminWebsiteRanking.tableHeader.sum'), value: 'total', sortable: true},
                {title: this.$t('AdminWebsiteRanking.tableHeader.sumA'), value: 'a_total', sortable: true},
                {title: this.$t('AdminWebsiteRanking.tableHeader.sumB'), value: 'b_total', sortable: true},
                {title: this.$t('AdminWebsiteRanking.tableHeader.sumC'), value: 'c_total', sortable: true},
                {title: this.$t('AdminWebsiteRanking.tableHeader.checkedAt'), value: 'checked_at', sortable: true},
            ];
        },
        ownItem() {
            return _.find(this.items, {
                account: {
                    id: this.user.account_id,
                },
            });
        },
        hasOwnItem() {
            return !_.isUndefined(this.ownItem);
        },
        itemsFiltered() {
            if (_.isEmpty(this.filter)) {
                return this.items;
            }

            return _.filter(this.items, item => {
                return _.difference(_.map(item.service_categories, 'id'), this.filter).length === 0;
            });
        },
    },
    mounted() {
        this.load();
        this.loadCategories();

        EventBus.$on('locale-changed', this.loadCategories);
    },
    beforeUnmount() {
        EventBus.$off('locale-changed', this.loadCategories);
    },
    methods: {
        localizeDate,
        load() {
            this.loading = true;

            window.axios.get('/webapi/page-rank').then(response => {
                this.items = response.data.data;
            }).finally(() => {
                this.loading = false;
            }).catch(err => {
                if (_.get(err, 'response.status') === 403) {
                    this.$router.push({name: 'error-403'});
                }
            });
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
        itemClass({item}) {
            if (item.account_id === this.user.account_id) {
                return {
                    class: ['own']
                };
            }
            else if (item.is_hidden) {
                return {
                    class: ['is-hidden']
                };
            }
        },
        clickOnRow(e, {item}) {
            this.openDetails(item);
        },
        openDetails(item) {
            if (item.account_id === this.user.account_id) {
                this.$router.push({name: 'admin-website-ranking-detail', params: {page_rank: item.id}});
            }
        },
        loadCategories() {
            window.axios.get('/api/service-categories/npo').then(response => {
                this.serviceCategories = _.sortBy(response.data.data, 'name');
            });
        },
    }
};
</script>

<style scoped lang="scss">
.v-data-table {
    :deep(.own) {
        @apply border-4;
        @apply border-da-pp;
    }

    :deep(.is-hidden) {
        @apply bg-gray-200;
    }
}
</style>