const filters = {

    formatDate(value) {
        if (value) {
            let dateVal = new Date(value);
            if (isNaN(dateVal.getTime())) {
                return '';
            }
            return dateVal.toLocaleDateString('de-CH', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        }

        return '';
    },

    formatDateTime(value) {
        if (value) {
            let dateVal = new Date(value);
            if (isNaN(dateVal.getTime())) {
                return '';
            }
            return dateVal.toLocaleDateString('de-CH', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        }

        return '';
    },

    nl2br(str) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }

        let breakTag = '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },

    percent(value) {
        const formattedValue = numeral(value).format('0.00');
        return `${formattedValue}%`;
    },

    percentNumber(value) {
        return numeral(value).format('0.00');
    },

    percentTotal(value) {
        const formattedValue = numeral(value).format('0');
        return `${formattedValue}%`;
    },

    date(value) {
        if (value) {
            return moment(value).format('L');
        }

        return null;
    },

    dateTime(value) {
        if (value) {
            return moment(value).format('LLL');
        }

        return null;
    },
    
    fullName(value) {
        return `${_.get(value, 'first_name')} ${_.get(value, 'last_name')}`;
    },
}

const useFilters = () => {
    return filters
}

export { useFilters }

export default {
    install(app) {
        app.config.globalProperties.filters = filters;
        app.config.globalProperties.$filters = filters;
    }
};