<template>
    <div>
        <vee-form v-slot="{ handleSubmit }" ref="2faActivateForm">
            <v-form id="2_fa_activate_form" @submit.prevent="handleSubmit(activate2fa)">
                <v-card>
                    <v-card-title class="text-subtitle-2">
                        {{ $t('UserSecurity.title') }}
                    </v-card-title>

                    <v-card-text>
                        <v-alert type="info" density="compact" class="mb-4" text>
                            <span v-html="$t('UserSecurity.infoText')" />
                        </v-alert>

                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="twoFactorNumber"
                            :label="$t('OneWord.smsNumber')"
                            name="two_factor_mobile_phone"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :error-messages="errors"
                                :label="$t('OneWord.smsNumber')"
                            />
                        </vee-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-if="user.two_factor_mobile_phone"
                            :loading="deleting"
                            color="error"
                            type="tel"
                            variant="flat"
                            @click="deactivate"
                        >
                            {{ $t('OneWord.delete') }}
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            :loading="loading"
                            color="primary"
                            variant="flat"
                            type="submit"
                            form="2_fa_activate_form"
                        >
                            {{ $t('OneWord.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </vee-form>

        <v-dialog v-model="verifyCodeDialog" width="600" persistent>
            <vee-form v-slot="{ handleSubmit }" ref="2faValidateForm">
                <v-form id="2_fa_validate_form" @submit.prevent="handleSubmit(verify2fa)">
                    <v-card>
                        <v-card-title>
                            {{ $t('UserSecurity.confirmCodeTitle') }}
                        </v-card-title>
                        <v-card-text>
                            <span>{{ $t('UserSecurity.confirmCodeInfoText') }}</span>
                            <v-alert v-if="verifyErrorMessage" type="error" density="compact" text>
                                {{ verifyErrorMessage }}
                            </v-alert>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="twoFactorCode"
                                :label="$t('OneWord.smsCode')"
                                rules="required"
                                name="code"
                            >
                                <v-text-field
                                    v-bind="componentField"
                                    placeholder="123456"
                                    :error-messages="errors"
                                />
                            </vee-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                variant="text"
                                :disabled="locked"
                                @click="cancel"
                            >
                                {{ $t('OneWord.cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                type="submit"
                                form="2_fa_validate_form"
                                :loading="verifying"
                            >
                                {{ $t('OneWord.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </vee-form>
        </v-dialog>
    </div>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '@/utils/event-bus';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'UserSecurity',
    components: {
        VeeForm,
        VeeField,
    },
    data: () => ({
        twoFactorNumber: null,
        twoFactorCode: null,

        loading: false,
        verifying: false,
        deleting: false,

        verifyCodeDialog: false,
        verifyErrorMessage: null,

        locked: true,
    }),
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
    },
    watch: {
        verifyCodeDialog(value) {
            if (value) {
                this.locked = true;
                this.timer = setTimeout(() => {
                    this.locked = false;
                }, 30000);
            } else {
                this.locked = false;
            }
        }
    },
    mounted() {
        this.loadUser().then(() => {
            this.load();
        })
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        load() {
            this.twoFactorNumber = _.get(this.user, 'two_factor_mobile_phone');
        },
        activate2fa() {
            this.loading = true;

            return axios.post(`/webapi/user/activate-2fa`, {
                'two_factor_mobile_phone': this.twoFactorNumber,
            }).then(() => {
                this.verifyCodeDialog = true;
            }).catch(error => {
                if (_.has(error, 'response.data.errors')) {
                    this.$refs['2faActivateForm'].setErrors(_.get(error, 'response.data.errors', []));
                }
                EventBus.$emit('error', _.get(error, 'response.data.message'));
            }).finally(() => {
                this.loading = false;
            });
        },
        verify2fa() {
            this.verifying = true;
            this.verifyErrorMessage = null;

            return axios.post(`/webapi/user/verify-2fa`, {
                code: this.twoFactorCode,
            }).then(() => {
                this.loadUser().then(() => {
                    this.load();
                    this.verifyCodeDialog = false;
                });
                EventBus.$emit('success', this.$t('User.updateSuccessful'));
            }).catch(error => {
                if (_.has(error, 'response.data.errors')) {
                    this.$refs['2faValidateForm'].setErrors(_.get(error, 'response.data.errors.code', []));
                    this.verifyErrorMessage = _.get(error, 'response.data.message');
                }
                else {
                    this.verifyErrorMessage = this.$t('UserSecurity.verify2faError');
                }
            }).finally(() => {
                this.verifying = false;
            });
        },
        deactivate() {
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('UserSecurity.confirmDelete'),
            ).then(confirm => {
                if (confirm) {
                    this.deleting = true;

                    let userData = _.cloneDeep(this.user);
                    userData['two_factor_mobile_phone'] = null;

                    return axios.put(`/webapi/users/${this.user.id}`, userData).then(() => {
                        this.loadUser().then(() => {
                            this.load();
                        });
                        EventBus.$emit('success', this.$t('User.updateSuccessful'));
                    }).finally(() => {
                        this.deleting = false;
                    });
                }
            });
        },
        cancel() {
            return axios.post(`/webapi/user/cancel-2fa`).then(() => {
                this.loadUser().then(() => {
                    this.load();
                    this.verifyCodeDialog = false;
                });
            }).catch(error => {
                if (_.has(error, 'response.data.message')) {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                }
            }).finally(() => {
                this.verifying = false;
            });
        },
    },
};
</script>

<style scoped>

</style>