import de from './de-CH.json';
import en from './en-GB.json';
import fr from './fr-CH.json';
import it from './it-CH.json';

export const app = {
    'de': de,
    'en': en,
    'fr': fr,
    'it': it,
};