<template>
    <div>
        <h1 class="text-h6 mb-2">
            {{ $t("AdminProfile.title") }}
        </h1>

        <v-data-iterator
            v-model:items-per-page="itemsPerPage"
            :items="profiles"
            :loading="loading"
            hide-default-footer
        >
            <template #default="props">
                <v-row>
                    <v-col
                        v-for="item in props.items.map((item) => item.raw)"
                        :key="item.name"
                        cols="12"
                        lg="6"
                    >
                        <v-card>
                            <div class="pa-4">
                                <v-img
                                    v-if="logo(item)"
                                    :src="logo(item).url"
                                    max-height="12rem"
                                />
                                <dein-adieu-logo v-else style="max-height: 12rem" />
                            </div>
                            <div class="px-4">
                                <v-chip
                                    v-for="pt in item.partner_types"
                                    :key="pt.id"
                                    class="mr-1"
                                    color="accent"
                                    label
                                    size="small"
                                    variant="outlined"
                                >
                                    {{ pt.name }}
                                </v-chip>
                            </div>
                            <v-card-title>
                                <router-link
                                    v-if="isEditable(item)"
                                    :to="{
                                        name: 'admin-edit-profile-description',
                                        params: { profile_id: item.id, lang: lang },
                                    }"
                                >
                                    {{
                                        item.company_name || $t("AdminProfileList.newProfileTitle")
                                    }}
                                </router-link>
                                <span v-else>
                                    {{
                                        item.company_name || $t("AdminProfileList.newProfileTitle")
                                    }}
                                </span>
                            </v-card-title>
                            <v-card-subtitle class="mb-4">
                                {{ item.street || $t("OneWord.address") }},
                                {{ item.zip || $t("OneWord.zip") }}
                                {{ item.place || $t("OneWord.place") }}
                            </v-card-subtitle>
                            <v-card-actions>
                                <v-btn
                                    :disabled="!isEditable(item)"
                                    :hidden="$vuetify.display.smAndDown && !isEditable(item)"
                                    color="primary"
                                    variant="flat"
                                    :to="{
                                        name: 'admin-edit-profile-description',
                                        params: { profile_id: item.id, lang: lang },
                                    }"
                                >
                                    <v-icon start>mdi-pencil</v-icon>
                                    {{ $t("OneWord.edit") }}
                                </v-btn>
                                <v-spacer />

                                <v-btn v-if="item.is_draft" icon @click="deleteProfile(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>

                                <v-chip
                                    v-if="item.is_in_translation"
                                    class="text-uppercase mx-2"
                                    color="warning"
                                    label
                                    variant="outlined"
                                >
                                    {{ $t("AdminProfileList.inTranslation") }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_approved"
                                    class="text-uppercase mx-2"
                                    color="success"
                                    label
                                    variant="outlined"
                                >
                                    {{ $t("OneWord.public") }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_approved !== true && item.is_draft === false"
                                    class="text-uppercase mx-2"
                                    color="default"
                                    label
                                    variant="outlined"
                                >
                                    {{ $t("OneWord.inReview") }}
                                </v-chip>

                                <v-chip
                                    v-if="item.is_draft"
                                    class="text-uppercase mx-2"
                                    color="warning"
                                    label
                                    variant="outlined"
                                >
                                    {{ $t("OneWord.draft") }}
                                </v-chip>

                                <v-tooltip location="bottom">
                                    <template #activator="{ props }">
                                        <v-chip
                                            class="text-uppercase mx-2"
                                            label
                                            v-bind="props"
                                            variant="outlined"
                                        >
                                            {{ item.locale.substring(0, 2) }}
                                        </v-chip>
                                    </template>
                                    <span>{{ getLang(item.locale) }}</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template #loading>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-skeleton-loader type="card, list-item-avatar" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-skeleton-loader type="card, list-item-avatar" />
                    </v-col>
                </v-row>
            </template>
            <template #no-data>
                <v-alert class="mt-3" type="info" variant="outlined">
                    {{ $t("AdminProfileList.errors.noData") }}
                </v-alert>
            </template>
            <template #header>
                <v-card :loading="loading" class="mb-4">
                    <v-toolbar color="white">
                        <v-toolbar-title>
                            {{ $t("AdminProfileList.myProfiles") }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn
                            :disabled="
                                $ability.can('ngo', 'Statistic') && profiles.length >= 1
                            "
                            color="accent"
                            variant="outlined"
                            @click="add"
                        >
                            <v-icon :start="$vuetify.display.mdAndUp">mdi-plus</v-icon>
                            <span class="hidden-sm-and-down">{{
                                $t("AdminProfileList.addProfile")
                            }}</span>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-menu>
                            <template #activator="{ props }">
                                <v-btn
                                    :block="$vuetify.display.smAndDown"
                                    class="text-capitalize"
                                    color="primary"
                                    size="small"
                                    v-bind="props"
                                    variant="flat"
                                >
                                    {{ $t("AdminProfileList.profileLanguage") }}:
                                    {{ selectedLang }}
                                    <v-icon end>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="item in languages"
                                    :key="item.locale"
                                    @click="lang = item.locale"
                                >
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span class="hidden-md-and-down ml-4">
                            {{ $t("AdminProfileList.selectProfileLangIntoText") }}
                        </span>
                    </v-card-text>
                </v-card>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { usei18n } from "@/plugins/i18n";
import { EventBus } from "@/utils/event-bus";
import DeinAdieuLogo from "../../components/Icons/DeinAdieuLogo";
import store from "@/store";

export default {
    name: "AdminProfileList",
    components: { DeinAdieuLogo },
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
    data() {
        return {
            loading: false,
            itemsPerPage: 1000,
            profiles: [],
            lang: "de_CH",
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
        }),
        languages() {
            return [
                { locale: "de_CH", label: this.$t("OneWord.lang.german") },
                { locale: "en_GB", label: this.$t("OneWord.lang.english") },
                { locale: "fr_CH", label: this.$t("OneWord.lang.french") },
                { locale: "it_CH", label: this.$t("OneWord.lang.italian") },
            ];
        },
        locale() {
            return usei18n().locale.substr(0, 2);
        },
        selectedLang() {
            return _.get(_.find(this.languages, { locale: this.lang }), "label");
        },
    },
    watch: {
        lang(value, old) {
            if (old !== value) {
                this.load();
            }
        },
    },
    mounted() {
        this.load();
    },
    activated() {
        this.load();
    },
    methods: {
        ...mapActions("user", {
            loadUser: "load",
        }),
        load() {
            this.loading = true;

            axios
                .get(`/webapi/accounts/${this.user.account_id}/profiles`, {
                    headers: {
                        "DA-Accept-Language": this.lang,
                    },
                })
                .then((response) => {
                    this.profiles = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        isEditable(item) {
            return item.is_in_translation === false;
        },
        add() {
            this.$router.push({ name: "admin-profiles-create" });
        },
        logo(item) {
            let logo = _.find(_.get(item, "profile_images", []), {
                type: "logo",
                lang: this.lang,
            });

            if (_.isUndefined(logo)) {
                logo = _.find(_.get(item, "profile_images", []), {
                    type: "logo",
                    lang: "de_CH",
                });
            }

            return logo;
        },
        getLang(locale) {
            return _.get(_.find(this.languages, { locale: locale }), "label", "");
        },
        deleteProfile(profile) {
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('ConfirmDelete',
                    {parameter: profile.company_name || this.$t('AdminProfileList.newProfileTitle')}),
            ).then(confirm => {
                if (confirm) {
                    axios.delete(`/webapi/accounts/${this.user.account_id}/profiles/${profile.id}`).then(() => {
                        EventBus.$emit(
                            'success',
                            this.$t('OneWord.itemDeleteSuccess',
                                {item: profile.company_name || this.$t('AdminProfileList.newProfileTitle')}),
                        );
                        this.load();
                    });
                }
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  @apply pt-2;
}
</style>
