<template>
    <v-row class="mt-2">
        <v-col cols="12" md="6">
            <v-text-field
                :append-icon="icon"
                :label="label"
                :model-value="email"
                class="invitation-email"
                disabled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="6" class="text-right" align-self="center">
            <v-btn
                :block="$vuetify.display.mdAndDown"
                :loading="loading"
                color="accent"
                theme="dark"
                variant="flat"
                @click="send"
            >
                <v-icon start>mdi-email</v-icon>
                {{ $t('SendPersonInvitation.btn.send') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: 'SendPersonInvitation',
    props: {
        label: String,
        person: Object,
        url: String,
        type: String,
        patientDecreeId: String,
    },
    data() {
        return {
            sent: false,
            loading: false,
        };
    },
    computed: {
        email() {
            return _.get(this.person, 'email');
        },
        icon() {
            return this.sent === true ? 'mdi-check-circle' : null;
        },
    },
    methods: {
        send() {
            this.loading = true;

            axios.post(this.url, {
                type: this.type,
            }).then(() => {
                this.sent = true;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.invitation-email :deep(.mdi-check-circle) {
    color: green !important;
}
</style>
