<template>
    <v-form @submit.prevent="save">
        <v-card :loading="loading">
            <v-card-title>
                {{ $t('PartnerTypeForm.title') }}
            </v-card-title>
            <v-card-text>
                <v-alert type="info" density="compact" text>
                    {{ $t('AdminProfileCreate.partnerTypeInfoText') }}
                </v-alert>

                <v-card border class="mb-2">
                    <v-card-title class="text-subtitle-2">
                        {{ $tc('AdminProfileCreate.ngoPartnerTypeInfoText', ngoPartnerTypes.length) }}
                    </v-card-title>
                    <v-list v-model:selected="selectedPartnerTypes" class="p-0">
                        <template v-for="(pt, index) in ngoPartnerTypes" :key="`pt-${pt.id}`">
                            <v-list-item density="compact" :value="pt.id">
                                <template #default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :model-value="active" />
                                    </v-list-item-action>

                                    
                                    <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                    </v-list-item-subtitle>
                                </template>
                            </v-list-item>
                            <v-divider
                                v-if="index < ngoPartnerTypes.length - 1"
                                :key="index"
                            />
                        </template>
                    </v-list>
                </v-card>

                <v-card border>
                    <v-card-title class="text-subtitle-2">
                        {{ $tc('AdminProfileCreate.spPartnerTypeInfoText', spPartnerTypes.length) }}
                    </v-card-title>
                    <v-list v-model:selected="selectedPartnerTypes" class="p-0">
                        <template v-for="(pt, index) in spPartnerTypes" :key="`pt-${pt.id}`">
                            <v-list-item density="compact" :value="pt.id">
                                <template #default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :model-value="active" />
                                    </v-list-item-action>

                                    
                                    <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                    </v-list-item-subtitle>
                                </template>
                            </v-list-item>
                            <v-divider
                                v-if="index < spPartnerTypes.length - 1"
                                :key="index"
                            />
                        </template>
                    </v-list>
                </v-card>

                <v-alert
                    v-if="partnerTypeRequiredError"
                    type="error"
                    density="compact"
                    text
                    class="mt-2"
                >
                    {{ $t('PartnerTypeForm.requiredError') }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn variant="text" @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn type="submit" variant="flat" color="primary" :loading="saving">{{ $t('OneWord.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {EventBus} from '../../utils/event-bus';

export default {
    name: 'PartnerTypeForm',
    components: {},
    beforeRouteEnter(to, from, next) {
        axios.get('/webapi/create-profile/profile').then(response => {
            next(vm => {
                vm.profile = response.data.data;
                vm.selectedPartnerTypes = _.map(_.get(response.data.data, 'partner_types', []), 'id');
            });
        });
    },
    data() {
        return {
            loading: false,
            saving: false,

            profile: {},

            partnerTypes: [],
            selectedPartnerTypes: [],

            partnerTypeRequiredError: false,
        };
    },
    computed: {
        ngoPartnerTypes() {
            return _.filter(this.partnerTypes, {id: 12});
        },
        spPartnerTypes() {
            return _.filter(this.partnerTypes, item => {
                return item.id !== 12;
            });
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        save() {
            if (this.selectedPartnerTypes.length > 0) {
                this.partnerTypeRequiredError = false;

                let payload = {
                    partner_types: _.filter(this.partnerTypes, item => {
                        return _.includes(this.selectedPartnerTypes, item.id);
                    }),
                };

                axios.post(`/webapi/create-profile/partner-type`, payload).then(() => {
                    this.$router.push({name: 'create-profile-description'});
                }).catch(error => {
                    EventBus.$emit('error', error.data.message);
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.partnerTypeRequiredError = true;
            }
        },
        select(value) {
            if (this.isSelected(value)) {
                this.selected.splice(_(this.selected).findIndex({id: value.id}), 1);
            } else {
                this.selected.push(value);
            }
        },
        isSelected(item) {
            return _(this.selected).findIndex({id: item.id}) !== -1;
        },
        load() {
            this.loading = true;
            axios.get('/api/partner-types').then(response => {
                this.partnerTypes = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
    }
};
</script>

<style scoped>

</style>
