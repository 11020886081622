import {EventBus} from '@/utils/event-bus';
import store from "@/store";

const initialState = {
    loaded: false,
    profile: null,
    externalLink: false,
};

const state = _.cloneDeep(initialState);

const getters = {
    profile: state => state.profile,
    externalLink: state => state.externalLink,
    hasProfile: state => !_.isEmpty(state.profile),
    hasNgoProfile: state => !_.isEmpty(state.profile) && _.get(state.profile, 'is_ngo', false) === true,
    isLoaded: state => state.loaded,
};

const actions = {
    load({state, commit}, forceReload) {
        if (!state.loaded || forceReload) {
            return axios.get(`/api/affiliate/profile`).then(response => {
                if (_.get(response, 'data.success') === false) {
                    commit('load');
                } else {
                    commit('load', response.data);
                }
            }).catch(() => {
                commit('load');
            });
        }
    },
};

const mutations = {
    load(state, response) {
        state.profile = _.get(response, 'data', null);
        state.externalLink = _.get(response, 'is_external', false);
        state.loaded = true;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

const reload = () => {
    store.dispatch('affiliateProfile/load', true);
}

EventBus.$off('locale-changed', reload);
EventBus.$on('locale-changed', reload);

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};