
class ImagesToBase64 {
    static fromDropzone(items) {
        let promises = [];
        _(items).forEach(item => {
            let form = new FormData();

            if (item.file.type) {
                form.append('data', item.file, item.file.name);
                promises.push(new Promise((resolve) => {
                    this.toBase64(form.get('data')).then((result) => {
                        resolve({
                            name: form.get('data').name,
                            data: result,
                        });
                    });
                }));
            }
        });

        return Promise.all(promises);
    }

    static toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
}

export {ImagesToBase64};
