const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,

    patientDecree: {},
};

const state = _.cloneDeep(initialState);

const getters = {
    patientDecree: state => state.patientDecree,
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,
    authorizedRepresentative: state => _.get(state.patientDecree, 'authorized_representative'),
    replacementPerson: state => _.get(state.patientDecree, 'replacement_person'),
    hasAuthorizedRepresentative: state => {
        if (_.has(state.patientDecree, 'authorized_representative.active')) {
            return _.get(state.patientDecree, 'authorized_representative.active');
        }

        // Legacy
        return !_.isEmpty(_.get(state.patientDecree, 'authorized_representative.last_name'));
    },
    hasReplacementPerson: state => {
        if (_.has(state.patientDecree, 'replacement_person.active')) {
            return _.get(state.patientDecree, 'replacement_person.active');
        }

        // Legacy
        return !_.isEmpty(_.get(state.patientDecree, 'replacement_person.last_name'))
    },
    getConsultingCode: state => _.get(state, 'patientDecree.consulting_code'),
};

const actions = {
    set: ({commit}, patientDecree) => commit('set', patientDecree),
    patch: ({commit}, patientDecree) => commit('patch', patientDecree),
    setStep: ({commit}, value) => commit('step', value),
    setCompleted: ({commit}) => commit('setCompleted'),
    setNgoThanksSubmitted: ({commit}) => commit('setNgoThanksSubmitted'),
    setAgreementAccepted: ({commit}, value) => commit('setAgreementAccepted', value),
    reset: ({commit}) => commit('resetState'),
};

const mutations = {
    set(state, patientDecree) {
        state.patientDecree = patientDecree;
    },
    patch(state, patientDecree) {
        _.merge(state.patientDecree, patientDecree);
    },
    step(state, value) {
        state.step = parseInt(value);

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};