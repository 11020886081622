import {assign, get} from 'lodash';

const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,
    familyData: {
        married: null,
        kids: null,
        parents: null,
        grandparents: null,
        greatgrandparents: null,
        de_matrimonial_property_regime: null, // Güterstand: separation_of_property, community_of_joint_gain
    },
    people: [],
    otherPeople: [],
    partner: null,
    testamentPeople: [],
    testator: null,
    ngos: [],
    consultingCode: null,
    pdfUrl: null,
    testamentId: null,

    // Sort order, internal helper
    sortIndex: 0,

    // Step 2b: Parents
    familyTree: [],
    mother: null,
    father: null,

    // Step 2c: Grandparents
    paternalFather: null,
    paternalMother: null,
    maternalFather: null,
    maternalMother: null,
    paternalFamilyTree: [],
    maternalFamilyTree: [],

    // Step 7: User email
    email: null,
};

const state = _.cloneDeep(initialState);

const getters = {
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,
    familyData: state => state.familyData,
    spouse: state => { return _.find(state.testamentPeople, {type: 'spouse'}); },
    married: state => state.familyData.married,
    kids: state => state.familyData.kids,
    parents: state => state.familyData.parents,
    grandparents: state => state.familyData.grandparents,
    greatgrandparents: state => state.familyData.greatgrandparents,
    people: state => state.people,
    otherPeople: state => state.otherPeople,
    testamentPeople: state => state.testamentPeople,
    testamentPeopleNgos: state => {
        return _.reduce(_.filter(state.testamentPeople, {type: 'ngo'}), (res, ngo) => {
            res.push({
                id: ngo.id.substring(4),
                quota: _.get(_.find(state.testamentPeople, {id: ngo.id}), 'value', 0),
                amount: _.get(_.find(state.testamentPeople, {id: ngo.id}), 'amount', 0),
                real_assets: _.get(_.find(state.testamentPeople, {id: ngo.id}), 'realAssets', null),
                value_type: _.get(_.find(state.testamentPeople, {id: ngo.id}), 'valueType', 'value'),
                'is_additional': ngo.is_additional,
                personalization: ngo.personalization,
            });
            return res;
        }, []);
    },
    testamentPeopleHumans: state => {
        return _.reject(state.testamentPeople, {type: 'ngo'});
    },
    testator: state => state.testator,
    ngos: state => state.ngos,
    consultingCode: state => state.consultingCode,
    pdfUrl: state => state.pdfUrl,
    testamentId: state => state.testamentId,
    familyTree: state => state.familyTree,
    mother: state => state.mother,
    father: state => state.father,
    paternalFather: state => state.paternalFather,
    paternalMother: state => state.paternalMother,
    maternalFather: state => state.maternalFather,
    maternalMother: state => state.maternalMother,
    maternalFamilyTree: state => state.maternalFamilyTree,
    paternalFamilyTree: state => state.paternalFamilyTree,
    getEmail: state => state.email,
    getDeMatrimonialPropertyRegime: state => get(state, 'familyData.de_matrimonial_property_regime')
};

const actions = {
    setStep({commit}, value) {
        commit('step', value);
    },
    nextStep({commit}, value) {
        commit('step', value);
    },
    setCompleted({commit}) {
        commit('setCompleted');
    },
    setNgoThanksSubmitted({commit}) {
        commit('setNgoThanksSubmitted');
    },
    setFamily({commit}, familyData) {
        commit('setFamily', familyData);
    },
    people({commit}, people) {
        commit('setPeople', people);
    },
    addPeople({commit}, people) {
        commit('addPeople', people);
    },
    otherPeople({commit}, people) {
        commit('setOtherPeople', people);
    },
    partner({commit}, partner) {
        commit('setPartner', partner);
    },
    personalize({commit}, {person, data}) {
        commit('setPersonalization', {person: person, data: data});
    },
    addToTestament({commit}, person) {
        commit('addToTestament', person);
    },
    removeFromTestament({commit}, person) {
        commit('removeFromTestament', person);
    },
    setValue({commit}, {person, value}) {
        commit('setValue', {person: person, value: value});
    },
    setTestator({commit}, testator) {
        commit('setTestator', testator);
    },
    addNgo({commit}, ngo) {
        commit('addNgo', ngo);
    },
    removeNgo({commit}, ngo) {
        commit('removeNgo', ngo);
    },
    setResponse({commit}, response) {
        commit('setResponse', response);
    },
    reset({commit}) {
        commit('resetState');
    },
    setAgreementAccepted({commit}, value) {
        commit('setAgreementAccepted', value);
    },
    removeType({commit}, type) {
        commit('removeType', type);
    },
    setFamilyTree({commit}, familyTree) {
        commit('familyTree', familyTree);
    },
    setMother({commit}, mother) {
        commit('mother', mother);
    },
    setFather({commit}, father) {
        commit('father', father);
    },
    paternalFather({commit}, paternalFather) {
        commit('paternalFather', paternalFather);
    },
    paternalMother({commit}, paternalMother) {
        commit('paternalMother', paternalMother);
    },
    maternalFather({commit}, maternalFather) {
        commit('maternalFather', maternalFather);
    },
    maternalMother({commit}, maternalMother) {
        commit('maternalMother', maternalMother);
    },
    maternalFamilyTree({commit}, maternalFamilyTree) {
        commit('maternalFamilyTree', maternalFamilyTree);
    },
    paternalFamilyTree({commit}, paternalFamilyTree) {
        commit('paternalFamilyTree', paternalFamilyTree);
    },
    setEmail({commit}, email) {
        commit('setEmail', email);
    },
    resetSliderValues({commit}) {
        commit('resetSliderValues');
    },
};

const mutations = {
    step(state, value) {
        state.step = parseInt(value) >= 0 ? parseInt(value) : parseInt(state.step) + 1;

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    setFamily(state, family) {
        assign(state.familyData, family);
    },
    setPeople(state, people) {
        state.people = people;
    },
    addPeople(state, people) {
        state.people.concat(people);
    },
    setOtherPeople(state, people) {
        state.otherPeople = people;
    },
    setPartner(state, partner) {
        state.partner = partner;
    },
    setPersonalization(state, {person, data}) {
        let result = _.find(state.testamentPeople, {id: person.id});
        if (result) {
            _.set(result, 'personalization', data);
        }
    },
    addToTestament(state, person) {
        let index = _.findIndex(state.testamentPeople, {id: person.id}),
            res = state.testamentPeople[index];

        person = Object.assign({
            legal: 0,
            min: 0,
            value: 0,
            amount: 0,
            personalization: {},
            valueType: 'value',
        }, res, person);

        _.set(person, 'parent', null);
        _.set(person, 'children', []);

        _.set(person, 'value', Number(person.value).toFixed(2));
        _.set(person, 'amount', Number(person.amount).toFixed(2));
        _.set(person, 'legal', Number(person.legal).toFixed(2));
        _.set(person, 'min', Number(person.min).toFixed(2));

        _.set(person, 'valueType', person.valueType);

        _.set(person, 'sortIndex', state.sortIndex);

        state.sortIndex++;

        if (res) {
            state.testamentPeople.splice(index, 1, person);
        }
        else {
            state.testamentPeople.push(person);
        }
    },
    removeFromTestament(state, person) {
        let index = _.findIndex(state.testamentPeople, {id: person.id});

        if (index !== -1) {
            state.testamentPeople.splice(index, 1);
        }
    },
    setValue(state, {person, value}) {
        let res = _.find(state.testamentPeople, {id: person.id});
        if (res) {
            _.set(res, 'value', value);
        }
    },
    setTestator(state, testator) {
        state.testator = testator;
    },
    addNgo(state, ngo) {
        let index = _.findIndex(state.ngos, {id: ngo.id});
        if (index === -1) {
            state.ngos.push(ngo);
        }
        else {
            state.ngos.splice(index, 1, ngo);
        }
    },
    removeNgo(state, ngo) {
        let index = _.findIndex(state.ngos, {id: ngo.id});
        if (index !== -1) {
            state.ngos.splice(index, 1);
        }
    },
    setResponse(state, {url, code, testament}) {
        state.consultingCode = code;
        state.pdfUrl = url;
        state.testamentId = testament;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
    removeType(state, type) {
        let itemsToRemove = _.filter(state.testamentPeople, {type: type}),
            index;

        _.forEach(itemsToRemove, item => {
            index = _.findIndex(state.testamentPeople, {id: item.id});
            state.testamentPeople.splice(index, 1);
        });
    },
    familyTree(state, familyTree) {
        state.familyTree = familyTree;
    },
    mother(state, mother) {
        state.mother = mother;
    },
    father(state, father) {
        state.father = father;
    },
    paternalFather(state, paternalFather) {
        state.paternalFather = paternalFather;
    },
    paternalMother(state, paternalMother) {
        state.paternalMother = paternalMother;
    },
    maternalFather(state, maternalFather) {
        state.maternalFather = maternalFather;
    },
    maternalMother(state, maternalMother) {
        state.maternalMother = maternalMother;
    },
    maternalFamilyTree(state, maternalFamilyTree) {
        state.maternalFamilyTree = maternalFamilyTree;
    },
    paternalFamilyTree(state, paternalFamilyTree) {
        state.paternalFamilyTree = paternalFamilyTree;
    },
    setEmail(state, email) {
        state.email = email;
    },
    resetSliderValues(state) {
        // this will reset the assigned values of all people without
        // a legal minimum value to 0 (to prevent totals of > 100%)
        let people = _.filter(state.testamentPeople, item => {
            return _.get(item, 'min', 0) <= 0;
        });

        _.forEach(people, person => {
            person.value = 0;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
