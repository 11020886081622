const initialState = {
    loaded: false,
    module: 'testament',
    profiles: [],
    filter: {
        seed: null,
        searchString: null,
        categories: [],
        services: [],
    },
    meta: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    },
    per_page: 9
};

const state = _.cloneDeep(initialState);

const getters = {
    profiles: state => state.profiles,
    meta: state => state.meta,
    getFilter: state => state.filter,
};

const actions = {
    load({state, commit}) {
        if (!state.loaded) {
            commit('filter', {});
            return axios.post(`/api/profiles/${state.module}/npo?per_page=${state.per_page}`, state.filter).then(response => {
                commit('load', response.data);
            });
        }
    },
    reload({state, commit}) {
        return axios.post(`/api/profiles/${state.module}/npo?per_page=${state.per_page}`, state.filter).then(response => {
            commit('load', response.data);
        });
    },
    setPerPage({commit}, perPage) {
        commit('setPerPage', perPage);
    },
    setModule({commit}, module) {
        commit('setModule', module);
    },
    get({state, commit}, filter) {
        commit('filter', filter);

        return axios.post(`/api/profiles/${state.module}/npo?per_page=${state.per_page}`, state.filter).then(response => {
            commit('load', response.data);
        });
    },
    next({state, commit}) {
        let page = state.meta.current_page + 1;
        return axios.post(`/api/profiles/${state.module}/npo?per_page=${state.per_page}&page=${page}`, state.filter).then(response => {
            commit('load', response.data);
        });
    },
    back({state, commit}) {
        let page;

        if (state.meta.current_page > 1) {
            page = state.meta.current_page - 1;
        } else {
            page = 1;
        }
        return axios.post(`/api/profiles/${state.module}/npo?per_page=${state.per_page}&page=${page}`, state.filter).then(response => {
            commit('load', response.data);
        });
    },
};

const mutations = {
    load(state, data) {
        state.profiles = data.data;

        state.meta.current_page = data.meta.current_page;
        state.meta.from = data.meta.from;
        state.meta.last_page = data.meta.last_page;
        state.meta.per_page = data.meta.per_page;
        state.meta.to = data.meta.to;
        state.meta.total = data.meta.total;

        state.loaded = true;
    },
    filter(state, filter) {
        state.filter.searchString = filter.searchString;
        if (!state.filter.seed) {
            state.filter.seed = _.random(0, 1, true);
        }

        state.filter.categories.length = 0;
        Object.assign(state.filter.categories, filter.categories);

        state.filter.services.length = 0;
        Object.assign(state.filter.services, filter.services);
    },
    setModule(state, module) {
        if (module) {
            state.module = module;
        } else {
            state.module = 'testament';
        }
    },
    setPerPage(state, perPage) {
        if (_.isInteger(perPage) && perPage > 0) {
            state.per_page = perPage;
        } else {
            state.per_page = 9;
        }
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
