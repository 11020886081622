import store from '@/store';
import {EventBus} from '@/utils/event-bus';

export default async function loadEnv() {
    const thenable = {
        then(resolve) {
            axios.get('/webapi/env').then(response => {
                let env = _.get(response, 'data.data', {});
                store.dispatch('env/setEnv', env);

                let user = _.get(response, 'data.data.user', {});
                store.dispatch('user/set', user);

                let abilities = _.get(response, 'data.data.abilities', []);

                // todo: check if this can be refactored, some components still depend on this event
                if (store.getters['env/isAuthenticated']) {
                    EventBus.$emit('logged-in');
                }

                resolve({
                    env,
                    user,
                    abilities,
                });
            });
        },
    };

    return thenable;
}