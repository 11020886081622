<template>
    <div v-if="user">
        <v-toolbar dense flat color="transparent" class="mb-4">
            <v-avatar color="accent" class="mr-4 cursor-pointer">
                <span class="text-h5 text-white">{{ user.avatar_text }}</span>
            </v-avatar>
            <v-toolbar-title>
                {{ user.name }}
                <div class="text-caption text-grey">{{ $t('User.title') }}</div>
            </v-toolbar-title>
        </v-toolbar>

        <v-row>
            <v-col cols="12" md="3">
                <v-card>
                    <v-list nav density="compact" color="primary">
                        <v-list-subheader class="text-uppercase">{{ $t('User.settings') }}</v-list-subheader>
                        <v-list-item :to="{name: 'user-profile'}" data-cy="user-nav-btn-profile">
                            <v-list-item-title>{{ $t('User.profile') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.has_zoho_contact" :to="{name: 'user-notifications'}" data-cy="user-nav-btn-notifications">
                            <v-list-item-title>{{ $t('User.notifications') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{name: 'user-account'}" data-cy="user-nav-btn-account">
                            <v-list-item-title>{{ $t('User.account') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{name: 'user-security'}" data-cy="user-nav-btn-security">
                            <v-list-item-title>{{ $t('User.accountSecurity') }}</v-list-item-title>
                            <template v-if="user.two_factor_mobile_phone" #append>
                                <v-icon color="green" size="small">mdi-check-circle</v-icon>
                            </template>
                        </v-list-item>
                        <v-list-item :to="{name: 'user-deletion'}" data-cy="user-nav-btn-deletion">
                            <v-list-item-title>{{ $t('User.accountDeletion') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="9">
                <router-view />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'User',
    components: {},
    props: {
        verified: Boolean,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', [
            'user'
        ]),
    },
};
</script>

<style scoped lang="scss">
</style>
