<template>
    <v-container class="text-center">
        <affiliate-header />

        <v-card class="pa-5 rounded-md text-center lg:rounded-xl" flat>
            <template v-if="!hasProfile">
                <img
                    v-if="getLogo"
                    :src="getLogo"
                    :style="{'height': $vuetify.display.mdAndDown ? '3rem': '4rem'}"
                    class="d-inline-block my-12"
                >
                <v-icon v-else :size="$vuetify.display.mdAndDown ? '8rem' : '10rem'">
                    custom:DeinAdieuLogo
                </v-icon>
            </template>
            <v-alert 
                v-if="$route.query.deleted"
                density="compact"
                type="info"
                data-cy="info-deleted"
                class="mb-4 text-left"
            >
                {{ $t('UserDeletion.deletedInfo') }}
            </v-alert>
            <v-card-title class="text-h5">
                {{ $t('LoginDialog.title') }}
            </v-card-title>
            <v-card-text>
                <login-form
                    :path="path"
                />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import LoginForm from '../components/App/LoginForm';
import AffiliateHeader from './affiliate/AffiliateHeader.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Login',
    components: {
        LoginForm,
        AffiliateHeader,
    },
    props: {
        path: String,
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            profile: 'profile',
            hasProfile: 'hasProfile',
        }),
        ...mapGetters('env', [
            'getLogo',
        ]),
    },
    watch: {
        '$route'(to) {
            if (to.name === 'login-affiliate') {
                this.affiliateSlug(to.params.profile_slug);
            }
        },
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);
        if (profileSlug) {
            this.affiliateSlug(profileSlug);
        }
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),
        affiliateSlug(slug) {
            this.loadAffiliate(slug);
        },
    },
};
</script>

<style scoped>

</style>
