<template>
    <div class="relative">
        <h1 class="text-h6 mb-2 p-4 lg:pl-0">
            {{ $t('AdminStatistic.title') }}
        </h1>

        <div class="absolute right-4 top-4 flex lg:right-0">
            <!-- Toplisting "Testament" only! -->
            <v-chip
                v-if="showToplistingBadge"
                color="grey-lighten-4"
                class="mr-3 mt-0.5 px-4"
                :class="'toplisting-' + toplistingStatus"
                variant="flat"
            >
                <span v-if="toplistings.addon_toplisting_testament">{{ $t('AdminStatistic.toplisting.testament.active') }}</span>
                <a v-else :href="$t('AdminStatistic.toplisting.url')" target="_blank">{{ $t('AdminStatistic.toplisting.testament.inactive') }}</a>
            </v-chip>

            <date-range @update="setRange" />
        </div>

        <div class="mt-4 px-4 lg:!px-0">
            <template v-for="statistic in statistics">
                <v-btn
                    v-if="statistic.show"
                    :key="statistic.key"
                    rounded="xl"
                    class="my-2 mr-2 mt-0 font-normal normal-case tracking-normal"
                    :class="statistic.key === selectedStatistic.key ? 'bg-gray-500 text-white' : 'bg-gray-100'"
                    :data-cy="'btn-statistic-' + statistic.key"
                    elevation="0"
                    variant="flat"
                    @click="setStatistic(statistic)"
                >
                    <v-icon size="medium" class="mr-2">{{ statistic.icon }}</v-icon>
                    {{ getLabel(statistic) }}
                </v-btn>
            </template>
        </div>

        <chart-segments
            :selected-statistic="selectedStatistic"
            :date-range="dateRange"
            :toplistings="toplistings"
        />
    </div>
</template>

<script>
import ChartSegments from './statistic/ChartSegments';
import DateRange from '@/components/DateRange';
import locizeKeys from '@/locize/admin/AdminStatistic';
import {Features as $Features} from "@/plugins/features";
import {mapGetters} from 'vuex';

export default {
    name: 'AdminStatistic',

    components: {
        ChartSegments,
        DateRange,
    },

    data() {
        return {
            dateRange: [],
            toplistings: {
                addon_toplisting_directory: false,
                addon_toplisting_testament: false,
            },
            selectedStatistic: null,
        };
    },

    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),

        ...mapGetters('env', {
            isSwitzerland: 'isSwitzerland',
        }),

        statistics() {
            return [
                {
                    key: 'listing',
                    icon: 'mdi-account',
                    types: ['preview', 'preview_home', 'preview_listing', 'preview_testament', 'preview_consulting', 'view'],
                    show: true,
                },
                {
                    key: 'testament',
                    icon: 'mdi-gavel',
                    types: ['preview', 'clicks', 'clicks_ext', 'conversion', 'conversion_ext', 'consideration', 'consideration_ext', 'consideration_int'],
                    show: this.$hasFeature($Features.Testament) && this.showTestamentStatistics,
                },
                {
                    key: 'patient-decree',
                    icon: 'mdi-file-sign',
                    types: ['clicks', 'conversion'],
                    show: this.$hasFeature($Features.PatientDecree),
                },
                {
                    key: 'advance-care-directive',
                    icon: 'mdi-file-account-outline',
                    types: ['clicks', 'conversion'],
                    show: this.$hasFeature($Features.AdvanceCareDirective),
                },
                {
                    key: 'consulting-contact',
                    icon: 'mdi-phone',
                    types: ['preview', 'clicks', 'clicks_ext', 'conversion'],
                    show: this.$hasFeature($Features.Consulting),
                },
            ];
        },

        profileType() {
            return this.$ability.can('ngo', 'Statistic') ? 'ngo' : 'service-provider';
        },

        showTestamentStatistics() {
            if (this.profileType === 'service-provider') {
                return true;
            }

            // only show testament statistics for accounts with profiles & active subscription
            return this.$ability.can('testament', 'Statistic');
        },

        toplistingStatus() {
            return this.toplistings.addon_toplisting_testament ? 'active' : 'inactive';
        },

        showToplistingBadge() {
            return ['listing', 'testament'].includes(this.selectedStatistic.key) 
                && this.$vuetify.display.mdAndUp
                && this.isSwitzerland
                && this.profileType === 'ngo';
        },
    },

    watch: {
        '$route.params.statistic'() {
            this.setSelectionByUrlParam();
        },
    },

    mounted() {
        this.load();
    },

    beforeMount() {
        this.setSelectionByUrlParam();
    },

    methods: {
        load() {
            axios.get(`/webapi/accounts/${this.user.account_id}/toplistings`).then(response => {
                this.toplistings = response.data;
            });
        },

        getLabel(statistic) {
            return this.$t(_.get(locizeKeys, `${this.profileType}.${statistic.key}.label`));
        },

        setRange(range) {
            this.dateRange = range;
        },

        setStatistic(statistic) {
            this.selectedStatistic = statistic;

            if (statistic.key !== this.$route.params.statistic) {
                this.$router.push({
                    name: 'admin-statistic-detail',
                    params: {
                        statistic: statistic.key
                    }
                });
            }
        },

        setSelectionByUrlParam() {
            this.selectedStatistic = _.find(this.statistics, {
                key: _.get(this.$route.params, 'statistic')
            }) || _.first(this.statistics);
        },
    },
};
</script>

<style scoped lang="scss">
.toplisting-active {
    background: #fffbdf !important;
    color: #ac830a !important;
}
</style>
