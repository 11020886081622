<template>
    <div>
        <v-toolbar dense flat color="transparent">
            <v-toolbar-title class="text-h6">
                {{ $t('AdminLeads.title') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn variant="flat" color="accent" class="text-none" href="/leads/export">
                <v-icon start>mdi-microsoft-excel</v-icon>
                {{ $t('AdminLeads.table.downloadExcel') }}
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text class="pa-0">
                <v-data-table-server
                    v-model:items-per-page="itemPerPage"
                    v-model:sort-by="sortBy"
                    v-model:options="options"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :items-length="meta.total || 0"
                    item-key="id"
                    multi-sort
                    data-cy="admin-leads"
                    :items-per-page-options="[
                        {value: 10, title: '10'},
                        {value: 15, title: '15'},
                        {value: 20, title: '20'},
                        {value: 50, title: '50'},
                        {value: 100, title: '100'},
                        {value: 200, title: '200'},
                        {value: -1, title: '$vuetify.dataFooter.itemsPerPageAll'}
                    ]"
                    @click:row="onRowClick"
                    @update:options="load"
                >
                    <template #top>
                        <v-toolbar density="compact" flat color="transparent">
                            <v-toolbar-title>
                                {{ $t('AdminTestator.personData') }}
                            </v-toolbar-title>
                            <v-spacer />
                            <v-menu
                                :close-on-content-click="false"
                                location="bottom end"
                                width="300"
                            >
                                <template #activator="{ props }">
                                    <v-btn
                                        v-bind="props"
                                        variant="text"
                                        class="tw-ml-auto"
                                    >
                                        {{ $t('OneWord.filter') }}
                                        <v-badge :model-value="filters.ngo_is_visible !== 1" dot color="red">
                                            <v-icon end>mdi-filter-outline</v-icon>
                                        </v-badge>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item-title class="text-subtitle-2 mx-4 mb-1 text-gray-600">{{ $t('AdminLeads.table.ngoIsVisible') }}</v-list-item-title>
                                    <v-list-item>
                                        <v-select
                                            v-model="filters.ngo_is_visible"
                                            variant="outlined"
                                            hide-details
                                            :items="[
                                                {title: $t('OneWord.showAll'), value: null},
                                                {title: $t('OneWord.yes'), value: 1},
                                                {title: $t('OneWord.no'), value: 0},
                                            ]"
                                            density="compact"
                                        />
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                    </template>
                    <template #header.is_favored="{ column, isSorted, getSortIcon, sortBy }">
                        <div class="v-data-table-header__content">
                            <v-tooltip location="bottom">
                                <template #activator="{ props }">
                                    <span v-bind="props">{{ column.title }}</span>
                                    <template v-if="isSorted(column)">
                                        <v-icon :icon="getSortIcon(column)" />
                                        <v-badge
                                            v-if="sortPos(sortBy) > 1"
                                            inline
                                            color="#0000001F"
                                            text-color="black"
                                            :content="sortPos(sortBy)"
                                        />
                                    </template>
                                </template>
                                <span>{{ $t('AdminLeads.dataTable.isFavoredHeaderTooltip') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template #item.first_name="{item, value}">
                        <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template #item.last_name="{item, value}">
                        <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template #item.lead_type="{value}">
                        {{ $t(`AdminLeads.leadType.items.${value}`) }}
                    </template>
                    <template #item.lead_source="{value}">
                        {{ $t(`AdminLeads.leadSource.items.${value}`) }}
                    </template>
                    <template #item.ngo_thanks_computed="{value}">
                        <v-icon v-if="value === 2" color="blue">mdi-information</v-icon>
                        <v-icon v-else-if="value === 1" color="green">mdi-check-circle</v-icon>
                        <v-icon v-else-if="value === 0" color="red">mdi-minus-circle-outline</v-icon>
                        <v-tooltip v-else location="bottom">
                            <template #activator="{ props }">
                                <span

                                    v-bind="props"
                                    class="text-caption"
                                >{{ $t('OneWord.notAvailableIcon') }}</span>
                            </template>
                            <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                        </v-tooltip>
                    </template>
                    <template #item.ngo_info="{value}">
                        <v-icon v-if="value" color="green">mdi-check-circle</v-icon>
                        <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                    </template>
                    <template #item.is_favored="{value, item}">
                        <template v-if="item.lead_type === 'will' && value !== null">
                            <v-icon v-if="value" color="green">mdi-check-circle</v-icon>
                            <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                        </template>
                        <v-tooltip v-else location="bottom">
                            <template #activator="{ props }">
                                <span
                                    v-bind="props"
                                    class="text-caption"
                                >{{ $t('OneWord.notAvailableIcon') }}</span>
                            </template>
                            <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                        </v-tooltip>
                    </template>
                    <template #item.created_at="{value}">
                        <span class="whitespace-nowrap">
                            {{ $filters.formatDateTime(value) }}
                        </span>
                    </template>
                    <template #item.deletion_scheduled_at="{value}">
                        {{ $filters.date(value) }}
                    </template>
                    <template #item.ngo_is_visible="{value}">
                        <v-icon v-if="value" color="green">mdi-check-circle</v-icon>
                        <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                    </template>
                    <template #item.actions="{item}">
                        <lead-actions-menu :item="item" @update:toggle="updateToggle" @error="errorToggle" />
                    </template>
                </v-data-table-server>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {EventBus} from "@/utils/event-bus";
import {mapGetters} from "vuex";
import LeadActionsMenu from "@/components/Lead/LeadActionMenu.vue";

export default {
    name: "AdminLeads",
    components: {LeadActionsMenu},
    data() {
        return {
            items: [],
            loading: false,
            meta: {},
            options: {},
            filters: {
                ngo_is_visible: 1,
            },
            sortBy: [ { "key": "created_at", "order": "desc" } ],
            itemsFilter: null,
            itemPerPage: 15,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        headers() {
            return [
                {title: this.$t('AdminLeads.ID'), value: 'id', sortable: true},
                {title: this.$t('AdminLeads.email'), value: 'email', sortable: true},
                {title: this.$t('AdminLeads.leadType.title'), value: 'lead_type', sortable: true},
                {title: this.$t('OneWord.date'), value: 'created_at', sortable: true},
                {title: this.$t('OneWord.firstName'), value: 'first_name', sortable: true},
                {title: this.$t('OneWord.lastName'), value: 'last_name', sortable: true},
                {title: this.$t('AdminLeads.source'), value: 'lead_source', sortable: true},
                {title: this.$t('AdminTestator.ngoThanks'), value: 'ngo_thanks_computed', sortable: true},
                {title: this.$t('AdminTestator.ngoInfo'), value: 'ngo_info', sortable: true},
                {title: this.$t('AdminLeadEdit.section.will.isFavouredInfo'), value: 'is_favored', sortable: true},
                {title: this.$t('AdminLeads.table.deletionScheduledAt'), value: 'deletion_scheduled_at', sortable: false},
                {title: this.$t('AdminLeads.table.ngoIsVisible'), value: 'ngo_is_visible', sortable: false},
                {title: '', value: 'actions', sortable: false},
            ];
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },
    methods: {
        load() {
            this.loading = true;

            let params = {
                page: this.options.page || 1,
                per_page: this.options.itemsPerPage || this.itemsPerPage,
                sort_by: this.options.sortBy || [],
                filters: {...this.filters},
            };

            axios.get(`/webapi/leads`, {params: params}).then(response => {
                this.items = response.data.data;
                this.meta = response.data.meta;
            }).catch(error => {
                EventBus.$emit('error', error.response.data.message);
            }).finally(() => {
                this.loading = false;
            });
        },
        onRowClick(event, {item}) {
            this.$router.push({
                name: 'admin-lead-edit',
                params: {lead_id: item.id},
            });
        },
        sortPos(sortBy) {
            return _.findIndex(sortBy, {key: 'is_favored'}) + 1;
        },
        updateToggle() {
            this.load();
            EventBus.$emit('success');
        },
        errorToggle(message) {
            EventBus.$emit('error', message);
        },
    }
}
</script>

<style scoped>

</style>