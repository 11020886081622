const initialState = {
    loaded: false,
    notifications: [],
    unread_count: 0,
};

const state = _.cloneDeep(initialState);

const getters = {
    getNotifications: state => state.notifications,
    getUnreadCount: state => state.unread_count,
    getProminentNotification: state => {
        return _.first(_.filter(state.notifications, {
            data: {
                is_prominent: 1,
            }
        }));
    },
};

const actions = {
    load({state, commit}) {
        if (!state.loaded) {
            return axios.get('/webapi/user/notifications').then(response => {
                commit('load', _.get(response, 'data'));
            }).catch(() => {
                commit('load');
            });
        }
    },
    reload({commit}) {
        axios.get('/webapi/user/notifications').then(response => {
            commit('load', _.get(response, 'data'));
        }).catch(() => {
            commit('load');
        });
    },
};

const mutations = {
    load(state, data) {
        state.notifications = _.get(data, 'data', []);
        state.unread_count = _.get(data, 'unread_count', 0);
        state.loaded = data ? true : false;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
