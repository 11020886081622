<template>
    <vee-form @submit="saveDescription()">
        <v-card :loading="loading" :disabled="loading" class="mt-4">
            <v-card flat>
                <v-card-text>
                    <profile-description-form v-model="profile" />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        ref="profileDescriptionSaveButton"
                        :loading="loading"
                        type="submit"
                    >
                        <v-icon start>mdi-content-save</v-icon>
                        {{ $t('OneWord.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-card>
    </vee-form>
</template>

<script>
import ProfileDescriptionForm from '../form/ProfileDescriptionForm';
import {EventBus} from '../../../utils/event-bus';
import {Form as VeeForm} from "vee-validate";
import store from '../../../store';
import {mapGetters} from 'vuex';

export default {
    name: 'ProfileDescription',
    components: {ProfileDescriptionForm, VeeForm},
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.profileChanged) {
            this.$root.$confirm(
                this.$t('OneWord.confirmUnsavedChangesTitle'),
                this.$t('OneWord.confirmUnsavedChangesInfoText'),
            ).then(confirm => {
                confirm ? next() : next(false);
            });
        }
        else {
            next();
        }
    },
    props: {
        lang: String,
    },
    data() {
        return {
            loading: false,
            profile: {},
            profileChanged: false,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        profileId() {
            return _.parseInt(_(this.$route.params).get('profile_id'));
        },
    },
    watch: {
        profile: {
            deep: true,
            handler() {
                this.profileChanged = true;
            },
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        saveDescription() {
            this.loading = true;
            return axios.put(
                `/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`,
                _.pickBy(this.profile, (value, key) => {
                    return _.includes(['profile_title', 'description', 'key_figures', 'services', 'service_categories'], key);
                }),
                {
                    headers: {
                        'DA-Accept-Language': this.lang,
                    },
                },
            ).then(() => {
                this.profileChanged = false;
                EventBus.$emit('success', this.$t('AdminProfile.saveSuccess'));
            }).finally(() => {
                this.loading = false;
            });
        },
        load() {
            this.loading = true;

            axios.get(`/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.profile = response.data.data;
            }).finally(() => {
                this.profileChanged = false;
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
