<template>
    <svg version="1.1" viewBox="0 0 622.1 243" xmlns="http://www.w3.org/2000/svg" class="da-logo">
        <path class="st0" d="m120.9 6.3c-63.2 0-114.6 51.4-114.6 114.5 0 25.9 8.4 50.3 24.3 70.5l-2.5 38.1 38.4-7.7c16.6 8.9 35.3 13.6 54.2 13.6 63.1 0 114.5-51.4 114.5-114.5 0.2-63.1-51.2-114.5-114.3-114.5zm-73.1 139.4h-14.1v-4.7h4.3v-43.3h-4.3v-4.7h13.9c10.6 0 16.9 4.7 16.9 26.4 0 11.6-0.6 26.3-16.7 26.3zm62.1-48h-20.4v17.4h16.9v4.7h-16.9v21.2h20.4v4.7h-25.7v-52.7h25.7v4.7zm42.9 0h-7.2v43.3h7.2v4.7h-19.6v-4.7h7.1v-43.3h-7.1v-4.7h19.6v4.7zm51.4 48h-6.6l-15.7-44.5-0.2 0.2 0.2 11.9v32.4h-5.3v-52.7h6.6l15.7 44.4 0.2-0.2-0.2-11.8v-32.4h5.3v52.7z" />
        <path class="st0" d="m48.9 97.7h-5.6v43.3h4.4c6.4 0 11.3-1.7 11.3-21.6 0-20.1-5.4-21.7-10.1-21.7z" />
        <path class="st1" d="m265.9 93-13.4 52.4 5.2 1.4 4.1-17.3h14.4l4.1 17.3 5.2-1.4-13.3-52.4h-6.3zm-2.7 31.7 5.8-24.7h0.2l5.9 24.7h-11.9z" />
        <path class="st1" d="m316.2 93h-13.9v4.7h4.3v43.3h-4.3v4.7h14.1c16.1 0 16.7-14.7 16.7-26.4-0.1-21.7-6.3-26.3-16.9-26.3zm0 48h-4.4v-43.3h5.6c4.7 0 10 1.6 10 21.7 0.1 19.9-4.8 21.6-11.2 21.6z" />
        <polygon class="st1" points="354.2 97.7 361.3 97.7 361.3 141 354.2 141 354.2 145.7 373.8 145.7 373.8 141 366.6 141 366.6 97.7 373.8 97.7 373.8 93 354.2 93" />
        <polygon class="st1" points="400.2 145.7 425.9 145.7 425.9 141 405.5 141 405.5 119.9 422.3 119.9 422.3 115.1 405.5 115.1 405.5 97.7 425.9 97.7 425.9 93 400.2 93" />
        <path class="st1" d="m466.6 131.3c0 5.1-1.1 10.1-7.7 10.1s-7.6-4.9-7.6-10.1v-38.3h-5.3v40.2c0 7.8 4.9 13.3 12.9 13.3 7.8 0 13-5.3 13-13.5v-40h-5.3v38.3z" />
        <rect
            class="st1"
            x="502.8"
            y="134"
            width="7.3"
            height="11.7"
        />
        <path class="st1" d="m555 141.5c-9.7 0-10.3-15.2-10.3-22.1 0-7 0.6-22.1 10.3-22.1 4 0 6.7 2.9 8.4 6.3l4.5-2.7c-2.8-5.1-6.9-8.5-12.9-8.5-13.8 0-15.8 16.6-15.8 27.1 0 10.4 2.1 27.1 15.8 27.1 6 0 10.6-3.6 13.7-8.6l-4.4-3c-1.9 3.1-5.2 6.5-9.3 6.5z" />
        <polygon class="st1" points="609.5 93 609.5 115.1 593.2 115.1 593.2 93 587.9 93 587.9 145.7 593.2 145.7 593.2 119.9 609.5 119.9 609.5 145.7 614.8 145.7 614.8 93" />
    </svg>
</template>

<script>
export default {
    name: 'DeinAdieuLogo',
};
</script>

<style scoped>
    .st0 {
        fill: #59364C;
    }

    .st1 {
        fill: #140824;
    }
</style>
