const $t = (t) => t;

/**
 * Locize key mapping for dynamic locize keys
 * to keep the component clean and readable.
 */
export default {
    'toplisting': {
        'active': $t('AdminStatistic.toplisting.testament.active'),
        'inactive': $t('AdminStatistic.toplisting.testament.inactive'),
    },
    'ngo': {
        'listing': {
            'label': $t('AdminStatistic.statistic.listing.label'),
        },
        'testament': {
            'label': $t('AdminStatistic.statistic.testamenttool.label'),
        },
        'patient-decree': {
            'label': $t('AdminStatistic.statistic.patientDecree.label'),
        },
        'advance-care-directive': {
            'label': $t('AdminStatistic.statistic.advancedCareDirective.label'),
        },
        'consulting-contact': {
            'label': $t('AdminStatistic.statistic.consulting.label'),
        },
    },
    'service-provider': {
        'listing': {
            'label': $t('AdminStatistic.service-provider.listing.label'),
        },
        'testament': {
            'label': $t('AdminStatistic.service-provider.testamenttool.label'),
        },
        'patient-decree': {
            'label': $t('AdminStatistic.service-provider.patientDecree.label'),
        },
        'advance-care-directive': {
            'label': $t('AdminStatistic.service-provider.advancedCareDirective.label'),
        },
        'consulting-contact': {
            'label': $t('AdminStatistic.service-provider.consulting.label'),
        },
    },
};
