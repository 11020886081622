/**
 * Lets PHP Storm see the vuex modules.
 */

import account from './account';
import advanceCareDirective from './advanceCareDirective';
import affiliateProfile from './affiliateProfile';
import consulting from './consulting';
import patientDecree from './patientDecree';
import publicProfiles from './publicProfiles';
import testament from './testament';
import user from './user';
import notifications from './notifications';
import env from './env';

export default {
    account,
    advanceCareDirective,
    affiliateProfile,
    consulting,
    patientDecree,
    publicProfiles,
    testament,
    user,
    notifications,
    env,
}