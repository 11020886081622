<template>
    <svg
        class="noun-child-icon"
        width="84"
        height="84"
        enable-background="new 0 0 100 100"
        fill="#000000"
        version="1.1"
        viewBox="0 0 84 84"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >

        <g transform="translate(-2)"><path d="m63.266 59.277c0.192 0 0.386-0.016 0.58-0.049 1.836-0.319 3.067-2.065 2.749-3.901-0.567-3.28-3.691-19.869-9.347-24.294-2.603-2.036-7.5-2.951-12.452-2.951-5.181 0-10.419 1.002-13.154 2.778-6.094 3.95-8.891 19.785-9.604 24.542-0.278 1.844 0.994 3.561 2.84 3.837 0.17 0.024 0.337 0.038 0.505 0.038 1.642 0 3.083-1.201 3.332-2.875 0.815-5.459 2.362-11.607 4.007-15.649-0.124 0.686-0.213 1.385-0.213 2.109v9.901c0 0.032 0.022 0.059 0.022 0.092 0.011 9.874 0.292 18.964 0.864 27.758 0.14 2.141 1.922 3.785 4.036 3.785 0.089 0 0.178-3e-3 0.27-8e-3 2.233-0.146 3.923-2.074 3.777-4.304-0.472-7.241-0.734-14.717-0.815-22.663 1.185 0.143 2.419 0.216 3.653 0.216 1.218 0 2.435-0.07 3.607-0.211-0.081 7.978-0.343 15.471-0.818 22.706-0.146 2.233 1.544 4.161 3.774 4.306 0.092 5e-3 0.181 8e-3 0.273 8e-3 2.114 0 3.893-1.642 4.036-3.785 0.57-8.71 0.85-17.741 0.864-27.555 0.016-0.119 0.081-0.221 0.081-0.346v-9.901c0-0.445-0.084-0.869-0.132-1.301 1.574 4.109 3.059 9.857 3.939 14.92 0.289 1.638 1.698 2.797 3.326 2.797z" /><g transform="translate(.51113 -1.937)">
            <circle cx="43.812" cy="14.762" r="12.825" />
        </g></g>
    </svg>
</template>

<script>
export default {
    name: 'Child',
};
</script>

<style scoped lang="scss">
    .noun-child-icon {
        fill: currentColor;
        width: auto;
    }
</style>
