<template>
    <v-card v-if="buttons.length > 0" class="mb-8" color="#fafcfc">
        <v-img
            :src="require('@/assets/img/app/bg/document.jpg')"
            class="align-center text-white"
            position="top center"
            max-height="260px"
            min-height="200px"
            cover
        >
            <v-card-title class="d-block text-center">{{ $t('TestamentCreated.card3.title') }}</v-card-title>
            <div class="d-block text-center">
                <v-btn
                    v-for="(button, index) in buttons"
                    :id="button.id"
                    :key="`btn-${index}`"
                    class="text-none mx-4 my-1"
                    variant="flat"
                    size="large"
                    @click="open(button)"
                >
                    <v-icon start size="large">{{ button.icon }}</v-icon>
                    {{ button.label }}
                </v-btn>
            </div>
        </v-img>
    </v-card>
</template>

<script>
export default {
    name: 'MoreDocuments',
    props: {
        buttons: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    methods: {
        open(button) {
            let target = _.get(button, 'to');

            if (_.isObject(target)) {
                this.$router.push(target);
            } else {
                window.open(target, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    border: 0;
}

.v-btn {
    color: #000;
    &:hover {
        color: #fff;
        background: var(--da-accent) !important;
    }
}

:deep(.v-image__image--cover) {
    background-position: top center !important;
}

.v-icon {
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
}
</style>
