"use strict";

import axios from 'axios';
import store from '@/store';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || "",
    timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

axios.create(config);

axios.interceptors.response.use(
    function(config) {
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {

        const originalRequest = error.config;

        if (_.get(error, 'response.status') === 401) {
            store.dispatch('env/logout')
        }

        if (_.get(error, 'response.status') === 419 && !originalRequest._retry) {
            originalRequest._retry = true;

            axios.get('/sanctum/csrf-cookie').finally(() => {
                return axios(originalRequest).catch(() => {
                    // another error occurred... stop trying
                });
            });
        }

        throw error;
    }
);

window.axios = axios;

export default axios;
