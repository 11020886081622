<template>
    <div>
        <v-menu 
            v-model="menuOpen"
            :close-on-content-click="false"
        >
            <template #activator="{ props }">
                <v-btn
                    variant="outlined"
                    rounded
                    v-bind="props"
                >
                    {{ dateRangeText }}
                </v-btn>
            </template>
            <v-card width="540px">
                <v-container class="pa-0">
                    <v-row no-gutters>
                        <v-col cols="4" class="border-r">
                            <v-list>
                                <v-list-item @click="setRange('month')">
                                    <v-list-item-title>
                                        {{ $t('AdminStatistic.rangeLastMonth') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="setRange('quarter')">
                                    <v-list-item-title>
                                        {{ $t('AdminStatistic.rangeLast3Months') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="setRange('year')">
                                    <v-list-item-title>
                                        {{ $t('AdminStatistic.rangeLast12Months') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="8">
                            <v-col>
                                <v-form class="m-4" @submit.prevent="submitFilter">
                                    <v-row>
                                        <small class="font-medium">{{ $t('AdminStatistic.dateRangeFrom') }}</small>
                                    </v-row>
                                    <v-row>
                                        <date-field v-model="dateRangeFrom" dense hide-details />
                                    </v-row>
                                    <v-row>
                                        <small class="font-medium">{{ $t('AdminStatistic.dateRangeTo') }}</small>
                                    </v-row>
                                    <v-row>
                                        <date-field v-model="dateRangeTo" dense hide-details />
                                    </v-row>
                                    <v-row>
                                        <v-btn block color="primary" variant="flat" type="submit">
                                            <v-icon>mdi-filter</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
import DateField from '@/components/DateField';
import { formatDate } from '@/composables/Helpers';

export default {
    components: {
        DateField
    },
    data() {
        return {
            menuOpen: false,
            dateRangeFrom: null,
            dateRangeTo: null,
            fromDate: null,
            toDate: null,
        }
    },
    computed: {
        dateRange: {
            get() {
                return [
                    this.dateRangeFrom,
                    this.dateRangeTo,
                ];
            },
            set(value) {
                this.dateRangeFrom = _.first(value);
                this.dateRangeTo = _.last(value);

                this.$emit('update', this.dateRange)
            },
        },
        dateRangeText() {
            return `${moment(this.fromDate).format('DD.MM.YYYY')} - ${moment(this.toDate).format('DD.MM.YYYY')}`;
        },
    },
    mounted() {
        this.setRange('month');
    },
    beforeMount() {
        this.fromDate = new Date();
        this.toDate = new Date();
    },
    methods: {
        submitFilter() {
            this.fromDate = new Date(_.first(this.dateRange));
            this.toDate = new Date(_.last(this.dateRange));
            this.$emit('update', this.dateRange)
            this.menuOpen = false;
        },

        setRange(range) {
            let from = new Date();
            let to = new Date();

            from.setDate(0);
            from.setDate(1);
            to.setDate(0);

            if (range === 'quarter') {
                from.setMonth(from.getMonth() - 3);
            }
            else if (range === 'year') {
                from.setMonth(from.getMonth() - 12);
            }

            this.dateRange = [
                formatDate(from),
                formatDate(to),
            ];

            this.submitFilter();
        },
    },
};
</script>
