<template>
    <div>
        <v-card v-if="webinars.length" class="mt-4">
            <v-card-title>
                {{ $t('AdminWebinars.list.title') }}
            </v-card-title>

            <v-card-text>
                <v-btn-toggle
                    v-model="langForLinks"
                    mandatory
                    density="compact"
                    variant="outlined"
                    divided
                    color="accent"
                >
                    <v-btn v-for="lang in languages" :key="`lang-btn-${lang.locale}`" :value="lang.locale" :data-cy="'btn-webinar-lang-' + lang.locale.substring(0, 2)">
                        <template v-if="$vuetify.display.smAndDown">
                            <span class="uppercase">{{ lang.locale.substring(0, 2) }}</span>
                        </template>
                        <template v-else>
                            {{ lang.label }}
                        </template>
                    </v-btn>
                </v-btn-toggle>

                <v-alert type="info" class="mt-6">
                    {{ $t('AdminWebinars.list.info') }}
                </v-alert>
            </v-card-text>

            <v-card
                v-for="status in ['active', 'past']"
                :id="`section-${status}`"
                :key="status"
                variant="text"
                :rounded="false"
            >
                <v-card
                    v-for="(webinar, index) in webinarsInStatus(status)"
                    :key="webinar.id"
                    variant="outlined"
                    class="text-medium-emphasis mx-4 mt-4 border-gray-200 p-4"
                    :class="[hasSubscription(webinar) ? 'subscribed' : 'not-subscribed', enabled ? 'my-0' : 'my-4' ]"
                    :data-cy="'webinar-item-' + index"
                >
                    <v-row class="mx-1 py-3">
                        <v-col cols="12" lg="1" class="text-center">
                            <v-card flat border class="mt-1 w-16">
                                <div class="text-subtitle-2 text-uppercase calendar-background bg-da-primary text-white">{{ localizeDate(webinar.date, 'MMM', langForLinks).substring(0, 3) }}</div>
                                <div class="text-h5 py-1">{{ localizeDate(webinar.date, 'D', langForLinks) }}</div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="5">
                            <div class="text-subtitle-2 my-1 text-gray-500">{{ localizeDate(webinar.date, 'dddd', langForLinks) }}, {{ localizeDate(webinar.date) }}</div>
                            <h1 class="text-h6 mb-1">{{ webinar.title }}</h1>
                            <div class="text-gray-500">{{ localizeTime(webinar.start_time) }} - {{ localizeTime(webinar.end_time) }}</div>
                        </v-col>
                        <v-col cols="12" lg="6" class="mt-7">
                            <div class="mb-4 mt-2 line-clamp-3 cursor-pointer" @click="dialog = webinar">{{ webinar.description }}</div>
                            <v-chip
                                :color="isPast(webinar) || ! hasSubscription(webinar) ? 'gray' : 'success'"
                                class="text-uppercase absolute right-6 top-6"
                                size="small"
                                variant="flat"
                            >
                                <span v-if="isPast(webinar)" :data-cy="'webinar-' + index + '-status-past'">{{ $t('AdminWebinars.status.past') }}</span>
                                <span v-else-if="hasSubscription(webinar)" :data-cy="'webinar-' + index + '-status-active'">{{ $t('AdminWebinars.status.active') }}</span>
                                <span v-else :data-cy="'webinar-' + index + '-status-inactive'">{{ $t('AdminWebinars.status.inactive') }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                    <v-row class="border-t pt-3">
                        <v-col class="flex py-0">
                            <v-switch
                                :id="'webinar-switch-' + webinar.id"
                                color="primary"
                                class="ml-6 pr-3 pt-1"
                                :model-value="hasSubscription(webinar)"
                                :disabled="isPast(webinar)"
                                :data-cy="'webinar-toggle-' + index"
                                @click="toggleSubscription(webinar)"
                            />

                            <template v-if="hasSubscription(webinar)">
                                <v-text-field
                                    :key="`field-${profile.id}`"
                                    cols="11"
                                    :model-value="addWebinarToUrl(profile.backlinks.webinars[localeCode], webinar.id)"
                                    data-cy="webinars-backlink"
                                    class="mt-1"
                                    flat
                                    prepend-inner-icon="mdi-link"
                                    readonly
                                    variant="solo-filled"
                                >
                                    <template #append>
                                        <v-btn
                                            v-if="!isPast(webinar)"
                                            icon
                                            variant="flat"
                                            color="gray"
                                            @click="copyToClipboard(addWebinarToUrl(profile.backlinks.webinars[localeCode], webinar.id).toString())"
                                        >
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="!isPast(webinar)"
                                            icon
                                            variant="flat"
                                            @click="showQRCode(profile, webinar.id, $t('AdminTools.backlinks.webinar'))"
                                        >
                                            <v-icon>mdi-qrcode</v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            variant="flat"
                                            data-cy="btn-show-registrations"
                                            @click="showRegistrations(webinar)"
                                        >
                                            <v-icon>mdi-badge-account</v-icon>
                                            <span data-cy="webinar-registrations-count">{{ webinar.affiliate_registrations_count }}</span>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </template>

                            <label v-else :for="'webinar-switch-' + webinar.id" class="py-5">{{ $t('AdminWebinars.list.toggle') }}</label>
                        </v-col>
                    </v-row>
                </v-card>

                <v-overlay
                    :key="`overlay-${status}`"
                    persistent
                    :model-value="!enabled && status === 'active'"
                    class="justify-center align-top"
                    scrim="grey"
                    contained
                >
                    <v-card rounded="lg" max-width="500px" class="mt-4 opacity-100">
                        <template #text>
                            <div class="pt-4 text-center">
                                <v-avatar
                                    class="mb-4"
                                    color="warning"
                                    icon="mdi-cancel"
                                    size="x-large"
                                    variant="tonal"
                                />

                                <div class="font-weight-bold mb-2">
                                    {{ $t('AdminWebinars.addonNotEnabledDialog.title') }}
                                </div>

                                <div class="text-body-2 text-medium-emphasis mb-6">
                                    {{ $t('AdminWebinars.addonNotEnabledDialog.infoText') }}
                                </div>

                                <v-btn
                                    block
                                    class="text-none opacity-100"
                                    color="primary"
                                    variant="flat"
                                    :href="$t('AdminWebinars.addonNotEnabledDialog.cta.url')"
                                    target="_blank"
                                >
                                    {{ $t('AdminWebinars.addonNotEnabledDialog.cta.label') }}
                                    <v-icon end>mdi-open-in-new</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-card>
                </v-overlay>
            </v-card>

            <v-card-title class="mt-9 border-t pb-0 pt-6">
                <span class="mx-4">{{ $t('AdminWebinars.title') }}</span>
            </v-card-title>

            <v-card-text v-if="profile" class="mt-3 px-8 pb-0">
                <div>{{ $t('AdminWebinars.backlink.info') }}</div>

                <v-text-field
                    :key="`field-${profile.id}`"
                    :model-value="profile.backlinks.webinars[localeCode]"
                    data-cy="webinars-backlink"
                    class="mr2"
                    flat
                    prepend-inner-icon="mdi-link"
                    readonly
                    single
                    variant="solo-filled"
                >
                    <template #append>
                        <v-btn
                            icon
                            variant="flat"
                            class="-mt-1"
                            color="gray"
                            @click="copyToClipboard(profile.backlinks.webinars[localeCode])"
                        >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            variant="flat"
                            class="-mt-1"
                            @click="showQRCode(profile, null, $t('AdminTools.backlinks.webinar'))"
                        >
                            <v-icon>mdi-qrcode</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-text>
        </v-card>

        <!-- DIALOGS BELOW -->

        <v-dialog
            v-if="dialog"
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 bg-grey-lighten-2">{{ dialog.title }}</v-card-title>
                <v-card-text class="mt-5">{{ dialog.description }}</v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" variant="text" @click="dialog = false">{{ $t('OneWord.ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="registrations.show"
            v-model="registrations.show"
            width="600"
        >
            <v-card>
                <v-card-title class="text-h5 bg-grey-lighten-2" data-cy="webinar-registrations-dialog-headline">{{ $t('OneWord.registrations') }} ({{ registrations.data.length }})</v-card-title>
                <v-card-text>
                    <v-table
                        data-cy="webinar-registrations-dialog-table"
                        fixed-header
                        height="300px"
                        dense
                    >
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('OneWord.firstName') }}</th>
                                    <th class="text-left">{{ $t('OneWord.lastName') }}</th>
                                    <th class="text-left">{{ $t('OneWord.email') }}</th>
                                    <th class="text-left">{{ $t('OneWord.registeredAt') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in registrations.data"
                                    :key="item.id"
                                >
                                    <td>{{ item.first_name }}</td>
                                    <td>{{ item.last_name }}</td>
                                    <td>{{ item.email }}</td>
                                    <td class="whitespace-nowrap">{{ localizeDate(item.created_at) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        v-if="registrations.data.length > 0"
                        data-cy="btn-webinar-registrations-dialog-download"
                        color="primary"
                        variant="text"
                        @click="downloadRegistrations(registrations.webinar)"
                    >
                        <v-icon start>mdi-download</v-icon>
                        {{ $t('OneWord.download') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        data-cy="btn-close-registrations"
                        color="primary"
                        variant="text"
                        @click="registrations.show = false"
                    >
                        {{ $t('OneWord.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="qr.show" width="500">
            <v-card>
                <v-card-title class="text-h5 bg-grey-lighten-2">{{ qr.headline }}</v-card-title>
                <a :href="qr.backlink" target="_blank"><img
                    id="qrcode"
                    :src="qr.image"
                    :alt="qr.filename"
                    width="800"
                    height="800"
                ></a>
                <v-divider color="grey" />
                <div ref="dialog" class="shortlink m-4 flex">
                    <v-text-field
                        :model-value="qr.shortlink"
                        variant="solo-filled"
                        flat
                        readonly
                        single
                        prepend-inner-icon="mdi-link"
                        class="mr2"
                        hide-details
                    >
                        <template #append>
                            <v-btn
                                icon
                                variant="flat"
                                @click="copyToClipboard(qr.shortlink, $refs.dialog)"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </div>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        size="small"
                        color="primary"
                        variant="flat"
                        @click="downloadQRCode()"
                    >
                        <v-icon start>mdi-download</v-icon>
                        {{ $t('OneWord.download') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="qr.show = false"
                    >
                        {{ $t('OneWord.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {localizeDate, localizeTime} from '@/composables/Helpers';

export default {
    name: 'AdminWebinars',
    props: {
        profile: Object,
        languages: Array,
        locale: String,
    },
    data() {
        return {
            loading: false,
            webinars: [],
            enabled: false,
            subscriptions: [],
            profiles: [],
            dialog: null,
            langForLinks: 'de-CH', // default locale
            qr: {
                show: false,
                headline: null,
                image: null,
                backlink: null,
                shortlink: null,
            },
            registrations: {
                show: false,
                webinar: null,
                data: [],
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),

        localeCode() {
            return (this.langForLinks || '').substring(0, 2) ?? 'de';
        },
    },
    watch: {
        langForLinks() {
            this.load();
        }
    },
    mounted() {
        this.langForLinks = this.locale;
        this.load();

        EventBus.$on('locale-changed', () => {
            this.load();
        });
    },
    methods: {
        load() {
            this.loading = true;
            Promise.all([
                axios.get(`/webapi/profiles/${this.profile.id}/webinars`, {
                    headers: {
                        'DA-Accept-Language': this.langForLinks.replace('-', '_'),
                    }
                }),
                axios.get(`/webapi/profiles/${this.profile.id}/webinars?scope=subscribed`)
            ]).then(([webinars, subscriptions]) => {
                this.webinars = _.sortBy(webinars.data.data, 'date');
                this.enabled = webinars.data.enabled;
                this.subscriptions = subscriptions.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },

        activeWebinars() {
            return _.filter(this.webinars, webinar => this.isPast(webinar) === false);
        },

        pastWebinars() {
            return _.filter(this.webinars, webinar => this.isPast(webinar));
        },

        webinarsInStatus(status) {
            if (status === 'past') {
                return this.pastWebinars();
            }

            return this.activeWebinars();
        },

        isPast(webinar) {
            return webinar.date < moment().format('YYYY-MM-DD');
        },

        hasSubscription(webinar) {
            return _.find(this.subscriptions, {id: webinar.id}) ? true : false;
        },

        toggleSubscription(webinar) {
            axios.put(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}`).then(response => {
                this.subscriptions = response.data.data;
            });
        },

        addWebinarToUrl(url, webinarId) {
            url = new URL(url);

            if (webinarId) {
                url.searchParams.set('wid', webinarId);
            }

            return url;
        },

        copyToClipboard(value, container) {
            this.$copyText(value, container).then(() => {
                EventBus.$emit('success', this.$t('Clipboard.success'));
            }, () => {
                EventBus.$emit('error', this.$t('Clipboard.failure'));
            });
        },

        /**
         * Get QR code and show in modal
         *
         * Will get the base64 encoded QR code for display
         * as well as the filename (slug + headline + lang).
         */
        showQRCode(profile, webinarId, headline) {
            let backlink = this.addWebinarToUrl(profile.backlinks.webinars[this.localeCode], webinarId).toString();
            let shortlink = this.addWebinarToUrl(profile.shortlinks.webinars[this.localeCode], webinarId).toString();
            axios.get('/webapi/qr-code', {
                params: {
                    url: backlink,
                    filename: [
                        headline,
                        webinarId,
                        this.langForLinks.substring(0, 2),
                    ].join('-'),
                }
            }).then(response => {
                // set the params used in the dialog
                this.qr.backlink = backlink;
                this.qr.shortlink = shortlink;
                this.qr.headline = headline;
                this.qr.image = response.data.image;
                this.qr.filename = response.data.filename;
                this.qr.show = true;
            });
        },

        showRegistrations(webinar) {
            axios.get(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}/registrations`).then(response => {
                this.registrations.webinar = webinar;
                this.registrations.data = response.data.data;
                this.registrations.show = true;
            });
        },

        downloadRegistrations(webinar) {
            window.open(`/webapi/profiles/${this.profile.id}/webinars/${webinar.id}/registrations?format=xlsx`, '_blank');
        },

        /**
         * Download the QR code currently displayed in the dialog.
         */
        downloadQRCode() {
            const params = new URLSearchParams({
                url: this.qr.backlink,
                filename: this.qr.filename,
            });

            window.location.href = '/webapi/qr-code/download?' + params.toString();
        },

        localizeTime,
        localizeDate,
    },
};
</script>

<style scoped lang="scss">
.not-subscribed {
    .row:first-child {
        opacity: 0.8;
    }

    .calendar-background {
        background-color: #888 !important;
    }
}

.v-card__title {
    word-break: normal;
}

.v-divider {
    &.v-theme--light {
        opacity: 1;
    }
}
</style>
