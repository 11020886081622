<template>
    <div data-cy="patientDecree-tool">
        <h1 class="text-h6 mb-2">
            {{ $t('PatientDecreeTool.title') }}
        </h1>

        <v-sheet :class="{'elevation-1': $vuetify.display.lgAndUp}" class="pa-4">
            <tool-stepper
                :step="step"
                :steps="steps"
            />

            <component
                :is="stepComponent"
                v-model="patientDecree"
                @back="back"
                @next="next"
            />
        </v-sheet>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ToolStepper from '@/components/ToolStepper';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'PatientDecreeTool',
    components: {
        ToolStepper,
        PatientDecreeStep1: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep1.vue')),
        PatientDecreeStep2: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep2.vue')),
        PatientDecreeStep3: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep3.vue')),
        PatientDecreeStep4: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep4.vue')),
        PatientDecreeStep5: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep5.vue')),
        PatientDecreeStep6: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep6.vue')),
        PatientDecreeStep7: defineAsyncComponent(() => import('@/pages/patient-decree/PatientDecreeStep7.vue')),
    },
    data() {
        return {
            patientDecree: {},
        };
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        ...mapGetters('patientDecree', {
            step: 'step',
            storedPatientDecree: 'patientDecree',
        }),
        ...mapGetters('user', {
            getUser: 'user'
        }),
        stepComponent() {
            return `PatientDecreeStep${this.step}`;
        },
        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('PatientDecreeTool.patientData'),
                }, {
                    step: 2,
                    text: this.$t('PatientDecreeTool.authorizedRepresentative'),
                    optional: true,
                }, {
                    step: 3,
                    text: this.$t('PatientDecreeTool.replacementPerson'),
                    optional: true,
                }, {
                    step: 4,
                    text: this.$t('PatientDecreeTool.questions.title'),
                }, {
                    step: 5,
                    text: this.$t('PatientDecreeTool.attitudeToLife.title'),
                    optional: true,
                }, {
                    step: 6,
                    text: this.$t('PatientDecreeTool.medicalMeasures.title'),
                    optional: true,
                }, {
                    step: 7,
                    text: this.$t('PatientDecreeTool.generateDocuments'),
                }
            ];
        },
        hasAuthorizedRepresentativePerson() {
            return _.get(this.patientDecree, 'authorized_representative.active', false);
        },

        hasReplacementPerson() {
            return _.get(this.patientDecree, 'replacement_person.active', false);
        },
    },
    watch: {
        storedPatientDecree: {
            deep: true,
            handler(value) {
                this.patientDecree = _.cloneDeep(value);
            }
        }
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);
        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }

        this.load();

        if (this.step === 8) {
            this.reset();
        }
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),
        ...mapActions('patientDecree', {
            setPatientDecree: 'set',
            setStep: 'setStep',
            setCompleted: 'setCompleted',
            reset: 'reset',
        }),
        back() {
            if (this.step === 4 && this.hasAuthorizedRepresentativePerson === false) {
                this.setStep(2);
            } else {
                this.setStep(this.step - 1);
            }
        },
        next() {
            this.setPatientDecree(this.patientDecree);

            if (this.step === 2 && this.hasAuthorizedRepresentativePerson === false) {
                this.setStep(4);
            } else if (this.step === 7) {
                this.setCompleted();
                this.$router.push({name: 'patient-decree-completed'});
            } else {
                this.setStep(this.step + 1);
            }

            window.scrollTo(0, 0);
        },
        load() {
            if (this.$route.params.patient_decree_id) {
                axios.get(`/webapi/patient-decree/${this.$route.params.patient_decree_id}`).then(response => {
                    this.reset();
                    this.setStep(1);
                    this.setPatientDecree(_.get(response, 'data.data'));
                }).catch(() => {
                    this.$router.replace({name: 'my-documents'});
                });
            } else {
                if (_.isObject(this.storedPatientDecree) && !_.isEmpty(this.storedPatientDecree)) {
                    this.patientDecree = _.cloneDeep(this.storedPatientDecree);
                } else {
                    let person = _.cloneDeep(this.getUser);
                    if (!_.isUndefined(person)) {
                        person.phone = _.get(person, 'mobile');
                        this.patientDecree = {'patient_data': person};
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    .v-item--active {
        background-color: var(--da-accent) !important;

        :deep(.v-btn__content) {
            color: white !important;
            caret-color: white !important;
        }
    }
}
</style>
