const events = {};

export const EventBus = {
    $on(eventName, fn) {
        eventName = 'on.' + eventName; // events bound with .on() can be bound multiple times
        events[eventName] = events[eventName] || [];
        events[eventName].push(fn);
    },

    $once(eventName, fn) {
        eventName = 'once.' + eventName; // events bound with .once() can be bound only once (and don't interfere with .on() events)
        events[eventName] = null;
        events[eventName] = fn;
    },

    $off(eventName, fn) {
        if (events['on.' + eventName]) {
            if (!fn) {
                delete events['on.' + eventName];
            } else {
                for (let i = 0; i < events['on.' + eventName].length; i++) {
                    if (events['on.' + eventName][i] === fn) {
                        events['on.' + eventName].splice(i, 1);
                        break;
                    }
                }
            }
        }

        if (events['once.' + eventName]) {
            if (!fn || events['once.' + eventName] === fn) {
                delete events['once.' + eventName];
            }
        }
    },

    $emit(eventName, data) {
        if (events['once.' + eventName]) {
            events['once.' + eventName](data);
        }
        if (events['on.' + eventName]) {
            events['on.' + eventName].forEach(function(fn) {
                fn(data);
            });
        }
    }
};

export default EventBus;