<template>
    <div>
        <h5 class="text-h5 mb-4">{{ $t('OneWord.company') }}</h5>

        <vee-field
            v-slot="{ componentField, errors }"
            v-model="profile.company_name"
            :label="$t('OneWord.company')"
            rules="required|max:255"
            name="company_name"
        >
            <v-text-field
                v-bind="componentField"
                :counter="255"
                :error-messages="errors"
                :label="$t('OneWord.company')"
            />
        </vee-field>

        <v-alert
            v-if="!isTranslateable"
            class="mt-8"
            density="compact"
            text
            type="info"
        >
            {{ $t('ProfileForm.translationInfo') }}
        </v-alert>

        <v-row>
            <v-col cols="12" md="8">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.street"
                    :label="$t('OneWord.address')"
                    rules="required|max:255"
                    name="street"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.address')"
                        autocomplete="street-address"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="4">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.post_office_box"
                    :label="$t('OneWord.poBox')"
                    rules="max:255"
                    name="post_office_box"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.poBox')"
                        autocomplete="street-address"
                    />
                </vee-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.zip"
                    :label="$t('OneWord.zip')"
                    rules="required|max:5"
                    name="zip"
                >
                    <v-text-field
                        v-bind="componentField"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.zip')"
                        autocomplete="postal-code"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="8">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.place"
                    :label="$t('OneWord.place')"
                    rules="required|max:255"
                    name="place"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :disabled="!isTranslateable"
                        :error-messages="errors"
                        :label="$t('OneWord.place')"
                        autocomplete="address-level2"
                    />
                </vee-field>
            </v-col>
        </v-row>

        <vee-field
            v-slot="{ componentField, errors }"
            v-model="profile.website"
            :label="$t('OneWord.website')"
            rules="max:255|url"
            name="website"
        >
            <v-text-field
                v-bind="componentField"
                :counter="255"
                :error-messages="errors"
                :hint="$t('OneWord.urlHint')"
                :label="$t('OneWord.website')"
                persistent-hint
                prepend-inner-icon="mdi-web"
            />
        </vee-field>

        <h5 class="text-h5 mb-4 mt-6">{{ $t('AccountForm.subheaderContact') }}</h5>

        <vee-field
            v-slot="{ componentField, errors }"
            v-model="profile.salutation_id"
            :label="$t('OneWord.salutation.label')"
            rules="required"
            name="salutation_id"
        >
            <v-select
                v-bind="componentField"
                :error-messages="errors"
                :items="salutations"
                :label="$t('OneWord.salutation.label')"
                item-title="title"
                item-value="id"
            />
        </vee-field>
        <v-row>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.first_name"
                    :label="$t('OneWord.first-name')"
                    rules="required|max:255"
                    name="first_name"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :error-messages="errors"
                        :label="$t('OneWord.first-name')"
                        autocomplete="given-name"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.last_name"
                    :label="$t('OneWord.last-name')"
                    rules="required|max:255"
                    name="last_name"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :error-messages="errors"
                        :label="$t('OneWord.last-name')"
                        autocomplete="family-name"
                    />
                </vee-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.email"
                    :label="$t('OneWord.email')"
                    rules="required|email|max:255"
                    name="email"
                >
                    <v-text-field
                        v-bind="componentField"
                        :counter="255"
                        :error-messages="errors"
                        :hint="$t('AccountForm.EmailHint')"
                        :label="$t('OneWord.email')"
                        autocomplete="email"
                        persistent-hint
                        prepend-inner-icon="mdi-at"
                    />
                </vee-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.phone"
                    :label="$t('OneWord.phone')"
                    rules="max:255|phone"
                    name="phone"
                >
                    <v-text-field
                        v-bind="componentField"
                        :error-messages="errors"
                        :hint="$t('OneWord.phoneHint')"
                        :label="$t('OneWord.phone')"
                        :placeholder="phonePlaceholder"
                        type="tel"
                        persistent-hint
                        prepend-inner-icon="mdi-phone"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="profile.mobile_phone"
                    :label="$t('OneWord.mobile')"
                    rules="max:255|phone"
                    name="mobile_phone"
                >
                    <v-text-field
                        v-bind="componentField"
                        :error-messages="errors"
                        :hint="$t('OneWord.phoneHint')"
                        :label="$t('OneWord.mobile')"
                        :placeholder="phonePlaceholder"
                        type="tel"
                        persistent-hint
                        prepend-inner-icon="mdi-cellphone"
                    />
                </vee-field>
            </v-col>
        </v-row>

        <v-checkbox
            v-model="profile.has_permanent_operation"
            :label="$t('ProfileComtactForm.hasPermanentOperation')"
        />
    </div>
</template>

<script>
import {Field as VeeField} from "vee-validate";
import {mapGetters} from "vuex";

export default {
    name: 'ProfileForm',
    components: {VeeField},
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },
        isNgo: Boolean,
        lang: String,
    },
    data() {
        return {
            profile: {},
            salutations: [
                {id: 1, title: this.$t('OneWord.salutation.f')},
                {id: 2, title: this.$t('OneWord.salutation.m')},
            ],
        };
    },
    computed: {
        ...mapGetters('env', ['isSwitzerland']),
        isTranslateable() {
            if (this.isNgo) {
                return true;
            }

            return this.lang === 'de_CH';
        },
        phonePlaceholder() {
            if (this.isSwitzerland) {
                return '+41';
            }

            return '+49';
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler(value) {
                this.profile = value;
            },
        },
        profile: {
            deep: true,
            handler(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    mounted() {
        this.profile = this.modelValue;
    },
};
</script>

<style scoped>

</style>
