<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" lg="6">
                <vee-form ref="register" @submit="save()">
                    <v-card>
                        <div class="text-center">
                            <img
                                v-if="getLogo"
                                :src="getLogo"
                                :style="{'height': $vuetify.display.mdAndDown ? '3rem': '4rem'}"
                                class="d-inline-block my-12"
                            >
                            <v-icon v-else :size="$vuetify.display.mdAndDown ? '8rem' : '10rem'">
                                custom:DeinAdieuLogo
                            </v-icon>
                        </div>
                        <v-card-title class="text-h5">
                            {{ $t('SendPassword.title') }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-alert data-cy="errors" type="error" :model-value="error.length > 0" density="compact">{{ error }}</v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <vee-field
                                        v-slot="{ componentField, errors }"
                                        v-model="user.email"
                                        :label="$t('OneWord.email')"
                                        rules="required|email|max:255"
                                        name="email"
                                    >
                                        <v-text-field
                                            v-bind="componentField"
                                            :error-messages="errors"
                                            :label="$t('OneWord.email')"
                                            autocomplete="email"
                                            data-cy="input-email"
                                            readonly
                                        />
                                    </vee-field>
                                </v-col>
                                <v-col cols="12">
                                    <vee-field
                                        v-slot="{ componentField, errors }"
                                        v-model="user.password"
                                        :label="$t('OneWord.password')"
                                        rules="required|min:8|max:255"
                                        name="password"
                                    >
                                        <v-text-field
                                            v-bind="componentField"
                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                            :error-messages="errors"
                                            :hint="$t('User.password.hint')"
                                            :label="$t('OneWord.password')"
                                            :type="show ? 'text' : 'password'"
                                            data-cy="input-password"
                                            autocomplete="off"
                                            counter
                                            loading
                                            @click:append="show = !show"
                                        >
                                            <template #loader>
                                                <v-progress-linear
                                                    :model-value="pwdStrengthProgress"
                                                    :color="pwdStrengthColor"
                                                    height="7"
                                                />
                                            </template>
                                        </v-text-field>
                                    </vee-field>
                                </v-col>
                                <v-col cols="12">
                                    <vee-field
                                        v-slot="{ componentField, errors }"
                                        v-model="user.password_confirmation"
                                        :label="$t('OneWord.passwordConfirmation')"
                                        rules="required|min:8|max:255"
                                        name="password_confirmation"
                                    >
                                        <v-text-field
                                            v-bind="componentField"
                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                            :error-messages="errors"
                                            :hint="$t('User.password.hint')"
                                            :label="$t('OneWord.passwordConfirmation')"
                                            :type="show ? 'text' : 'password'"
                                            data-cy="input-confirmation"
                                            autocomplete="off"
                                            counter
                                            @click:append="show = !show"
                                        />
                                    </vee-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn variant="text" @click="$router.push({name:'login'})">
                                {{ $t('OneWord.cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn variant="flat" color="primary" type="submit" :loading="loading">
                                {{ $t('OneWord.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </vee-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '@/utils/event-bus';
import zxcvbn from 'zxcvbn';
import {mapGetters} from "vuex";

export default {
    name: 'SetPassword',
    components: {VeeForm, VeeField},
    data() {
        return {
            user: {},
            loading: false,
            error: '',
            show: false,
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLogo',
        ]),
        pwdStrengthProgress() {
            if (this.user.password) {
                let res = zxcvbn(this.user.password);
                return (res.score + 1) * 20;
            }

            return 0;
        },
        pwdStrengthColor() {
            if (this.pwdStrengthProgress > 80) {
                return 'success';
            }
            if (this.pwdStrengthProgress > 40 && this.pwdStrengthProgress <= 80) {
                return 'warning';
            }
            if (this.pwdStrengthProgress < 40) {
                return 'error';
            }

            return 'transparent';
        },
    },
    mounted() {
        this.user.email = this.$route.query.email;
        this.user.token = this.$route.query.token;
    },
    methods: {
        save() {
            this.loading = true;

            axios.post('/webapi/password/reset', this.user).then(() => {
                EventBus.$emit('success', this.$t('SetPassword.success'));

                this.$router.push({name: 'login'});
            }).catch(error => {
                this.$refs.register.setErrors(_.get(error, 'response.data.errors', []));
                this.error = _.get(error, 'response.data.message');
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
