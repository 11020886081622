<template>
    <account-persons :account="account" />
</template>

<script>
import {mapGetters} from 'vuex';
import AccountPersons from './Account/AccountPersons';
import store from '../../store';

export default {
    name: 'AdminAccount',
    components: {AccountPersons},
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            store.dispatch('account/load', store.getters['user/user'].account_id).finally(() => {
                next();
            });
        });
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('user/load').then(() => {
            store.dispatch('account/load', store.getters['user/user'].account_id).finally(() => {
                next();
            });
        });
    },
    computed: {
        ...mapGetters('account', {
            account: 'account',
        }),
    }
};
</script>

<style scoped>

</style>
