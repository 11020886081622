<template>
    <v-card border tile class="mt-2">
        <VuetifyTiptap
            v-model="editorContent"
            :extensions="extensions"
        />
    </v-card>
</template>

<script>
import {Bold, BulletList, Heading, History, Italic, Link, OrderedList, Strike, VuetifyTiptap, Underline} from 'vuetify-pro-tiptap';

export default {
    name: 'CustomContent',
    components: {
        VuetifyTiptap
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        primaryColor: {
            type: String,
            default: '#2F1F3C',
        },
    },
    data() {
        return {
            editorContent: '',
            extensions: [
                BulletList,
                Heading.configure({
                    levels: [1, 2, 3],
                }),
                OrderedList,
                Link.configure({
                    target: '_blank',
                }),
                Bold,
                Italic,
                Strike,
                Underline,
                History,
            ],
        };
    },
    watch: {
        modelValue(value) {
            this.editorContent = value;
        },
        editorContent(value) {
            if (value === '<p></p>' || value === null) {
                value = '';
            }
            this.$emit('update:modelValue', value);
        },
        primaryColor(value) {
            document.documentElement.style.setProperty('--da-wysiwyg-primary', value);
        }
    },
    mounted() {
        this.editorContent = this.modelValue;
    },
};
</script>
