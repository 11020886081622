import store from '@/store';

export const Features = {
    Testament: 'Testament',
    PatientDecree: 'PatientDecree',
    AdvanceCareDirective: 'AdvanceCareDirective',
    Consulting: 'Consulting',
};

export default {
    install(app) {
        app.config.globalProperties.$Features = Features;

        app.config.globalProperties.$hasFeature = (feature) => {
            return _.get(store.getters['env/getFeatures'], feature, false);
        };

        app.config.globalProperties.$isCountry = (country) => {
            return store.getters['env/getCountry'].toLowerCase() === country.toLowerCase();
        };
        
        window.$hasFeature = app.config.globalProperties.$hasFeature;
        window.$isCountry = app.config.globalProperties.$isCountry;
    },
}