<template>
    <v-card>
        <v-card-text>
            <div class="text-center">
                <v-icon color="success" size="12rem">mdi-check-circle-outline</v-icon>
            </div>

            <div class="text-h6 my-12 text-center">
                {{ $t('ProfileCreated.successMessage1') }}
            </div>
            <div class="text-subtitle-1 my-6 text-center">
                {{ $t('ProfileCreated.successMessage2') }}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'ProfileCreated',
};
</script>

<style scoped>

</style>
