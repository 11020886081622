<template>
    <div class="mb-6 text-xs">
        <v-progress-linear
            :height="2"
            :model-value="progress"
        />
        <div
            class="flex cursor-pointer flex-row px-2 pb-4 pt-2"
            @click="toggle"
        >
            <div class="w-2/3 grow font-bold">
                <div class="float-left mr-2 size-4 rounded-full bg-da-primary text-center text-white">
                    {{ step }}
                </div>
                <div class="truncate" :data-cy="`stepper-step-${step}`">{{ currentStepText }}</div>
            </div>
            <div class="pl-2 text-gray-600">
                <div class="flex flex-row">
                    <div class="whitespace-nowrap">
                        {{ $t('TestamentTool.stepperInfoText', {step: step, total: steps.length}) }}
                    </div>
                    <div
                        class="mdi mdi-18px"
                        :class="{'mdi-chevron-up': open, 'mdi-chevron-down': !open}"
                    />
                </div>
            </div>
        </div>
        <div v-if="open" class="px-2 text-gray-300">
            <div
                v-for="step in listItems"
                :key="`step-${step.step}`"
                class="mb-4"
                :class="{'text-gray-600': isCompleted(step), 'text-gray-900': isCurrent(step)}"
            >
                <div
                    class="float-left mr-2 size-4 rounded-full bg-gray-300 text-center text-white"
                    :class="{'bg-gray-500': isCompleted(step), 'bg-gray-900': isCurrent(step)}"
                >
                    {{ step.step }}
                </div>
                {{ step.text }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ToolStepper',
    props: {
        steps: {
            type: Array,
            default: () => {
                return [];
            },
        },
        step: Number,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        progress() {
            let size = this.steps.length || 1;
            return _.round((100 / size) * this.step);
        },
        listItems() {
            if (this.step === 1) {
                return _.slice(this.steps, 1);
            }

            return this.steps;
        },
        currentStepText() {
            return _.get(
                this.steps,
                `[${_.findIndex(this.steps, {step: this.step})}].text`,
                '',
            );
        },
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        isCompleted(step) {
            return this.step > step.step;
        },
        isCurrent(step) {
            return this.step === step.step;
        },
    },
};
</script>

<style scoped>

</style>