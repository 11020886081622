import { defineRule, configure } from 'vee-validate';
import { all, required } from '@vee-validate/rules';
import PhoneNumber from 'awesome-phonenumber';
import store from "@/store";
import {usei18n} from './i18n';
import {EventBus} from '@/utils/event-bus';
import { localize } from '@vee-validate/i18n';
import { get } from 'lodash';

const translations = {};

Object.entries(all).forEach(([name, rule]) => {
    defineRule(name, rule);
});

EventBus.$on('locale-changed', locale => {
    loadLocale(locale.substr(0, 2));
});

function loadLocale(locale) {
    return import(`@vee-validate/i18n/dist/locale/${locale}.json`).then(dictionary => {
        translations[locale] = dictionary;
        configure({
            generateMessage: localize(locale, dictionary)
        });
    });
}

loadLocale('de');

defineRule('phone', (value, _, ctx) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }

    const phone = new PhoneNumber(value, store.getters['env/getCountry']);
    if (!phone.isValid()) {
        return usei18n().t('VeeValidate.phone', {
            _field_: ctx.label,
        });
    }

    return true;
});

defineRule('url', (value, _, ctx) => {
    if (!value || !value.length) {
        return true;
    }

    var pattern = new RegExp('^((http|https):\\/\\/)' + // protocol
        '((([a-zäöü\\d]([a-zäöü\\d-]*[a-zäöü\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    if (!pattern.test(value)) {
        return usei18n().t('VeeValidate.url', {
            _field_: ctx.label,
        });
    }

    return true;
});

defineRule('birthday', (value, _, ctx) => {
    if (!value || !value.length) {
        return true;
    }

    if (!moment(value).isSameOrBefore(moment.now())) {
        return usei18n().t('VeeValidate.birthday', {
            _field_: ctx.label,
        });
    }

    return true;
});

defineRule('password', (value, [target]) => {
    if (!value || !value.length) {
        return true;
    }

    if (value !== ctx.form[target]) {
        return usei18n().t('User.passwordNotConfirmed')
    }

    return true;
});

// custom required rule does NOT consider false empty
defineRule('required', (value, _, ctx) => {
    if (
        value === '' || 
        value === null || 
        typeof value === 'undefined' ||
        (Array.isArray(value) && !value.length)
    ) {
        let errorMessage = get(translations, usei18n().locale + '.messages.required') || 'The {field} field is required'; // fallback (just in case no locale is loaded)
        return errorMessage.replace('{field}', ctx.label);
    }

    return true;
});

defineRule('required_if', (value, [target, targetValue], ctx) => {
    // only check with loose equality! because otherwise: 1 !== '1'
    if (ctx.form[target] == targetValue) {
        return required(value);
    }

    return true;
});

defineRule('boolean', (value, _, ctx) => {
    if (typeof value === "boolean") {
        return true;
    }

    return `Field ${ctx.label} not of type boolean`
});