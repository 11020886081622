const $t = (t) => t;

/**
 * Locize key mapping for dynamic locize keys
 * to keep the component clean and readable.
 */
export default {
    'ngo': {
        'listing': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'view': $t('AdminStatistic.segment.view'),
            },
            'title': {
                'preview': $t('AdminStatistic.statistic.listing.previews'),
                'view': $t('AdminStatistic.statistic.listing.views'),
            },
            'info': {
                'preview': $t('AdminStatistic.statistic.listing.previewsInfoText'),
                'view': $t('AdminStatistic.statistic.listing.viewsInfoText'),
            },
        },
        'testament': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
                'consideration': $t('AdminStatistic.segment.consideration'),
            },
            'title': {
                'preview': $t('AdminStatistic.statistic.testamenttool.previews'),
                'clicks': $t('AdminStatistic.statistic.testamenttool.backlink'),
                'conversion': $t('AdminStatistic.statistic.testamenttool.conversion'),
                'consideration': $t('AdminStatistic.statistic.testamenttool.pdf'),
            },
            'info': {
                'preview': $t('AdminStatistic.statistic.testamenttool.previewsInfoText'),
                'clicks': $t('AdminStatistic.statistic.testamenttool.backlinkInfoText'),
                'conversion': $t('AdminStatistic.statistic.testamenttool.conversionInfoText'),
                'consideration': $t('AdminStatistic.statistic.testamenttool.pdfInfoText'),
            },
        },
        'patient-decree': {
            'label': {
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'clicks': $t('AdminStatistic.statistic.patientDecree.backlink'),
                'conversion': $t('AdminStatistic.statistic.patientDecree.conversion'),
            },
            'info': {
                'clicks': $t('AdminStatistic.statistic.patientDecree.backlinkInfoText'),
                'conversion': $t('AdminStatistic.statistic.patientDecree.conversionInfoText'),
            },
        },
        'advance-care-directive': {
            'label': {
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'clicks': $t('AdminStatistic.statistic.advancedCareDirective.backlink'),
                'conversion': $t('AdminStatistic.statistic.advancedCareDirective.conversion'),
            },
            'info': {
                'clicks': $t('AdminStatistic.statistic.advancedCareDirective.backlinkInfoText'),
                'conversion': $t('AdminStatistic.statistic.advancedCareDirective.conversionInfoText'),
            },
        },
        'consulting-contact': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'preview': $t('AdminStatistic.statistic.consulting.previews'),
                'clicks': $t('AdminStatistic.statistic.consulting.backlink'),
                'conversion': $t('AdminStatistic.statistic.consulting.conversion'),
            },
            'info': {
                'preview': $t('AdminStatistic.statistic.consulting.previewsInfoText'),
                'clicks': $t('AdminStatistic.statistic.consulting.backlinkInfoText'),
                'conversion': $t('AdminStatistic.statistic.consulting.conversionInfoText'),
            },
        },
    },
    'service-provider': {
        'listing': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'view': $t('AdminStatistic.segment.view'),
            },
            'title': {
                'preview': $t('AdminStatistic.service-provider.listing.previews'),
                'view': $t('AdminStatistic.service-provider.listing.views'),
            },
            'info': {
                'preview': $t('AdminStatistic.service-provider.listing.previewsInfoText'),
                'view': $t('AdminStatistic.service-provider.listing.viewsInfoText'),
            },
        },
        'testament': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
                'consideration': $t('AdminStatistic.segment.consideration'),
            },
            'title': {
                'preview': $t('AdminStatistic.service-provider.testamenttool.previews'),
                'clicks': $t('AdminStatistic.service-provider.testamenttool.backlink'),
                'conversion': $t('AdminStatistic.service-provider.testamenttool.conversion'),
                'consideration': $t('AdminStatistic.service-provider.testamenttool.pdf'),
            },
            'info': {
                'preview': $t('AdminStatistic.service-provider.testamenttool.previewsInfoText'),
                'clicks': $t('AdminStatistic.service-provider.testamenttool.backlinkInfoText'),
                'conversion': $t('AdminStatistic.service-provider.testamenttool.conversionInfoText'),
                'consideration': $t('AdminStatistic.service-provider.testamenttool.pdfInfoText'),
            },
        },
        'patient-decree': {
            'label': {
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'clicks': $t('AdminStatistic.service-provider.patientDecree.backlink'),
                'conversion': $t('AdminStatistic.service-provider.patientDecree.conversion'),
            },
            'info': {
                'clicks': $t('AdminStatistic.service-provider.patientDecree.backlinkInfoText'),
                'conversion': $t('AdminStatistic.service-provider.patientDecree.conversionInfoText'),
            },
        },
        'advance-care-directive': {
            'label': {
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'clicks': $t('AdminStatistic.service-provider.advancedCareDirective.backlink'),
                'conversion': $t('AdminStatistic.service-provider.advancedCareDirective.conversion'),
            },
            'info': {
                'clicks': $t('AdminStatistic.service-provider.advancedCareDirective.backlinkInfoText'),
                'conversion': $t('AdminStatistic.service-provider.advancedCareDirective.conversionInfoText'),
            },
        },
        'consulting-contact': {
            'label': {
                'preview': $t('AdminStatistic.segment.preview'),
                'clicks': $t('AdminStatistic.segment.backlink'),
                'conversion': $t('AdminStatistic.segment.conversion'),
            },
            'title': {
                'preview': $t('AdminStatistic.service-provider.consulting.previews'),
                'clicks': $t('AdminStatistic.service-provider.consulting.backlink'),
                'conversion': $t('AdminStatistic.service-provider.consulting.conversion'),
            },
            'info': {
                'preview': $t('AdminStatistic.service-provider.consulting.previewsInfoText'),
                'clicks': $t('AdminStatistic.service-provider.consulting.backlinkInfoText'),
                'conversion': $t('AdminStatistic.service-provider.consulting.conversionInfoText'),
            },
        },
    },
};
