import { h } from "vue";
import DeinAdieuLogo from "@/components/Icons/DeinAdieuLogo.vue";
import NounChild from "@/components/Icons/Noun/Child.vue";
import NounGrandParents from "@/components/Icons/Noun/GrandParents.vue";
import NounParents from "@/components/Icons/Noun/Parents.vue";
import NounRings from "@/components/Icons/Noun/Rings.vue";

const customSvgNameToComponent = {
    DeinAdieuLogo,
    NounChild,
    NounGrandParents,
    NounParents,
    NounRings,
};

export default {
    component: (props) => {
        return h(props.tag, [h(customSvgNameToComponent[props.icon], { class: 'v-icon__svg' })])
    }
}