<template>
    <div>
        <h1 class="text-h6 mb-2">
            {{ $t('AdminProfile.title') }}
        </h1>
        <v-form @submit.prevent="save">
            <v-card :loading="loading">
                <v-card-title>
                    {{ $t('PartnerTypeForm.title') }}
                </v-card-title>
                <v-card-text>
                    <v-alert type="info" density="compact" text>
                        {{ $t('AdminProfileCreate.partnerTypeInfoText') }}
                    </v-alert>

                    <v-card border class="mb-2">
                        <v-card-title class="text-subtitle-2">
                            {{ $tc('AdminProfileCreate.ngoPartnerTypeInfoText', ngoPartnerTypes.length) }}
                        </v-card-title>
                        <v-list v-model:selected="selectedPartnerTypes" class="p-0">
                            <template v-for="(pt, index) in ngoPartnerTypes" :key="`pt-${pt.id}`">
                                <v-list-item density="compact" :value="pt">
                                    <template #default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :model-value="active" />
                                        </v-list-item-action>

                                        
                                        <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                        </v-list-item-subtitle>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < ngoPartnerTypes.length - 1"
                                    :key="index"
                                />
                            </template>
                        </v-list>
                    </v-card>

                    <v-card border>
                        <v-card-title class="text-subtitle-2">
                            {{ $tc('AdminProfileCreate.spPartnerTypeInfoText', spPartnerTypes.length) }}
                        </v-card-title>
                        <v-list v-model:selected="selectedPartnerTypes" class="p-0">
                            <template v-for="(pt, index) in spPartnerTypes" :key="`pt-${pt.id}`">
                                <v-list-item density="compact" :value="pt">
                                    <template #default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :model-value="active" />
                                        </v-list-item-action>

                                        
                                        <v-list-item-title>{{ pt.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t(`PartnerTypeForm.${pt.id}.info`) }}
                                        </v-list-item-subtitle>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < spPartnerTypes.length - 1"
                                    :key="index"
                                />
                            </template>
                        </v-list>
                    </v-card>

                    <v-alert
                        v-if="partnerTypeRequiredError"
                        type="error"
                        density="compact"
                        text
                        class="mt-2"
                    >
                        Bitte wählen Sie mindestens einen Partnertyp.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn type="submit" variant="flat" color="primary">{{ $t('OneWord.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import {EventBus} from '../../utils/event-bus';
import store from '../../store';
import {mapGetters} from 'vuex';

export default {
    name: 'AdminProfileCreate',
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
    props: {
        lang: String,
    },
    data() {
        return {
            partnerTypes: [],
            selectedPartnerTypes: [],

            loading: false,
            partnerTypeRequiredError: false,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        ngoPartnerTypes() {
            return _.filter(this.partnerTypes, {id: 12});
        },
        spPartnerTypes() {
            return _.filter(this.partnerTypes, item => {
                return item.id !== 12;
            });
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        save() {
            axios.post(`/webapi/accounts/${this.user.account_id}/profiles`, {
                partner_types: this.selectedPartnerTypes,
            }).then(response => {
                this.$router.push({
                    name: 'admin-edit-profile-description',
                    params: {profile_id: response.data.data.id},
                    query: {lang: this.lang},
                });
            }).catch(error => {
                if (_.has(error, 'data.errors.partner_types')) {
                    this.partnerTypeRequiredError = true;
                }
                EventBus.$emit('error', error.data.message);
            });
        },
        load() {
            this.loading = true;
            axios.get('/api/partner-types').then(response => {
                this.partnerTypes = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
}
;
</script>

<style scoped>

</style>
