<template>
    <vee-form @submit="save()">
        <v-card :loading="saving">
            <v-card-text>
                <h2 class="text-h6 mb-2">
                    {{ $t('PreviewProfileForm.title') }}
                </h2>

                <div v-if="false" class="text-center">
                    <v-btn size="x-large" color="primary" @click="openPreview">
                        {{ $t('PreviewProfileForm.btn.openProfilePreview') }}
                    </v-btn>
                </div>

                <div class="mt-3" v-html="$t('PreviewProfileForm.infoText')" />

                <vee-field 
                    v-slot="{ componentField, errors }"
                    v-model="tac"
                    rules="required"
                    :label="$t('OneWord.termsOfUse')"
                    name="tac"
                >
                    <v-checkbox
                        v-bind="componentField"
                        :error-messages="errors"
                        class="mt-3"
                    >
                        <template #label>
                            <span v-html="$t('PreviewProfileForm.tac')" />
                        </template>
                    </v-checkbox>
                </vee-field>

                <v-alert type="error" closable text :model-value="error.message !== null">
                    <p>{{ error.message }}</p>
                    <ul>
                        <li v-for="(item, index) in error.errors" :key="`error-${index}`">{{ item }}</li>
                    </ul>
                    <p class="mt-3">
                        {{ $t('PreviewProfileForm.errors.submitError') }}
                    </p>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-btn variant="text" @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn color="accent" type="submit" :loading="saving">
                    {{ $t('PreviewProfileForm.btn.create') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '../../utils/event-bus';

export default {
    name: 'PreviewProfileForm',
    components: {VeeForm, VeeField},
    props: {
        error: {
            type: Object,
            default() {
                return {
                    message: null,
                    errors: [],
                };
            },
        },
    },
    data() {
        return {
            tac: null,
            saving: false,
        };
    },
    methods: {
        save() {
            this.saving = true;

            axios.post('/webapi/create-profile/submit', {
                tac: this.tac,
            }).then(() => {
                this.$router.push({name: 'create-profile-created'});
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            }).finally(() => {
                this.saving = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
