<template>
    <div>
        <v-card :disabled="loading">
            <v-card-title class="text-subtitle-2">
                {{ $t('UserNotifications.title') }}
            </v-card-title>
            <v-card-text>
                <v-alert type="info" density="compact" text>
                    <span v-html="$t('UserNotifications.infoText')" />
                </v-alert>
            </v-card-text>

            <v-card-text class="lg:flex">
                <v-card-subtitle class="font-regular p-0 text-black lg:w-1/3 lg:p-4">{{ $t('AccountPersons.subtitle.emailNotifications') }}</v-card-subtitle>
                <div class="pr-3 pt-0 lg:w-2/3">
                    <v-switch
                        v-model="contact.has_campaigns_statistic"
                        color="primary"
                        class="pr-3 pt-0"
                        hide-details
                        data-cy="switch-has-campaigns-statistic"
                        :disabled="!contact.has_account_login"
                        :label="$t('AccountPersons.hasCampaignStatistic')"
                        size="small"
                        @click="updateItem(contact, 'has_campaigns_statistic')"
                    />

                    <v-switch
                        v-if="account.is_ngo"
                        v-model="contact.has_email_testator"
                        color="primary"
                        class="mt-2 pr-3 pt-0"
                        hide-details
                        data-cy="switch-has-email-testator"
                        :disabled="!contact.has_account_login"
                        :label="$t('AccountPersons.hasEmailTestator')"
                        size="small"
                        @click="updateItem(contact, 'has_email_testator')"
                    />

                    <v-switch
                        v-if="account.is_ngo"
                        v-model="contact.has_warnings"
                        color="primary"
                        class="mt-2 pr-3 pt-0"
                        hide-details
                        data-cy="switch-has-warnings"
                        :disabled="!contact.has_account_login"
                        :label="$t('AccountPersons.hasWarnings')"
                        size="small"
                        @click="updateItem(contact, 'has_warnings')"
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
import {mapActions, mapGetters} from 'vuex';
import store from '../../store';

export default {
    name: 'UserNotifications',
    data: () => ({
        loading: false,
        contact: {},
    }),
    computed: {
        ...mapGetters('user', [
            'user'
        ]),
        ...mapGetters('account', [
            'account'
        ]),
    },
    mounted() {
        this.loadUser().then(() => {
            this.load();
        })
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        load() {
            store.dispatch('account/load', this.user.account_id);

            axios
                .get(`/webapi/user/contact`)
                .then((response) => {
                    this.contact = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateItem(item, type) {
            this.loading = true;
            let payload = { action: type };
            payload[type] = item[type]; // get toggle value
            axios
                .post(`/webapi/accounts/${this.account.id}/contacts/${item.id}/services`, payload)
                .then(() => {
                    EventBus.$emit("success", this.$t("OneWord.saveSuccess"));
                    this.load();
                })
                .catch((error) => {
                    // reset value to previous value
                    item[type] = !item[type];
                    
                    if (_.has(error, 'response.data.errors')) {
                        let errors = _.keys(_.get(error, 'response.data.errors'));
                        EventBus.$emit('error', _.get(error, `response.data.errors.${errors[0]}.[0]`));
                    } else {
                        EventBus.$emit('error', _.get(error, 'response.data.message'));
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>

</style>