<template>
    <div>
        <vee-field
            v-slot="{ componentField, errors }"
            v-model="company.company_name"
            :label="$t('OneWord.company')"
            rules="required|max:255"
            name="company_name"
        >
            <v-text-field
                v-bind="componentField"
                :label="$t('OneWord.company')"
                :counter="255"
                :error-messages="errors"
            />
        </vee-field>

        <v-row>
            <v-col cols="12" md="8">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.street"
                    :label="$t('OneWord.address')"
                    rules="required|max:255"
                    name="street"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.address')"
                        :error-messages="errors"
                        :counter="255"
                        autocomplete="street-address"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="4">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.post_office_box"
                    :label="$t('OneWord.poBox')"
                    rules="max:255"
                    name="post_office_box"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.poBox')"
                        :error-messages="errors"
                        :counter="255"
                        autocomplete="street-address"
                    />
                </vee-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="2">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.zip"
                    :label="$t('OneWord.zip')"
                    rules="required|max:30"
                    name="zip"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.zip')"
                        :error-messages="errors"
                        autocomplete="postal-code"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="4">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.place"
                    :label="$t('OneWord.place')"
                    rules="required|max:100"
                    name="place"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.place')"
                        :error-messages="errors"
                        autocomplete="address-level2"
                        :counter="100"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.country"
                    :label="$t('OneWord.country')"
                    rules=""
                    name="country"
                >
                    <v-select
                        disabled
                        v-bind="componentField"
                        :label="$t('OneWord.country')"
                        :error-messages="errors"
                        :counter="100"
                        :items="translateCountry"
                        data-cy="country"
                    />
                </vee-field>
            </v-col>
        </v-row>

        <vee-field
            v-slot="{ componentField, errors }"
            v-model="company.website"
            :label="$t('OneWord.website')"
            rules="required|url"
            name="website"
        >
            <v-text-field
                v-bind="componentField"
                :label="$t('OneWord.website')"
                :error-messages="errors"
                :counter="255"
                prepend-inner-icon="mdi-web"
                :hint="$t('OneWord.urlHint')"
            />
        </vee-field>

        <v-row>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.phone"
                    :label="$t('OneWord.callCenter')"
                    rules="required|max:255|phone"
                    name="phone"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.callCenter')"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-phone"
                        type="tel"
                    />
                </vee-field>
            </v-col>
            <v-col cols="12" md="6">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.mobile_phone"
                    :label="$t('OneWord.directPhone')"
                    rules="max:255|phone"
                    name="mobile_phone"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.directPhone')"
                        type="tel"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cellphone"
                    />
                </vee-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {Field as VeeField} from "vee-validate";

export default {
    name: 'AccountForm',
    components: {VeeField},
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            }
        },
        countries: Array,
    },
    data() {
        return {
            company: {},
            salutations: [
                {id: 1, title: this.$t('OneWord.salutation.f')},
                {id: 2, title: this.$t('OneWord.salutation.m')},
            ],
        };
    },
    computed: {
        translateCountry() {
            const namesTranslated = {
                'Schweiz': this.$t('OneWord.switzerland'),
                'Österreich': this.$t('OneWord.austria'),
                'Deutschland': this.$t('OneWord.germany'),
            };

            return this.countries.map(country => {
                return {
                    value: country,
                    title: _.get(namesTranslated, country, country)
                };
            });
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler(value) {
                this.company = value;
            },
        },
        company: {
            deep: true,
            handler(value) {
                this.$emit('update:company', value);
            },
        },
    },
    mounted() {
        this.company = this.modelValue;
    },
};
</script>

<style scoped>

</style>
