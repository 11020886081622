<template>
    <v-container
        class="fill-height text-center"
        style="height: calc(100vh - (400px));"
    >
        <v-row align="center">
            <v-col>
                <h1 class="text-h3 text-primary">
                    {{ $t('Error403.whoops') }}
                </h1>

                <p>
                    {{ $t('Error403.text') }}
                </p>

                <v-btn
                    :to="{name: 'my-documents'}"
                    color="primary"
                    variant="outlined"
                >
                    {{ $t('Error403.btn.label') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Error404',
};
</script>

<style scoped>

</style>
