import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import './plugins/axios';
import router from './router';
import filters from './plugins/filters';
import ability from './plugins/ability';
import moment from 'moment';
import numeral from 'numeral';
import VueClipboard from 'vue-clipboard2';
import './plugins/vee-validate';
import {v4 as uuidv4} from 'uuid';
import {makei18n, getCurrentLocale} from './plugins/i18n';
import VuetifyTipTap from './composables/TipTap';
import * as Sentry from "@sentry/vue";
import Features from "@/plugins/features";
import loadEnv from "@/plugins/env";
import {pirsch} from "@/utils/pirsch";

import './assets/app.scss';

window.uuidv4 = uuidv4;
window.moment = moment;
window.numeral = numeral;

const app = createApp(App);

loadEnv().then(({ abilities }) => {

    ability.install(app, abilities);

    app.use(vuetify);
    app.use(filters);
    app.use(router);
    app.use(store);
    app.use(Features);
    app.use(VuetifyTipTap);
    app.use(VueClipboard);
    app.use(makei18n());

    moment.locale(getCurrentLocale());

    app.mount('#app');

    if (!window.location.hostname.endsWith('.test')) {
        pirsch();
    }
});

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        dsn: "https://bdd650ce53b04d799b1947aaf8d5e29c@o763092.ingest.sentry.io/5793774",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 0.08,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}