const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,

    formData: {},
    selectedProfiles: [],
    response: null, // response after step 3 in Consulting tool
};

const state = _.cloneDeep(initialState);

const getters = {
    formData: state => state.formData,
    selectedProfiles: state => state.selectedProfiles,
    response: state => state.response,
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,
};

const actions = {
    setResponse: ({commit}, value) => commit('response', value),
    setState: ({commit}, value) => commit('set', value),
    setStep: ({commit}, value) => commit('step', value),
    setCompleted: ({commit}) => commit('setCompleted'),
    setNgoThanksSubmitted: ({commit}) => commit('setNgoThanksSubmitted'),
    setAgreementAccepted: ({commit}, value) => commit('setAgreementAccepted', value),
    reset: ({commit}) => commit('resetState'),
};

const mutations = {
    set(state, value) {
        Object.assign(state, value);
    },
    response(state, value) {
        state.response = value;
    },
    step(state, value) {
        state.step = parseInt(value);

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};