<template>
    <profile-images-form
        v-if="profile"
        :account-id="profile.account_id"
        :profile-id="profile.id"
        :profile="profile"
        lang="de_CH"
        show-actions
        @save="$router.push({name: 'create-profile-contact-form'})"
    />
</template>

<script>
import ProfileImagesForm from '../admin/form/ProfileImagesForm';

export default {
    name: 'CreateProfileImagesForm',
    components: {ProfileImagesForm},
    beforeRouteEnter(to, from, next) {
        axios.get('/webapi/create-profile/profile').then(response => {
            next(vm => {
                vm.profile = response.data.data;
            });
        });
    },
    data() {
        return {
            profile: null,
        };
    },
};
</script>

<style scoped lang="scss">
</style>
