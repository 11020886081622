<template>
    <div class="da-footer text-center">
        <v-container v-if="isDa" class="text-caption p-10" fluid>
            {{ $t('AppFooter.col1.address') }},
            <a
                href="https://www.deinadieu.ch/datenschutzerklaerung/"
                target="_blank"
                class="underline"
            >{{ $t('AppFooter.col2.privacy') }}</a>&nbsp;
            <a
                href="https://www.deinadieu.ch/nutzungsbedingungen/"
                target="_blank"
                class="underline"
            >
                {{ $t('AppFooter.col2.terms') }}
            </a>
        </v-container>

        <v-container v-else-if="isGermany" class="text-caption p-10" fluid>
            {{ $t('AppFooter.col1.address') }},
            <a
                href="https://www.deinadieu.de/impressum/"
                target="_blank"
                class="underline"
            >{{ $t('AppFooter.col2.imprint') }}</a>&nbsp;
            <a
                href="https://www.deinadieu.de/datenschutzerklaerung/"
                target="_blank"
                class="underline"
            >{{ $t('AppFooter.col2.privacyPolicy') }}</a>&nbsp;
            <a
                href="https://www.deinadieu.de/nutzungsbedingungen/"
                target="_blank"
                class="underline"
            >
                {{ $t('AppFooter.col2.terms') }}
            </a>
            <br>
            <span v-html="$t('AppFooter.cookieInfo', {url: 'https://www.deinadieu.de/datenschutzerklaerung/'})" />
        </v-container>

        <v-container v-else-if="isAustria" class="text-caption p-10" fluid>
            {{ $t('AppFooter.col1.address') }},
            <a
                href="https://www.deinadieu.at/impressum/"
                target="_blank"
                class="underline"
            >{{ $t('AppFooter.col2.imprint') }}</a>&nbsp;
            <a
                href="https://www.deinadieu.at/datenschutzerklaerung/"
                target="_blank"
                class="underline"
            >{{ $t('AppFooter.col2.privacyPolicy') }}</a>&nbsp;
            <a
                href="https://www.deinadieu.at/nutzungsbedingungen/"
                target="_blank"
                class="underline"
            >
                {{ $t('AppFooter.col2.terms') }}
            </a>
            <br>
            <span v-html="$t('AppFooter.cookieInfo', {url: 'https://www.deinadieu.at/datenschutzerklaerung/'})" />
        </v-container>

        <v-container v-else class="text-caption p-10" fluid>
            {{ $t('AppFooter.col1.address') }},
            <a
                href="https://www.deinadieu.ch/datenschutzerklaerung/"
                target="_blank"
                class="underline"
            >
                {{ $t('AppFooter.col2.privacy') }}
            </a>&nbsp;
            <a
                href="https://www.deinadieu.ch/nutzungsbedingungen/"
                target="_blank"
                class="underline"
            >
                {{ $t('AppFooter.col2.terms') }}
            </a>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'AppFooter',
    computed: {
        ...mapGetters('env', [
            'isDa',
            'isGermany',
            'isAustria',
        ]),
    },
};
</script>

<style scoped lang="scss">
.da-footer {
    background-color: rgb(250, 250, 250);
    width: 100%;
    bottom: 0;
}
</style>
