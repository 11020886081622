<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12" lg="6">
                <vee-form ref="sendpwd" @submit="save()">
                    <v-card class="text-center">
                        <img
                            v-if="getLogo"
                            :src="getLogo"
                            :style="{'height': $vuetify.display.mdAndDown ? '3rem': '4rem'}"
                            class="d-inline-block my-12"
                        >
                        <v-icon v-else :size="$vuetify.display.mdAndDown ? '8rem' : '10rem'">
                            custom:DeinAdieuLogo
                        </v-icon>
                        <v-card-title class="text-h5">
                            {{ $t('SendPassword.title') }}
                        </v-card-title>
                        <v-card-text>
                            <v-alert type="error" :model-value="error.length > 0" density="compact">{{ error }}</v-alert>
                            <vee-field
                                v-slot="{ componentField, errors }"
                                v-model="user.email"
                                :label="$t('OneWord.email')"
                                rules="required|email|max:255"
                                name="email"
                            >
                                <v-text-field
                                    v-bind="componentField"
                                    :error-messages="errors"
                                    :label="$t('OneWord.email')"
                                    autocomplete="email"
                                    data-cy="input-email"
                                />
                            </vee-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn variant="text" @click="$router.push({name:'login'})">
                                {{ $t('OneWord.cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn variant="flat" color="primary" type="submit" :loading="loading">
                                {{ $t('SendPassword.btn.send') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </vee-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '@/utils/event-bus';
import {mapGetters} from "vuex";

export default {
    name: 'SendPassword',
    components: {VeeForm, VeeField},
    data() {
        return {
            error: '',
            user: {},
            loading: false,
        };
    },
    computed: {
        ...mapGetters('env', [
            'getLogo',
        ]),
    },
    methods: {
        save() {
            this.loading = true;
            axios.post('/webapi/password/email', this.user).then(() => {
                EventBus.$emit('success', this.$t('SendPassword.success'));
                this.$router.push({name: 'login'});
            }).catch(error => {
                this.$refs.sendpwd.setErrors(error.data.errors);
                this.error = error.data.message;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
