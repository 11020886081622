import { VuetifyTiptap, createVuetifyProTipTap } from 'vuetify-pro-tiptap';
import { BaseKit } from 'vuetify-pro-tiptap';
import '@/assets/_wysiwyg.scss';

export default createVuetifyProTipTap({
    lang: 'en',
    components: {
        VuetifyTiptap
    },
    extensions: [BaseKit],
    markdownTheme: false,
});