<template>
    <vee-form ref="profileContactForm" @submit="save()">
        <v-card :loading="loading" :disabled="loading" class="mt-4">
            <v-card flat>
                <v-card-text>
                    <profile-form
                        v-model="profile"
                        :is-ngo="isNgo"
                        :lang="lang"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" :loading="loading">
                        <v-icon start>mdi-content-save</v-icon>
                        {{ $t('OneWord.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-card>
    </vee-form>
</template>

<script>
import store from '../../../store';
import ProfileForm from '../form/ProfileForm';
import {EventBus} from '@/utils/event-bus';
import {Form as VeeForm} from "vee-validate";
import {mapGetters} from 'vuex';

export default {
    name: 'ProfileContact',
    components: {ProfileForm, VeeForm},
    beforeRouteEnter(to, from, next) {
        store.dispatch('user/load').then(() => {
            next();
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.profileChanged) {
            this.$root.$confirm(
                this.$t('OneWord.confirmUnsavedChangesTitle'),
                this.$t('OneWord.confirmUnsavedChangesInfoText'),
            ).then(confirm => {
                confirm ? next() : next(false);
            });
        } else {
            next();
        }
    },
    props: {
        lang: String,
    },
    data() {
        return {
            loading: false,
            profile: {},
            profileChanged: false,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        profileId() {
            return _.parseInt(_(this.$route.params).get('profile_id'));
        },
        isNgo() {
            return _.map(_.get(this.profile, 'partner_types', []), 'id').includes(12);
        },
    },
    watch: {
        profile: {
            deep: true,
            handler() {
                this.profileChanged = true;
            },
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        save() {
            this.loading = true;
            axios.put(
                `/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`,
                this.profile,
                {
                    headers: {
                        'DA-Accept-Language': this.lang,
                    },
                },
            ).then(response => {
                this.profile = response.data.data;
                EventBus.$emit('success', this.$t('AdminProfile.saveSuccess'));
            }).catch(error => {
                if (_.has(error, 'response.data.errors')) {
                    this.$refs.profileContactForm.setErrors(_.get(error, 'response.data.errors', []));
                }

                if (_.has(error, 'response.data.message')) {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                }
            }).finally(() => {
                this.profileChanged = false;
                this.loading = false;
            });
        },
        load() {
            this.loading = true;

            axios.get(`/webapi/accounts/${this.user.account_id}/profiles/${this.profileId}`, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.profile = response.data.data;
            }).finally(() => {
                this.profileChanged = false;
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
