<template>
    <v-card data-cy="my-documents-item">
        <v-menu location="left">
            <template #activator="{ props: menuProps }">
                <v-tooltip location="bottom">
                    <template #activator="{ props: tooltipProps }">
                        <v-btn
                            color="grey"
                            theme="dark"
                            icon
                            variant="text"
                            class="ma-2 right-0"
                            position="absolute"
                            v-bind="{ ...tooltipProps, ...menuProps }"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('OneWord.more') }}</span>
                </v-tooltip>
            </template>
            <v-list density="compact">
                <template v-if="hasStatus">
                    <v-list-subheader>STATUS</v-list-subheader>
                    <v-list-item :disabled="!document.download_url" @click="document.download_url ? $emit('download') : null">
                        <template #prepend>
                            <v-icon :color="getStatusColor('downloaded_at')">{{ getIcon('downloaded_at') }}</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('OneWord.download') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="markAsSigned">
                        <template #prepend>
                            <v-icon :color="getStatusColor('signed_at')">{{ getIcon('signed_at') }}</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsSigned') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="markAsArchived">
                        <template #prepend>
                            <v-icon :color="getStatusColor('archived_at')">{{ getIcon('archived_at') }}</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsArchived') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                </template>
                <v-list-item data-cy="menu-btn-clear" @click="$emit('delete')">
                    <template #prepend>
                        <v-icon>mdi-delete</v-icon>
                    </template>
                    <v-list-item-title>{{ $t('OneWord.delete') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class="px-6 pb-1 pt-6 text-center">
            <div class="d-inline-block pa-6 bg-primary-lighten-1 rounded-full" style="width: 7rem">
                <v-icon size="4rem" color="white">{{ docIcon }}</v-icon>
            </div>
        </div>
        <div class="flex flex-row justify-between px-4 align-middle">
            <div>
                <v-chip
                    v-if="isDraft"
                    class="ma-0"
                    label
                    size="x-small"
                >
                    {{ $t('OneWord.draft') }}
                </v-chip>
            </div>
            <div>
                <v-icon
                    v-if="hasStatus"
                    :color="documentStatusColor()"
                    class="ma-0"
                    data-cy="document-status-icon"
                    end
                    size="x-small"
                >
                    mdi-circle
                </v-icon>
                <v-icon v-else color="white" size="x-small">mdi-circle</v-icon>
            </div>
        </div>
        <div class="my-2 border-t border-solid border-gray-400" />
        <v-card-text class="py-0">
            <div class="text-h6 text-truncate text-nowrap">{{ docTitle }}</div>
            <div class="text-truncate text-nowrap">
                <span v-html="personName" />
            </div>
        </v-card-text>
        <v-card-actions class="px-4">
            <div class="text-caption text-grey inline-block">{{ $filters.date(document.created_at) }}</div>
            <v-spacer />
            <div>
                <v-tooltip location="bottom">
                    <template #activator="{ props }">
                        <v-btn
                            v-if="document.type === 'patient_decree'"
                            v-bind="props"
                            icon="mdi-pencil"
                            color="grey-lighten-1"
                            variant="flat"
                            data-cy="btn-edit"
                            size="x-small"
                            class="mr-2"
                            :to="{name: 'patientenverfuegung-edit', params: {patient_decree_id: document.id}}"
                        />
                        <v-btn
                            v-if="document.type === 'advance_care_directive'"
                            v-bind="props"
                            icon="mdi-pencil"
                            color="grey-lighten-1"
                            theme="dark"
                            variant="flat"
                            data-cy="btn-edit"
                            size="x-small"
                            class="mr-2"
                            :to="{path: `/vorsorgeauftrag/${document.id}`}"
                        />
                    </template>
                    <span>{{ $t('OneWord.edit') }}</span>
                </v-tooltip>
                <v-tooltip location="bottom">
                    <template #activator="{ props }">
                        <v-btn
                            v-if="document.download_url"
                            v-bind="props"
                            theme="dark"
                            icon="mdi-arrow-down"
                            variant="flat"
                            size="x-small"
                            color="grey-lighten-1"
                            @click="$emit('download')"
                        />
                    </template>
                    <span>{{ $t('OneWord.downloadPdf') }}</span>
                </v-tooltip>
                <v-tooltip location="bottom">
                    <template #activator="{ props }">
                        <v-btn
                            v-if="document.website_url"
                            theme="dark"
                            icon="mdi-open-in-new"
                            variant="flat"
                            size="x-small"
                            color="grey-lighten-1"
                            v-bind="props"
                            @click="openUrl(document.website_url)"
                        />
                    </template>
                    <span>{{ $t('OneWord.openUrl') }}</span>
                </v-tooltip>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'MyDocumentItem',
    props: {
        document: {
            type: Object,
            default() {
                return {
                    type: null,
                    person_name: null,
                    created_at: null,
                };
            },
        },
    },
    computed: {
        personName() {
            return _.get(this.document, 'person_name', '&nbsp;');
        },
        hasStatus() {
            switch (this.document.type) {
            case 'testament':
            case 'patient_decree':
            case 'advance_care_directive':
                return true;
            default:
                return false;
            }
        },
        docTitle() {
            switch (this.document.type) {
            case 'testament':
                return this.$t('OneWord.testament');
            case 'patient_decree':
                return this.$t('OneWord.patientDecree');
            case 'advance_care_directive':
                return this.$t('AppUserNav.advance_care_directive');
            default:
                return '';
            }
        },
        docIcon() {
            switch (this.document.type) {
            case 'testament':
                return 'mdi-gavel';
            case 'patient_decree':
                return 'mdi-file-sign';
            case 'advance_care_directive':
                return 'mdi-file-account-outline';
            default:
                return '';
            }
        },
        isDraft() {
            return _.get(this.document, 'is_draft', false);
        },
    },
    methods: {
        markAsSigned() {
            if (this.document.type === 'patient_decree') {
                axios.post(`/webapi/patient-decree/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'testament') {
                axios.post(`/webapi/testaments/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'advance_care_directive') {
                axios.post(`/webapi/advance-care-directive/${this.document.id}/mark`, {
                    mark: 'signed',
                }).then(() => {
                    this.$emit('mark');
                });
            }
        },
        markAsArchived() {
            if (this.document.type === 'patient_decree') {
                axios.post(`/webapi/patient-decree/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'testament') {
                axios.post(`/webapi/testaments/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }

            if (this.document.type === 'advance_care_directive') {
                axios.post(`/webapi/advance-care-directive/${this.document.id}/mark`, {
                    mark: 'archived',
                }).then(() => {
                    this.$emit('mark');
                });
            }
        },
        getStatusColor(field) {
            let val = _.get(this.document, field, false);
            if (val) {
                return 'green';
            }
            return 'red';
        },
        getIcon(field) {
            let val = _.get(this.document, field, false);
            if (val) {
                return 'mdi-check-circle';
            }
            return 'mdi-checkbox-blank-circle';
        },
        documentStatusColor() {
            if (
                _.get(this.document, 'downloaded_at', false)
                && _.get(this.document, 'signed_at', false)
                && _.get(this.document, 'archived_at', false)
            ) {
                return 'green';
            }

            if (_.get(this.document, 'downloaded_at', false)) {
                return 'orange';
            }

            return 'red';
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
.v-fab {
    :deep(.v-icon) {
        color: #fff;
    }

    :deep(.v-fab__container) {
        position: relative;
    }
}
</style>
