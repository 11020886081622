const initialState = {
    loaded: false,
    account: {},
};

const state = _.cloneDeep(initialState);

const getters = {
    account: state => state.account,
};

const actions = {
    load({state, commit}, accountId) {
        if (!state.loaded) {
            return axios.get(`/webapi/accounts/${accountId}`).then(response => {
                commit('load', response.data.data);
            });
        }
    },
    update({commit}, data) {
        return axios.put(`/webapi/accounts/${data.id}`, data).then(response => {
            commit('update', response.data.data);
        });
    },
};

const mutations = {
    load(state, data) {
        state.account = data;
        state.loaded = true;
    },
    update(state, data) {
        state.account = data;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
