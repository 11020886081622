<template>
    <vee-form ref="createProfileContact" @submit="save()">
        <v-card :loading="saving">
            <v-card-text>
                <profile-form
                    v-model="profile"
                    :is-ngo="isNgo"
                    lang="de_CH"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn variant="text" @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn type="submit" color="accent" :loading="saving">
                    <v-icon start>mdi-content-save</v-icon>
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<script>
import {Form as VeeForm} from "vee-validate";
import ProfileForm from '../admin/form/ProfileForm';
import store from '../../store';

export default {
    name: 'CreateProfileContact',
    components: {ProfileForm, VeeForm},
    beforeRouteEnter(to, from, next) {
        axios.get('/webapi/create-profile/profile').then(response => {
            next(vm => {
                vm.profile = response.data.data;
                vm.profile.email = store.getters['user/user'].email;
            });
        });
    },
    data() {
        return {
            profile: {},
            saving: false,
        };
    },
    computed: {
        isNgo() {
            return _.map(_.get(this.profile, 'partner_types', []), 'id').includes(12);
        },
    },
    methods: {
        save() {
            this.saving = true;

            axios.post('/webapi/create-profile/contact', this.profile).then(() => {
                this.$router.push({name: 'create-profile-submit'});
            }).catch(error => {
                this.$refs.createProfileContact.setErrors(error.data.errors);
            }).finally(() => {
                this.saving = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
