<template>
    <v-dialog v-model="dialog" :max-width="optionsInternal.width" @keydown.esc="cancel()">
        <v-card>
            <v-card-title class="bg-primary-lighten-2 text-white">
                {{ title || titleInternal }}
            </v-card-title>
            <v-card-text class="pt-4">
                {{ message || messageInternal }}
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn variant="text" data-cy="confirm-btn-cancel" @click="cancel()">
                    {{ optionsInternal.cancel || $t('OneWord.cancel') }}
                </v-btn>
                <v-btn data-cy="confirm-btn-yes" @click="agree()">
                    {{ optionsInternal.confirm || $t('OneWord.yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";

/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open(this.$t('OneWord.delete'), 'Sind Sie sicher dass Sie löschen möchten?', { color: 'red' }).then((confirm) => {});
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open;
 * }
 */
export default {
    name: 'Confirm',
    props: {
        value: {
            type: Boolean,
            default: false,
            handler(value) {
                if (value) {
                    this.open();
                } else {
                    this.cancel();
                }
            }
        },
        options: {
            type: Object,
            default: () => ({}),
            handler: function (value) {
                this.optionsInternal = Object.assign(this.optionsInternal, value);
            }
        },
        title: String,
        message: String,
    },
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            messageInternal: null,
            titleInternal: null,
            optionsInternal: {
                color: 'primary',
                width: 380,
                cancel: null,
                confirm: null,
            },
        };
    },
    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.titleInternal = _.isEmpty(title) ? this.titleInternal : title;
            this.messageInternal = _.isEmpty(message) ? this.messageInternal : message;
            this.optionsInternal = Object.assign(this.optionsInternal, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            if (_.isFunction(this.resolve)) {
                this.resolve(true);
            }

            this.dialog = false;
            this.$emit('click:agree');
        },

        cancel() {
            if (_.isFunction(this.resolve)) {
                this.resolve(false);
            }

            this.dialog = false;
            this.$emit('click:cancel');
        },
    },
};
</script>
