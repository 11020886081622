<template>
    <div data-cy="consulting-tool">
        <h1 class="text-h6 mb-2">
            {{ $t('AdminTools.backlinks.consulting') }}
        </h1>

        <v-sheet
            v-if="!isCompleted"
            :class="{'elevation-1': $vuetify.display.lgAndUp}"
            class="pa-4"
        >
            <tool-stepper
                :step="step"
                :steps="steps"
            />

            <component
                :is="stepComponent"
                @back="back"
                @next="next"
            />
        </v-sheet>
    </div>
</template>

<script>
import ToolStepper from '@/components/ToolStepper';
import {mapActions, mapGetters} from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'ConsultingTool',
    components: {
        ToolStepper,
        ConsultingStep1: defineAsyncComponent(() => import('@/pages/consulting/ConsultingStep1.vue')),
        ConsultingStep2: defineAsyncComponent(() => import('@/pages/consulting/ConsultingStep2.vue')),
        ConsultingStep3: defineAsyncComponent(() => import('@/pages/consulting/ConsultingStep3.vue')),
    },
    computed: {
        ...mapGetters('consulting', [
            'step'
        ]),

        isCompleted() {
            return this.step > 3;
        },

        stepComponent() {
            return `ConsultingStep${this.step}`;
        },

        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('Consulting.step1.title'),
                }, {
                    step: 2,
                    text: this.$t('Consulting.step2.title'),
                }, {
                    step: 3,
                    text: this.$t('Consulting.step3.title'),
                },
            ];
        },
    },

    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);

        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }
    },

    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        ...mapActions('consulting', [
            'setStep',
        ]),

        next() {
            if (this.isCompleted) {
                this.$router.push({name: 'consulting-completed'});
            } else {
                this.setStep(this.step + 1);
            }
        },

        back() {
            this.setStep(this.step - 1);
        },
    },
};
</script>