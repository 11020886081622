<template>
    <vee-form ref="applicationForm" @submit="save()">
        <v-card>
            <v-card-text>
                <h2 class="text-h6 mb-2">
                    {{ $t('ApplicationForm.title') }}
                </h2>

                <v-expansion-panels class="mb-2">
                    <v-expansion-panel>
                        <v-expansion-panel-title disable-icon-rotate color="info">
                            Informationen zur Bewerbung
                            <template #actions>
                                <v-icon color="white">mdi-alert-circle</v-icon>
                            </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="pt-3">
                            <p>
                                DeinAdieu versteht sich als Qualitätsgarant für seine Enduser. Aus diesem Grund wird
                                jede Bewerbung von einem unabhängigen Gremium geprüft.
                            </p>

                            <h2 class="text-subtitle-1">
                                Folgende Kriterien entscheiden über die Aufnahme von <strong>Hilfswerken</strong>:
                            </h2>

                            <p>
                                Aktuelle ZEWO-Zertifizierung oder Einhaltung folgender Aufnahmekriterien:
                            </p>

                            <ul>
                                <li>Steuerbefreiter Verein oder Stiftung</li>
                                <li>Komplette Jahresrechnung, die die Mittelverwendung klar darlegt</li>
                                <li>Niederlassung in der Schweiz</li>
                                <li>Transparente Onlinepräsenz mit Impressum & Datenschutzerklärung</li>
                                <li>
                                    Organisation handelt integer, professionell, ethisch & wirkungsorientiert und
                                    achtet die Solidarität unter den bestehenden Mitgliedern
                                </li>
                            </ul>

                            <p>
                                Verwendung der Gelder für einen der folgenden Zwecke: Menschenrechte & Humanitäres,
                                Natur-, Umwelt-, & Tierschutz, Menschen mit Behinderung, Kinder & Jugendliche,
                                Senioren-, Sozial- & Nothilfe, Gesundheitsprävention & Forschung,
                                Entwicklungszusammenarbeit. DeinAdieu behält sich das Recht vor, Bewerbungen ohne
                                Nennung von Gründen abzulehnen.
                            </p>

                            <h2 class="text-subtitle-1">
                                Folgende Kriterien entscheiden über die Aufnahme von <strong>Dienstleistern</strong>:
                            </h2>

                            <h2 class="text-subtitle-2">Professionalität</h2>

                            <ul>
                                <li>(Erst-)Ausbildung</li>
                                <li>Equipment</li>
                                <li>Standards</li>
                            </ul>
                            <br>

                            <h2 class="text-subtitle-2">Erfahrung</h2>

                            <ul>
                                <li>Erfahrung im Umgang mit Betroffenen & Angehörigen</li>
                                <li>Frequenz der erbrachten Dienstleistungen</li>
                                <li>Referenzen</li>
                            </ul>
                            <br>

                            <span class="text-subtitle-2">Leumund</span>

                            <ul>
                                <li>HR-Eintrag</li>
                                <li>Betreibungen</li>
                            </ul>
                            <br>

                            <p>In jeder Dienstleisterkategorie werden diese Kriterien unterschiedlich angewandt.</p>

                            <p>
                                <strong>Bestatter:</strong> >2 Jahre Erfahrung, aktiv im HR ohne Betreibung, Minimum
                                2 Angestellte (Tag/Nachtdienst), mind. 1 Bestatter/-in mit eidg. Fachausweis, 24h
                                Piket, min. 25 Bestattungen pro Jahr, Fahrzeug nach Richtlinien für
                                Bestatterfahrzeuge, 2-3 Kundenreferenzen.
                            </p>

                            <p>
                                <strong>Palliative Care:</strong> Für Institutionen Qualitätsstandards im Bereich
                                Palliative Care von Palliative.ch. Wünschenswert: Label „Qualité Palliative“
                                (Schweizer Verein für Palliative Care). Für SterbebegleiterInnen Fachwissen im
                                Bereich Medizin, Medizinrecht, Ethik und Psychologie. Erfahrung mit der
                                medizinethischen Entscheidungsfindung am Lebensende in Spitälern, Heimen oder
                                Spitex.
                            </p>

                            <p>
                                <strong>Notare & Rechtsanwälte:</strong> Fachanwälte Erbrecht, juristische Erfahrung
                                in Bereichen Ehe-/Erbrecht und gegebenenfalls der Willensvollstreckung,
                                Mitgliedschaft beim SAV/FSA.
                            </p>

                            <p>
                                <strong>Redner & Musiker:</strong> (Erst-)Ausbildung, 2-3 Kunden-Referenzen,
                                rhetorische/musikalische Nachweise, Frequenz der Tätigkeit: >15 Auftritte/Jahr.
                            </p>

                            <p>
                                <strong>Verpflegung:</strong> Saal oder Bereich für Trauergesellschaft mit
                                mindestens 15 Sitzplätzen, Erfahrung im Bewirten von Trauergesellschaften,
                                entsprechende Öffnungszeiten.
                            </p>

                            <p>
                                <strong>Non-Profit:</strong> Aktuelle ZEWO-Zertifizierung, Verwendung der Gelder für
                                einen der folgenden Zwecke: Menschenrechte & Humanitäres, Natur-, Umwelt-, &
                                Tierschutz, Menschen mit Behinderung, Kinder & Jugendliche, Senioren-, Sozial- &
                                Nothilfe, Gesundheitsprävention & Forschung,
                                Entwicklungszusammenarbeit.
                            </p>

                            <p>
                                <strong>BestattungsplanerInnen:</strong> Basiswissen im Bereich Medizin,
                                Medizinrecht, Ethik und Psychologie. Erfahrung im Umgang mit Betroffenen und
                                Angehörigen. 4-5 Referenzen. Frequenz: >15 Einsätze pro Jahr.
                            </p>

                            <p>
                                <strong>Bildhauer:</strong> >2 Jahre Erfahrung im Beruf, aktiv im HR ohne
                                Betreibung, 2-3 Kunden- Referenzen.
                            </p>

                            <p>
                                Bitte legen Sie die entsprechenden Punkte in Ihrer Bewerbung dar. Bei Fragen stehen
                                wir Ihnen unter <a href="mailto:info@deinadieu.ch">info@deinadieu.ch</a> zur
                                Verfügung.
                            </p>

                            <p>
                                Weitere Informationen:<br>
                                Nutzungsbedingungen: <a
                                    href="https://www.deinadieu.ch/partner-terms"
                                    target="_blank"
                                >www.deinadieu.ch/partner-terms</a><br>
                                Datenschutz: <a href="https://www.deinadieu.ch/datenschutzerklaerung/">www.deinadieu.ch/datenschutzerklaerung/</a><br>
                            </p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="application.application_text"
                    :label="$t('ApplicationForm.applicationText')"
                    rules="required"
                    name="application_text"
                >
                    <v-textarea
                        v-bind="componentField"
                        :error-messages="errors"
                        :rows="6"
                        auto-grow
                        variant="outlined"
                    />
                </vee-field>

                <h2 class="text-h6 mb-2 mt-4">
                    {{ $t('ApplicationForm.documentsTitle') }}
                </h2>

                <v-data-table
                    v-if="hasApplicationDocuments"
                    :headers="documentHeaders"
                    :items="application.account_application_documents"
                    class="mb-4"
                    disable-pagination
                    hide-default-footer
                    hide-default-header
                >
                    <template #item.actions="{item}">
                        <v-btn icon size="small" @click.stop="deleteDocument(item)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <v-alert type="info" density="compact" text>
                    {{ $t('ApplicationForm.uploadInfoText') }}
                </v-alert>
                <v-alert
                    v-if="dropzoneError"
                    type="error"
                    density="compact"
                    text
                    closable
                >
                    {{ dropzoneError }}
                </v-alert>

                <Vue3Dropzone
                    id="application-form-vue-dropzone"
                    ref="dropzone"
                    v-model="files"
                    :max-file-size="3"
                    :show-select-button="false"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn variant="text" @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn type="submit" :loading="saving">{{ $t('OneWord.next') }}</v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<script>
import Vue3Dropzone from "@/components/Dropzone/Vue3Dropzone";
import '@/components/Dropzone/style.css'
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '../../utils/event-bus';

export default {
    name: 'ApplicationForm',
    components: {VeeField, VeeForm, Vue3Dropzone},
    props: {
        account: Object,
    },
    data() {
        return {
            application: {},
            files: [],
            saving: false,
            dropzoneError: null,
        };
    },
    computed: {
        documentHeaders() {
            return [
                {title: 'Name', value: 'document_name'},
                {title: '', value: 'actions', align: 'right'},
            ];
        },
        hasApplicationDocuments() {
            return _.get(this.application, 'account_application_documents', []).length > 0;
        },
    },
    mounted() {
        axios.get('/webapi/create-profile/account').then(response => {
            this.application = response.data.data;
        });
    },
    methods: {
        deleteDocument(document) {
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('ConfirmDelete', {parameter: document.document_name})
            ).then(confirm => {
                if (confirm) {
                    axios.delete(`/webapi/create-profile/application/documents/${document.id}`).then(() => {
                        this.application.account_application_documents = [
                            ...this.application.account_application_documents
                        ];
                        _.remove(this.application.account_application_documents, {id: document.id});
                    }).catch(error => {
                        EventBus.$emit('error', error.data.message);
                    });
                }
            });
        },
        save() {
            this.saving = true;
            this.saveFiles();
        },
        saveFiles() {
            this.files.length = 0;
            let files = this.$refs.dropzone.getAcceptedFiles();
            let promises = [];

            this.dropzoneError = null;

            _(files).forEach(file => {
                let form = new FormData();
                form.append('data', file, file.name);

                promises.push(this.toBase64(form.get('data')));
            });

            Promise.all(promises).then(res => {
                _(files).forEach((file, index) => {
                    this.files.push({
                        name: file.name,
                        data: res[index],
                    });
                });

                let payload = this.application;
                payload['application_files'] = this.files;

                axios.post(`/webapi/create-profile/application/${this.application.id}`, payload).then(() => {
                    // this.$emit('files', this.files);
                    // this.$emit('save', this.application);
                    this.$router.push({name: 'create-profile-partner-type'});
                }).catch(error => {
                    if (!_.isEmpty(_.get(error.data.error, 'application_files'))) {
                        this.$refs.applicationForm.setErrors(error.data.errors);
                    } else {
                        this.dropzoneError = _.get(error.data, 'message');
                    }
                }).finally(() => {
                    this.saving = false;
                });
            });
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
};
</script>

<style scoped lang="scss">

</style>
