/**
 * Formats a date object to a date string.
 * 
 * @param Date  dateObject  a date object
 * @return string  YYYY-MM-DD  a formatted date string
 */
const formatDate = (dateObject) =>
{
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // month is zero-indexed
    const day = String(dateObject.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const localizeDate = (value, format = 'DD.MM.YYYY', locale) => {
    if (locale) {
        return moment(value).locale(locale).format(format);
    }
    return moment(value).format(format);
}

const localizeTime = (value, format = 'HH:mm') => {
    return moment(value).format(format);
}

const shortTimezone = (timezoneString) => {
    timezoneString = timezoneString || Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
        const options = { timeZone: timezoneString, timeZoneName: 'short' };
        const formatter = new Intl.DateTimeFormat([], options);
        return formatter.formatToParts().find(part => part.type === 'timeZoneName').value;
    } catch (error) {
        console.error(`Error converting timezone: ${error.message}`);
        return null;
    }
}


export {
    formatDate,
    localizeDate,
    localizeTime,
    shortTimezone,
}