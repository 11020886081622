<script setup>
import {get} from "lodash";
import Confirm from "@/components/Confirm.vue";
import {ref} from "vue";

const emit = defineEmits(["update:toggle", "error"]);

const confirmDialog = ref(false);

const props = defineProps({
    item: {
        type: Object,
        required: true,
        default: () => ({
            ngo_is_visible: null,
        })
    }
})

const hideItem = () => {
    confirmDialog.value = true;
}

const showItem = () => {
    axios.put(`/webapi/leads/${props.item.id}/toggle`).then(() => {
        emit("update:toggle", props.item);
    }).catch((error) => {
        emit("error", get(error, "response.data.message"));
    });
}

const hideItemSave = () => {
    axios.put(`/webapi/leads/${props.item.id}/toggle`).then(() => {
        confirmDialog.value = false;
        emit("update:toggle", props.item);
    }).catch((error) => {
        emit("error", get(error, "response.data.message"));
    });
}
</script>

<template>
    <div>
        <confirm
            v-model="confirmDialog"
            :options="{ confirm: 'Ausblenden' }"
            :title="$t('AdminLeadEdit.confirmHide.title')"
            :message="$t('AdminLeadEdit.confirmHide.message')"
            @click:agree="hideItemSave"
            @click:cancel="confirmDialog = false"
        />
        <v-menu location="bottom right">
            <template #activator="{ props }">
                <v-btn
                    icon="mdi-dots-vertical"
                    variant="text"
                    v-bind="props"
                    size="small"
                    data-cy="menu-actions"
                />
            </template>
            <v-list>
                <v-list-item v-if="item.ngo_is_visible === false" data-cy="menu-actions-show" @click="showItem()">
                    <v-list-item-title>{{ $t('AdminLeadEdit.actions.showItem') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.ngo_is_visible === true" data-cy="menu-actions-hide" @click="hideItem()">
                    <v-list-item-title>{{ $t('AdminLeadEdit.actions.hideItem') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
