<template>
    <v-app-bar
        :flat="flat"
        class="px-2"
        color="grey-lighten-4"
    >
        <v-app-bar-nav-icon
            v-if="$vuetify.display.mdAndDown"
            @click.stop="$emit('drawer')"
        />

        <a :href="getHomepage" :title="getName" rel="noopener" target="_blank">
            <v-toolbar-title class="cursor-pointer">
                <div v-if="getLogo" class="flex items-center">
                    <small 
                        v-if="hasProfile && !isAuthenticated && !isWhiteLabel"
                        class="inline-block pt-2 text-xs"
                        data-cy="logo-tenant-powered-by"
                    >
                        {{ $t('OneWord.poweredBy') }}:
                    </small>
                    <img
                        class="ml-2 inline-block h-8"
                        :src="getLogo"
                        data-cy="logo-tenant"
                    >
                </div>
                <div v-else class="flex items-center">
                    <small
                        v-if="hasProfile && !isAuthenticated"
                        class="inline-block text-xs"
                        data-cy="logo-da-powered-by"
                    >
                        {{ $t('OneWord.poweredBy') }}:
                    </small>
                    <img 
                        class="ml-2 inline-block h-8"
                        :src="require('@/assets/img/deinadieu_logo.svg')" 
                        style="fill: white"
                        data-cy="logo-da"
                    >
                </div>
            </v-toolbar-title>
        </a>

        <v-spacer />

        <v-menu>
            <template #activator="{ props }">
                <v-btn
                    class="text-none mr-2"
                    data-cy="language-select"
                    variant="outlined"
                    v-bind="props"
                    :loading="languageLoading"
                    :data-loaded="languageLoading ? 'false' : 'true'"
                >
                    <v-icon start>mdi-translate</v-icon>
                    <span class="hidden-md-and-down">{{ languageLabel }}</span>
                    <v-icon end>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="language in languages"
                    :key="language.locale"
                    data-cy="language-select-item"
                    @click="changeLocale(language.locale)"
                >
                    <v-list-item-title>{{ language.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <template v-if="isAuthenticated">
            <the-notifications-menu />
            <v-menu>
                <template #activator="{ props }">
                    <v-btn
                        class="text-none ml-2"
                        color="primary"
                        data-cy="btn-user"
                        variant="flat"
                        v-bind="props"
                    >
                        <v-icon start>mdi-account-outline</v-icon>
                        <span class="hidden-md-and-down">{{ displayName }}</span>
                        <v-icon end>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="profile">
                        <template #prepend>
                            <v-icon>mdi-account-edit</v-icon>
                        </template>
                        
                        <v-list-item-title>{{ $t('App.nav.profile') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item data-cy="logout" @click="logout">
                        <template #prepend>
                            <v-icon>mdi-logout</v-icon>
                        </template>
                        
                        {{ $t('OneWord.logout') }}
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-else>
            <v-tooltip location="bottom">
                <template #activator="{ props }">
                    <v-btn
                        class="text-none"
                        color="primary"
                        variant="flat"
                        v-bind="props"
                        @click="$router.push({name: 'login' })"
                    >
                        <v-icon :start="!$vuetify.display.smAndDown">mdi-login</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('OneWord.login') }}</span>
                    </v-btn>
                </template>
                <span>{{ $t('AppBar.login') }}</span>
            </v-tooltip>
        </template>
    </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {usei18n} from '@/plugins/i18n';
import {filter, find, get} from "lodash";
import TheNotificationsMenu from "@/components/TheNotificationsMenu.vue";

export default {
    name: 'AppBar',
    components: {
        TheNotificationsMenu,
    },
    props: {
        flat: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            languageLabel: null,
            languageLoading: false,
            selected: [1],
        };
    },
    computed: {
        ...mapGetters('user', [
            'user',
        ]),
        ...mapGetters('env', [
            'getLogo',
            'getLanguages',
            'isAuthenticated',
            'isWhiteLabel',
            'getHomepage',
            'getName',
        ]),
        ...mapGetters('affiliateProfile', [
            'hasProfile',
        ]),
        languages() {
            return filter([
                {lang: 'de', locale: 'de-CH', label: this.$t('OneWord.lang.german')},
                {lang: 'en', locale: 'en-GB', label: this.$t('OneWord.lang.english')},
                {lang: 'fr', locale: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {lang: 'it', locale: 'it-CH', label: this.$t('OneWord.lang.italian')},
            ], obj => get(this.getLanguages, obj.lang, false));
        },
        lang() {
            return usei18n().locale?.substring(0, 2) || 'de';
        },
        displayName() {
            return get(this.user, 'display_name');
        },
    },
    watch: {
        lang(lang) {
            this.setLanguageLabel(lang);
        },
    },
    mounted() {
        this.setLanguageLabel(this.lang);
    },
    methods: {
        ...mapActions('env', [
            'logout',
        ]),
        changeLocale(locale) {
            this.languageLoading = true;
            EventBus.$once('locale-changed', () => {
                this.languageLoading = false;
            });
            EventBus.$emit('change-locale', locale);
            this.setLanguageLabel(locale);
        },
        getLocale(lang) {
            switch (lang) {
            case 'en':
                return 'en-GB';
            case 'fr':
                return 'fr-CH';
            case 'it':
                return 'it-CH';
            default:
                return 'de-CH';
            }
        },
        setLanguageLabel(value) {
            let locale = value.includes('-') ? value : this.getLocale(value),
                language = find(this.languages, {locale: locale});

            if (language) {
                this.languageLabel = language.label;
            }
        },
        phone() {
            window.open('tel:+41445005237');
        },
        profile() {
            this.$router.push({path: '/user'});
        },
    }
};
</script>

<style lang="scss" scoped>
.v-list {
    .v-list-item {
        &.mark-unread {
            @apply border-l-4;
            @apply border-blue-400;
            @apply bg-blue-100;
        }
    }
}
</style>
