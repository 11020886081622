<template>
    <vee-form ref="accountForm" @submit="save()">
        <v-card>
            <v-card-text>
                <h2 class="text-h6 mb-2">
                    {{ $t('AccountForm.title') }}
                </h2>

                <v-alert type="info" density="compact">
                    {{ $t('AccountForm.info') }}
                </v-alert>

                <v-alert v-if="formError" type="error" density="compact">
                    {{ formError }}
                </v-alert>

                <h3 class="text-subtitle-1 mb-2">
                    {{ $t('AccountForm.subheaderCompany') }}
                </h3>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.company_name"
                    :label="$t('OneWord.company')"
                    rules="required|max:255"
                    name="company_name"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.company')"
                        :counter="255"
                        :error-messages="errors"
                    />
                </vee-field>

                <v-row>
                    <v-col cols="12" md="8">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.street"
                            :label="$t('OneWord.address')"
                            rules="required|max:255"
                            name="street"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.address')"
                                :error-messages="errors"
                                :counter="255"
                                autocomplete="street-address"
                            />
                        </vee-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.post_office_box"
                            :label="$t('OneWord.poBox')"
                            rules="max:255"
                            name="post_office_box"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.poBox')"
                                :error-messages="errors"
                                :counter="255"
                                autocomplete="street-address"
                            />
                        </vee-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.zip"
                            :label="$t('OneWord.zip')"
                            rules="required|max:5"
                            name="zip"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.zip')"
                                :error-messages="errors"
                                autocomplete="postal-code"
                            />
                        </vee-field>
                    </v-col>
                    <v-col cols="12" md="8">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.place"
                            :label="$t('OneWord.place')"
                            rules="required|max:255"
                            name="place"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.place')"
                                :error-messages="errors"
                                autocomplete="address-level2"
                                :counter="255"
                            />
                        </vee-field>
                    </v-col>
                </v-row>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.website"
                    :label="$t('OneWord.website')"
                    rules="required|url"
                    name="website"
                >
                    <v-text-field
                        v-bind="componentField"
                        :label="$t('OneWord.website')"
                        :error-messages="errors"
                        :counter="255"
                        prepend-inner-icon="mdi-web"
                        :hint="$t('OneWord.urlHint')"
                    />
                </vee-field>

                <h3 class="text-subtitle-1 mb-2 mt-4">
                    {{ $t('AccountForm.subheaderContact') }}
                </h3>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="company.salutation_id"
                    :label="$t('OneWord.salutation.label')"
                    rules="required"
                    name="salutation_id"
                >
                    <v-select
                        v-bind="componentField"
                        :label="$t('OneWord.salutation.label')"
                        :items="salutations"
                        item-title="label"
                        item-value="id"
                        :error-messages="errors"
                    />
                </vee-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.first_name"
                            :label="$t('OneWord.first-name')"
                            rules="required|max:255"
                            name="first_name"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.first-name')"
                                :error-messages="errors"
                                autocomplete="given-name"
                                :counter="255"
                            />
                        </vee-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.last_name"
                            :label="$t('OneWord.last-name')"
                            rules="required|max:255"
                            name="last_name"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.last-name')"
                                :error-messages="errors"
                                autocomplete="family-name"
                                :counter="255"
                            />
                        </vee-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.email"
                            :label="$t('OneWord.email')"
                            rules="required|email|max:255"
                            name="email"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.email')"
                                :error-messages="errors"
                                autocomplete="email"
                                :hint="$t('AccountForm.EmailHint')"
                                persistent-hint
                                prepend-inner-icon="mdi-at"
                                :counter="255"
                            />
                        </vee-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.phone"
                            :label="$t('OneWord.phone')"
                            rules="max:255|phone"
                            name="phone"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.phone')"
                                :error-messages="errors"
                                prepend-inner-icon="mdi-phone"
                                type="tel"
                            />
                        </vee-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="company.mobile_phone"
                            :label="$t('OneWord.mobile')"
                            rules="max:255|phone"
                            name="mobile"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :label="$t('OneWord.mobile')"
                                :error-messages="errors"
                                prepend-inner-icon="mdi-cellphone"
                                type="tel"
                            />
                        </vee-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :loading="saving"
                    color="accent"
                    type="submit"
                >
                    {{ $t('OneWord.next') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {mapGetters} from 'vuex';

export default {
    name: 'AccountStep',
    components: {VeeField, VeeForm},
    data() {
        return {
            company: {},
            salutations: [
                {id: 1, label: this.$t('OneWord.salutation.f')},
                {id: 2, label: this.$t('OneWord.salutation.m')},
            ],
            saving: false,
            formError: null,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
    },
    mounted() {
        axios.get('/webapi/create-profile/account').then(response => {
            this.company = response.data.data;
        }).finally(() => {
            this.company.email = this.user.email;
        });
    },
    methods: {
        save() {
            this.saving = true;
            this.formError = null;

            axios.post('/webapi/create-profile/account', this.company).then(response => {
                this.company = response.data.data;
                this.formError = null;
                this.$router.push({name: 'create-profile-application'});
            }).catch(error => {
                this.$refs.accountForm.setErrors(_.get(error, 'data.errors', []));

                if (error.response.status === 409) {
                    this.formError = 'Sie besitzen bereits ein Profil bei DeinAdieu. Bitte erstellen sie neue Profile unter Partner > Präsenz.';
                }
                else {
                    this.formError = error.data.message;
                }
            }).finally(() => {
                this.saving = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
