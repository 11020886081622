<template>
    <div class="container">VVV</div>
</template>

<script>
export default {
    name: 'NgoProfile',
};
</script>

<style scoped>

</style>
