<template>
    <div data-cy="testament-tool">
        <h1 class="text-h6 mb-2">
            {{ $t('TestamentTool.title') }}
        </h1>
        <v-sheet class="pa-4" :class="{'elevation-1': $vuetify.display.lgAndUp}">
            <tool-stepper
                :step="step"
                :steps="steps"
            />

            <component :is="stepComponent" />
        </v-sheet>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ToolStepper from '@/components/ToolStepper';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'TestamentTool',
    components: {
        ToolStepper,
        TestamentStep1: defineAsyncComponent(() => import('@/pages/testament/TestamentStep1')),
        TestamentStep2: defineAsyncComponent(() => import('@/pages/testament/TestamentStep2')),
        TestamentStep3: defineAsyncComponent(() => import('@/pages/testament/TestamentStep3')),
        TestamentStep4: defineAsyncComponent(() => import('@/pages/testament/TestamentStep4')),
        TestamentStep5: defineAsyncComponent(() => import('@/pages/testament/TestamentStep5')),
        TestamentStep6: defineAsyncComponent(() => import('@/pages/testament/TestamentStep6')),
        TestamentStep7: defineAsyncComponent(() => import('@/pages/testament/TestamentStep7')),
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('testament', {
            step: 'step',
        }),
        stepComponent() {
            return `TestamentStep${this.step}`;
        },
        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('TestamentTool.stepper.step1'),
                }, {
                    step: 2,
                    text: this.$t('TestamentTool.stepper.step2'),
                }, {
                    step: 3,
                    text: this.$t('TestamentTool.stepper.step3'),
                }, {
                    step: 4,
                    text: this.$t('TestamentTool.stepper.step4'),
                }, {
                    step: 5,
                    text: this.$t('TestamentTool.stepper.step5'),
                }, {
                    step: 6,
                    text: this.$t('TestamentTool.stepper.step6'),
                }, {
                    step: 7,
                    text: this.$t('TestamentTool.stepper.step7'),
                },
            ];
        },
    },
    watch: {
        '$route'(to) {
            if (to.name === 'testament-affiliate') {
                this.loadAffiliate(to.params.profile_slug);
            }
        },
        step(value) {
            this.changeStep(value);
        },
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);

        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),
        changeStep() {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style scoped>

</style>
