<template>
    <v-sheet data-cy="how-it-works" color="white" class="how-it-works py-10 text-black">
        <v-row>
            <v-col cols="12" :lg="isWhiteLabel ? 6 : 5" :class="isWhiteLabel ? 'mx-auto' : null">
                <h2 class="text-2xl font-medium">{{ $t('HowItWorks.headline') }}</h2>
                <small class="text-grey mb-4 mt-1 block">{{ $t('HowItWorks.subheadline') }}</small>
                <ol>
                    <li>{{ $t('HowItWorks.steps.1') }}</li>
                    <li>{{ $t('HowItWorks.steps.2') }}</li>
                    <li>{{ $t('HowItWorks.steps.3') }}</li>
                    <li>{{ $t('HowItWorks.steps.4', {organization: organization}) }}</li>
                    <li>{{ $t('HowItWorks.steps.5') }}</li>
                    <li>{{ $t('HowItWorks.steps.6') }}</li>
                </ol>
            </v-col>
            <v-col v-if="!isWhiteLabel" cols="12" lg="7" class="lg:d-flex lg:justify-end">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/K5NRj9bxpKM?si=GbvnfmIDU1HuE2GP"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'HowItWorks',

    computed: {
        ...mapGetters('env', [
            'isWhiteLabel',
        ]),

        ...mapGetters('affiliateProfile', [
            'profile',
            'hasProfile',
        ]),

        organization() {
            if (this.hasProfile) {
                return this.profile.company_name;
            }
            
            return this.$t('HowItWorks.steps.4_defaultOrganization')
        },
    }
};
</script>

<style scoped lang="scss">
ol {
    list-style-type: decimal;

    li::marker { 
        color: var(--da-primary);
        content: "0" counter(list-item, decimal);
        font-weight: 500;
        font-size: 1.2em;
    }

    li {
        padding: 5px 10px;
    }
}

iframe {
    max-width: 100%;
}
</style>
