import store from "@/store";

export const pirsch = () => {
    // Construct the snippet.
    const snippet = document.createElement("script");
    snippet.src = "https://api.pirsch.io/pa.js";
    snippet.id = "pianjs";
    snippet.setAttribute("data-code", "V8YMkUV8YtWaG1zK6wNN1ydYDdWMDlpi");
    snippet.setAttribute("data-domain", "rollup.deinadieu.ch:ZS6q0Vqjd7eJuazebMq5SRCbjuDkvBwi");
    snippet.setAttribute("data-path-prefix", "/app");
    snippet.setAttribute("data-tag-tenant", store.getters["env/getSlug"]);
    snippet.setAttribute("data-tag-country", store.getters["env/getCountry"]);
    if (store.getters["env/isWhiteLabel"]) {
        snippet.setAttribute("data-tag", "whitelabel");
    }

    // Add the snippet to the site and init Pirsch.
    document.body.appendChild(snippet);

    // eslint-disable-next-line no-undef
    snippet.addEventListener("load", () => pirschInit());
};
