<template>
    <div class="text-left">
        <v-alert 
            v-if="hasError"
            class="mb-4"
            density="compact" 
            text
            type="error"
            data-cy="login--error-general"
        >
            {{ errorMessage }}
        </v-alert>

        <v-alert
            v-if="twoFactorError"
            class="mb-4"
            density="compact"
            text
            type="error"
            data-cy="login--error-2fa"
        >
            <v-row>
                <v-col cols="12">
                    {{ errorMessage }}
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" variant="flat" @click="cancelTwoFactor">
                        {{ $t('LoginForm.btn.TwoFactorRestart') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <template v-if="twoFactorAuth">
            <div>
                {{ $t('LoginForm.twoFactorInfoText') }}
            </div>
            <vee-form @submit="code()">
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="twoFactorCode"
                    label="Code"
                    rules="required"
                    name="code"
                >
                    <v-text-field
                        v-bind="componentField"
                        :error-messages="errors"
                        autocomplete="one-time-code"
                        class="mt-4"
                        inputmode="numeric"
                        variant="outlined"
                        pattern="[0-9]"
                        placeholder="123456"
                        autofocus
                    >
                        <template #append>
                            <div class="-my-4">
                                <v-btn class="text-none my-3" size="small" variant="text" @click="resendCode">
                                    {{ $t('LoginForm.resendCode') }}
                                </v-btn>
                            </div>
                        </template>
                    </v-text-field>
                </vee-field>
                <span
                    class="text-caption mt-4"
                    v-html="$t('LoginForm.twoFactorLost')"
                />
                <v-row class="mt-4" no-gutters>
                    <v-col>
                        <v-btn variant="text" @click="cancelTwoFactor">
                            {{ $t('OneWord.cancel') }}
                        </v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            :loading="loading"
                            color="primary"
                            variant="flat"
                            type="submit"
                        >
                            <v-icon start>mdi-login</v-icon>
                            {{ $t('LoginDialog.login') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </vee-form>
        </template>
        <template v-else>
            <vee-form ref="loginForm" @submit="login()">
                <vee-field
                    v-slot="{ errors, field }"
                    :label="$t('OneWord.email')"
                    rules="required|email|max:255"
                    name="email"
                >
                    <v-text-field
                        v-bind="field"
                        v-model="email"
                        :label="$t('OneWord.email')"
                        :error-messages="errors"
                        class="mb-2"
                        data-cy="login-form-email"
                        prepend-inner-icon="mdi-account"
                        type="email"
                    />
                </vee-field>
                <vee-field
                    v-slot="{ errors, field }"
                    :label="$t('OneWord.password')"
                    rules="required"
                    name="password"
                >
                    <v-text-field
                        v-bind="field"
                        v-model="password"
                        :label="$t('OneWord.password')"
                        :error-messages="errors"
                        data-cy="login-form-password"
                        prepend-inner-icon="mdi-form-textbox-password"
                        type="password"
                    />
                </vee-field>
                <v-btn
                    :loading="loading"
                    block
                    class="mt-2"
                    color="primary"
                    variant="flat"
                    size="large"
                    type="submit"
                >
                    <v-icon start>mdi-login</v-icon>
                    {{ $t('LoginDialog.login') }}
                </v-btn>
                <router-link :to="{name: 'send-password'}" data-cy="reset-password" class="font-weight-bold d-block mb-2 mt-3">
                    {{ $t('LoginDialog.resetPwd') }}
                </router-link>
                <router-link :to="{name:'register'}" data-cy="register" class="font-weight-bold d-block mb-2 mt-3">
                    {{ $t('LoginDialog.register') }}
                </router-link>
            </vee-form>
        </template>
    </div>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {mapActions} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import loadEnv from "@/plugins/env";

export default {
    name: 'LoginForm',
    components: {VeeForm, VeeField},
    props: {
        value: Boolean,
        path: String,
    },
    data() {
        return {
            email: null,
            password: null,
            loading: false,
            loginInvalid: false,

            hasError: false,
            errorMessage: null,
            twoFactorError: false,

            twoFactorAuth: false,
            twoFactorCode: null,
            twoFactorInvalid: null,
        };
    },
    computed: {},
    methods: {
        ...mapActions('user', {
            setUser: 'set',
        }),
        login() {
            this.loading = true;

            axios.get('/sanctum/csrf-cookie').then(() => {
                axios.post('/webapi/login', {
                    email: this.email,
                    password: this.password,
                }).then(response => {
                    if (_.get(response, 'data.data.two_factor', false)) {
                        this.twoFactorAuth = true;
                    } else {
                        this.doLogin(_.get(response, 'data.data'));
                    }
                }).catch(error => {
                    let code = _.get(error, 'response.data.code');
                    if (code === 10) {
                        this.twoFactorError = true;
                        this.errorMessage = this.$t('LoginForm.errorMessages.code-10');
                    } else if (_.has(error, 'response.status') && error.response.status === 419) {
                        this.hasError = true;
                        this.errorMessage = this.$t('LoginForm.errorMessages.code-419');
                    } else if (_.has(error, 'response.data.message')) {
                        this.hasError = true;
                        this.errorMessage = _.get(error, 'response.data.message');
                        if (_.get(error, 'response.data.errors')) {
                            this.$refs.loginForm.setErrors(_.get(error, 'response.data.errors'));
                        }
                    }

                    this.loginInvalid = true;
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        code() {
            this.loading = true;
            this.twoFactorInvalid = true;

            axios.post('/webapi/code', {
                code: this.twoFactorCode,
            }).then(response => {
                this.doLogin(_.get(response, 'data.data'));
            }).catch(error => {
                let code = _.get(error, 'response.data.code');
                if (code === 16) {
                    this.errorMessage = this.$t('LoginForm.errorMessages.code-16');
                } else if (_.has(error, 'response.data.message')) {
                    this.errorMessage = _.get(error, 'response.data.message');
                }

                this.hasError = true;
                this.twoFactorInvalid = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        doLogin() {
            loadEnv().then(({abilities}) => {

                this.$ability.update(abilities);

                if (_.isString(this.path) && this.path !== '/') {
                    this.$router.push({path: this.path});
                } else if (_.has(this.$route.query, 'redirect')) {
                    if (this.$route.query.redirect === 'dc') {
                        window.location.href = `${process.env.VUE_APP_DA_PUBLIC_URL}/vertraege-briefe/${this.$route.query.slug}?file=${this.$route.query.file}`;
                    } else if (this.$route.query.redirect === 'testament') {
                        this.$router.push({name: 'testament-tool'});
                    } else {
                        window.location.href = this.$route.query.redirect;
                    }
                } else if (this.$ability.can('read', 'Account')) {
                    if (window.Cypress) {
                        this.$router.push({name: 'testing-startpage'});
                    } else {
                        this.$router.push({name: 'admin-statistic'});
                    }
                } else {
                    this.$router.push({name: 'my-documents'});
                }
            });
        },
        cancelTwoFactor() {
            axios.post('/webapi/code-cancel', {
                code: this.twoFactorCode,
            }).finally(() => {
                EventBus.$emit('success', this.$t('LoginForm.cancelTwoFactorSuccess'));
                this.hasError = false;
                this.twoFactorAuth = false;
                this.twoFactorError = false;
            });
        },
        resendCode() {
            axios.post('/webapi/code-resend').then(() => {
                EventBus.$emit('success', this.$t('LoginForm.resendSuccess'));
            }).catch(error => {
                let code = _.get(error, 'response.data.code');
                if (code === 19) {
                    EventBus.$emit('error', this.$t('LoginForm.errorMessages.code-19'));
                } else if (_.has(error, 'response.data.message')) {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                }
            }).finally(() => {
                this.hasError = false;
                this.twoFactorError = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
