<template>
    <div>
        <v-alert v-if="isTranslation" class="mb-4" density="compact" type="warning">
            {{ $t('ProfileImagesForm.translationInfo') }}
        </v-alert>

        <v-card :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="text-h6 mb-2">
                            {{ $t('ImagesForm.titleLogo') }}
                        </h2>

                        <v-alert color="info" density="compact" variant="tonal">
                            {{ $t('ProfileImagesForm.logoImageInfoText') }}
                        </v-alert>

                        <v-alert class="mt-3" density="compact" variant="tonal" type="warning">
                            {{ $t('ProfileImagesForm.useInListsInfoText') }}
                        </v-alert>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                        <v-card
                            v-if="logoImage.url"
                            :color="logoImage.use_in_directory ? 'orange' : undefined"
                            variant="flat"
                            border
                        >
                            <v-card-text class="bg-white">
                                <v-img :src="logoImage.url" cover height="150" data-cy="profile-image-logo" />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    data-cy="profile-image-logo-delete"
                                    variant="flat"
                                    size="small"
                                    @click="removeFile(logoImage)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <v-spacer />
                                <span
                                    v-if="logoImage.use_in_directory"
                                    class="ml-4"
                                    v-html="$t('ProfileImagesForm.useInLists')"
                                />
                                <v-tooltip v-else location="bottom">
                                    <template #activator="{props}">
                                        <v-btn
                                            :color="logoImage.use_in_directory ? 'white' : 'primary'"
                                            variant="flat"
                                            size="small"
                                           
                                            v-bind="props"
                                            @click="setListImage(logoImage)"
                                        >
                                            {{ $t('ProfileImagesForm.useInListsBtn') }}
                                        </v-btn>
                                    </template>
                                    {{ $t('ProfileImagesForm.useInListsBtnToolTip') }}
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>

                        <Vue3Dropzone
                            v-if="!logoImage.url"
                            id="application-form-vue-dropzone-logo"
                            ref="dropzoneLogo"
                            accept="image/png,image/jpeg,image/gif,image/svg+xml"
                            :max-files="1"
                            :max-file-size="3"
                            :show-select-button="false"
                            data-cy="application-form-vue-dropzone-logo"
                            @update:model-value="e => handleDropzone(e, 'logo')"
                        />

                        <v-alert
                            :model-value="errors.logo !== null"
                            class="mt-3"
                            density="compact"
                            theme="light"
                            variant="tonal"
                            type="error"
                        >
                            {{ errors.logo }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4" :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="text-h6 mb-2">
                            {{ $t('ImagesForm.titlePerson') }}
                        </h2>

                        <v-alert color="info" density="compact" variant="tonal">
                            {{ $t('ProfileImagesForm.personImageInfoText') }}
                        </v-alert>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card
                            v-if="personImage.url"
                            :color="personImage.use_in_directory ? 'warning' : undefined"
                            variant="flat"
                            border
                        >
                            <v-card-text class="bg-white">
                                <v-img :src="personImage.url" cover height="150" data-cy="profile-image-person" />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="white"
                                    data-cy="profile-image-person-delete"
                                    variant="flat"
                                    size="small"
                                    @click="removeFile(personImage)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                                <v-spacer />
                                <span
                                    v-if="personImage.use_in_directory"
                                    class="ml-4"
                                    v-html="$t('ProfileImagesForm.useInLists')"
                                />
                                <v-tooltip v-else location="bottom">
                                    <template #activator="{props}">
                                        <v-btn
                                            :color="personImage.use_in_directory ? 'white' : 'primary'"
                                            variant="flat"
                                            size="small"
                                           
                                            v-bind="props"
                                            @click="setListImage(personImage)"
                                        >
                                            {{ $t('ProfileImagesForm.useInListsBtn') }}
                                        </v-btn>
                                    </template>
                                    {{ $t('ProfileImagesForm.useInListsBtnToolTip') }}
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>

                        <Vue3Dropzone
                            v-if="!personImage.url"
                            id="application-form-vue-dropzone-person"
                            ref="dropzonePerson"
                            data-cy="application-form-vue-dropzone-person"
                            accept="image/png,image/jpeg,image/gif,image/svg+xml"
                            :max-files="1"
                            :show-select-button="false"
                            @update:model-value="e => handleDropzone(e, 'person')"
                        />

                        <v-alert
                            :model-value="errors.person !== null"
                            class="mt-3"
                            density="compact"
                            theme="light"
                            variant="tonal"
                            type="error"
                        >
                            {{ errors.person }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4" :loading="loading">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="text-h6 mb-2">
                            {{ $t('ImagesForm.titleImages') }}
                        </h2>

                        <v-alert color="info" density="compact" variant="tonal">
                            {{ $t('ProfileImagesForm.profileImageInfoText') }}
                        </v-alert>
                    </v-col>

                    <v-col cols="12" md="6">
                        <Vue3Dropzone
                            id="application-form-vue-dropzone"  
                            ref="dropzone"
                            data-cy="application-form-vue-dropzone"
                            accept="image/png,image/jpeg,image/gif,image/svg+xml"
                            :show-select-button="false"
                            @update:model-value="e => handleDropzone(e, 'profile')"
                        />

                        <v-alert
                            :model-value="errors.profile !== null"
                            class="mt-3"
                            density="compact"
                            theme="light"
                            variant="tonal"
                            type="error"
                        >
                            {{ errors.profile }}
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row v-if="profileImages.length > 0" class="pa-3 -mx-4 -mb-4 mt-6 border-t bg-gray-100">
                    <v-col v-for="image in profileImages" :key="`image-${image.id}`" cols="12" lg="3">
                        <v-card v-if="image.url" variant="flat" border>
                            <v-img :src="image.url" cover height="150" data-cy="profile-image-profile" />
                            <v-card-actions>
                                <v-btn icon size="x-small" @click="removeFile(image)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions v-if="showActions">
                <v-btn variant="text" @click="$router.back()">{{ $t('OneWord.back') }}</v-btn>
                <v-btn :loading="loading" @click="$emit('save')">
                    <v-icon start>mdi-content-save</v-icon>
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- only show background settings in partner area, not in creation wizard -->
        <v-card v-if="isNgo && !showActions" class="mt-4" :loading="saving">
            <v-card-text>
                <v-row class="pa-3">
                    <v-col cols="12" md="6">
                        <h2 class="text-h6 mb-2">
                            {{ $t('ProfileImagesForm.background.headline') }}
                        </h2>

                        <p>
                            {{ $t('ProfileImagesForm.background.infoText') }}
                        </p>

                        <v-alert 
                            v-if="background.type === 'image'"
                            class="mt-3"
                            color="info"
                            density="compact"
                            variant="tonal"
                        >
                            {{ $t('ProfileImagesForm.background.image.infoText') }}
                        </v-alert>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="background.type"
                            :items="[
                                {title: $t('ProfileImagesForm.background.options.da'), value: 'da'},
                                {title: $t('ProfileImagesForm.background.options.gradient'), value: 'gradient'},
                                {title: $t('ProfileImagesForm.background.options.image'), value: 'image'},
                            ]"
                            :label="$t('ProfileImagesForm.background.label.background')"
                            hide-details
                            class="mb-5"
                        />

                        <v-row v-if="background.type === 'gradient'" class="mb-2">
                            <v-col>
                                <v-text-field v-model="background.gradient.start" hide-details class="ma-0 pa-0" variant="solo">
                                    <template #append>
                                        <v-menu
                                            v-model="showColorPicker.start"
                                            location="top"
                                            offset-bottom="105"
                                            offset-left="16"
                                            :close-on-content-click="false"
                                        >
                                            <template #activator="{ props }">
                                                <div :style="colorPickerStart" v-bind="props" />
                                            </template>
                                            <v-card>
                                                <v-card-text class="pa-0">
                                                    <v-color-picker v-model="background.gradient.start" :modes="['rgb']" variant="flat" />
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="background.gradient.end" hide-details class="ma-0 pa-0" variant="solo">
                                    <template #append>
                                        <v-menu
                                            v-model="showColorPicker.end"
                                            location="top"
                                            offset-bottom="105"
                                            offset-left="16"
                                            :close-on-content-click="false"
                                        >
                                            <template #activator="{ props }">
                                                <div :style="colorPickerEnd" v-bind="props" />
                                            </template>
                                            <v-card>
                                                <v-card-text class="pa-0">
                                                    <v-color-picker v-model="background.gradient.end" :modes="['rgb']" variant="flat" />
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <template v-if="background.type === 'image'">
                            <v-card
                                v-if="backgroundImage.url"
                                class="mb-5"
                                variant="flat"
                                border
                            >
                                <v-card-text class="bg-white">
                                    <v-img :src="backgroundImage.url" cover height="150" />
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        data-cy="profile-image-background-delete"
                                        variant="flat"
                                        size="small"
                                        @click="removeFile(backgroundImage)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                            <Vue3Dropzone
                                v-if="!backgroundImage.url"
                                id="application-form-vue-dropzone-background"
                                ref="dropzoneBackground"
                                accept="image/png,image/jpeg,image/gif,image/svg+xml"
                                :max-files="1"
                                :show-select-button="false"
                                data-cy="application-form-vue-dropzone-background"
                                class="mb-5"
                                @update:model-value="e => handleDropzone(e, 'background')"
                            />

                            <v-alert
                                :model-value="errors.background !== null"
                                class="mt-3"
                                density="compact"
                                theme="light"
                                variant="tonal"
                                type="error"
                            >
                                {{ errors.background }}
                            </v-alert>
                        </template>

                        <v-btn 
                            type="submit" 
                            color="accent" 
                            :loading="saving" 
                            @click="setBackground()"
                        >
                            <v-icon start>mdi-content-save</v-icon>
                            {{ $t('OneWord.save') }}
                        </v-btn>

                        <v-btn 
                            v-if="showPaletteReset"
                            type="submit" 
                            class="ml-3"
                            @click="resetPalette()"
                        >
                            <v-icon start>mdi-reload</v-icon>
                            {{ $t('ProfileImagesForm.background.gradient.reset') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="pa-3 -mx-4 -mb-4 mt-6 block border-t bg-gray-100">
                    <div class="mx-auto max-w-xl">
                        <small class="text-uppercase">{{ $t('ProfileImagesForm.background.preview.label') }}:</small>
                        <v-card 
                            border
                            class="affiliate-preview cursor-pointer flex-row border text-xxs"
                            align="center"
                            @click="openPreview()"
                        >
                            <div class="pa-2 text-left">
                                <div class="mx-auto max-w-1/2">
                                    <img data-v-04b37fa0="" :src="require('@/assets/img/deinadieu_logo.svg')" class="inline-block h-4" style="fill: white;">
                                    <div class="pa-1 float-right inline-block rounded bg-da-primary px-2 text-xxxs text-white">{{ $t('ProfileImagesForm.background.preview.login') }}</div>
                                </div>
                            </div>
                            <div class="flex-row pt-3" :style="backgroundPreview">
                                <div class="mt-2 w-1/2 rounded-lg bg-white">
                                    <div class="text-uppercase ma-2 inline-block rounded bg-gray-100 px-2 py-1.5">{{ $t('ProfileImagesForm.background.preview.logo') }}</div>
                                    <div class="text-uppercase ma-2 inline-block rounded bg-gray-100 px-2 py-1.5">{{ $t('ProfileImagesForm.background.preview.contact') }}</div>
                                </div>
                                <div class="pa-8 mb-5 mt-2 inline-block w-1/2 rounded-lg bg-white">
                                    <h3 class="mb-4 text-lg">{{ $t('ProfileImagesForm.background.preview.headline') }}</h3>
                                    <div class="pa-1 inline-block rounded bg-da-primary px-2 text-xxxs text-white">{{ $t('ProfileImagesForm.background.preview.button') }}</div>
                                </div>
                            </div>
                            <div class="pa-2 text-xxxs text-gray-600">{{ $t('ProfileImagesForm.background.preview.footer') }}</div>
                        </v-card>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="showPreviewDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ $t('ProfileImagesForm.background.preview.label') }}</v-card-title>
                <v-card-text>{{ $t('ProfileImagesForm.background.preview.info') }}</v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="showPreviewDialog = false">{{ $t('OneWord.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue3Dropzone from "@/components/Dropzone/Vue3Dropzone";
import '@/components/Dropzone/style.css'
import {ImagesToBase64} from '@/utils/images';
import {EventBus} from '@/utils/event-bus';
import {usei18n} from "@/plugins/i18n";

export default {
    name: 'ProfileImagesForm',
    components: {Vue3Dropzone},
    props: {
        accountId: Number,
        profile: Object,
        profileId: Number,
        lang: String,
        showActions: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            loading: false,
            saving: false,
            errors: {
                logo: null,
                person: null,
                profile: null,
                background: null,
            },
            personImage: {},
            logoImage: {},
            backgroundImage: {},
            profileImages: [],
            background: {
                type: 'da',
                gradient: {
                    start: null,
                    end: null,
                },
            },
            images: [],
            showColorPicker: {
                start: false,
                end: false,
            },
            showPreviewDialog: false,
        };
    },
    computed: {
        isTranslation() {
            return this.lang !== 'de_CH';
        },
        isNgo() {
            return _.map(_.get(this.profile, 'partner_types', []), 'id').includes(12);
        },
        showPaletteReset() {
            return this.background.type === 'gradient' && _.get(this.profile, 'logo_palette') && (
                _.get(this.background, 'gradient.start') !== _.get(this.profile, 'logo_palette.gradient.0') || 
                _.get(this.background, 'gradient.end') !== _.get(this.profile, 'logo_palette.gradient.1')
            );
        },
        colorPickerStart() {
            return {
                backgroundColor: this.background.gradient.start,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.showColorPicker.start ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        colorPickerEnd() {
            return {
                backgroundColor: this.background.gradient.end,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.showColorPicker.end ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        backgroundPreview() {
            // background gradient
            if (this.background.type === 'gradient') {
                return {
                    background: `linear-gradient(to bottom, ${this.background.gradient.start}, ${this.background.gradient.end})`,
                };
            }

            // custom background image
            if (this.background.type === 'image' && this.backgroundImage.url) {
                return {
                    backgroundImage: 'url(' + this.backgroundImage.url + ')',
                    backgroundSize: 'cover',
                    backgroundColor: '#eee',
                };
            }

            // default: DA background image
            return {
                backgroundImage: 'url(' + require('@/assets/img/app/bg/da-colors.png') + ')',
                backgroundSize: 'cover',
            };
        },
    },
    watch: {
        images(value) {
            if (!_.isEmpty(value)) {
                this.loadImages();
            }
        },
    },

    mounted() {
        // set default values for affiliate background
        this.background.type = _.get(this.profile, 'background.type') || 'da';
        this.backgroundImage = _.get(this.profile, 'background_image') || {};

        if (_.get(this.profile, 'background.type') === 'gradient') {
            this.background.gradient.start = _.get(this.profile, 'background.gradient.start');
            this.background.gradient.end = _.get(this.profile, 'background.gradient.end');
        } else {
            this.resetPalette();
        }

        this.load();
    },
    methods: {
        setListImage(image) {
            this.loading = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images/${image.id}`;
            axios.put(url, {use_in_directory: true}).then(() => {
                EventBus.$emit('success');
                this.load();
            }).finally(() => {
                this.loading = false;
            });
        },

        setBackground() {
            this.saving = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/background`;
            axios.put(url, this.background, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(() => {
                EventBus.$emit('success');
            }).catch(errors => {
                if (errors.response.data.errors.background) {
                    this.errors.background = this.$t('ProfileImagesForm.errors.backgroundImageRequired');
                }
            }).finally(() => {
                this.$emit('reload-profile');
                this.saving = false;
            });
        },

        openPreview() {
            if (this.profile.background.type !== this.background.type) {
                this.showPreviewDialog = true;
                return;
            }

            window.open(this.profile.backlinks.testament[usei18n().locale] + '?preview=true', '_blank');
        },

        resetPalette() {
            this.background.gradient.start = _.get(this.profile, 'logo_palette.gradient.0', '#2f1f3c');
            this.background.gradient.end = _.get(this.profile, 'logo_palette.gradient.1', '#867197');
        },

        handleDropzone(files, type) {
            if (files.length === 0) {
                return;
            }

            this.loading = true;
            ImagesToBase64.fromDropzone(files).then(res => {
                _.forEach(res, image => {
                    image['type'] = type;
                    axios.post(`/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images`, image, {
                        headers: {
                            'DA-Accept-Language': this.lang,
                        },
                    }).then(response => {
                        this.errors.person = null;
                        this.errors.logo = null;
                        this.errors.profile = null;
                        this.errors.background = null;

                        if (type === 'logo') {
                            this.$emit('reload-profile');

                            if (_.get(this.profile, 'background.type') !== 'gradient') {
                                this.background.gradient.start = _.get(response.data, 'data.palette.gradient.0');
                                this.background.gradient.end = _.get(response.data, 'data.palette.gradient.1');
                            }
                        }

                        this.setImage(response.data.data);
                    }).catch(error => {
                        if (type === 'logo') {
                            this.errors.logo = _.get(error, 'response.data.errors.data[0]', _.get(error, 'statusText'));
                            this.$refs.dropzoneLogo.clearPreview();
                        }
                        if (type === 'background') {
                            this.errors.background = _.get(error, 'response.data.errors.data[0]', _.get(error, 'statusText'));
                            this.$refs.dropzoneBackground.clearPreview();
                        }
                        if (type === 'person') {
                            if (_.has(error, 'response.data.errors.data[0]')) {
                                this.errors.person = _.get(error, 'response.data.errors.data[0]');
                            } else {
                                this.errors.person = _.get(error, 'statusText');
                            }
                            this.$refs.dropzonePerson.clearPreview();
                        }
                        if (type === 'profile') {
                            if (_.has(error.data, 'exception')) {
                                this.errors.profile = this.$t('ProfileImagesForm.fileExistsException');
                            }
                            else {
                                this.errors.profile = _.get(
                                    error, 'response.data.errors.data[0]',
                                    _.get(error, 'statusText'),
                                );
                            }
                            this.$refs.dropzone.clearPreview();
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            });
        },

        removeFile(file) {
            if (!_.has(file, 'id')) {
                return;
            }

            this.$root.$confirm(this.$t('OneWord.delete'), this.$t('ConfirmDelete', {parameter: file.name})).
                then((confirm) => {
                    if (confirm) {
                        axios.delete(`/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images/${file.id}`).
                            finally(() => {
                                this.loading = false;

                                if (file.type === 'logo') {
                                    this.logoImage = {};
                                }
                                if (file.type === 'person') {
                                    this.personImage = {};
                                }
                                if (file.type === 'background') {
                                    this.backgroundImage = {};
                                }
                                if (file.type === 'profile') {
                                    this.profileImages.splice(_.findIndex(this.profileImages, {id: file.id}), 1);
                                }

                                this.load();
                            });
                    }
                });
        },

        setImage(image) {
            image['name'] = image.url.substring(image.url.lastIndexOf('/') + 1);

            if (image.type === 'logo') {
                this.logoImage = image;
            }
            if (image.type === 'person') {
                this.personImage = image;
            }
            if (image.type === 'background') {
                this.backgroundImage = image;
            }
            if (image.type === 'profile') {
                this.profileImages.push(image);
                this.$refs.dropzone.clearPreview();
            }
        },
        loadImages() {
            if (_.has(this.$refs, 'dropzone')) {
                this.profileImages = [];
                _.forEach(this.images, item => {
                    let imageMock = {
                        url: item.url,
                        name: item.url.substring(item.url.lastIndexOf('/') + 1),
                        size: item.size,
                        id: item.id,
                        type: item.type,
                        use_in_directory: item.use_in_directory,
                    };

                    if (item.type === 'logo') {
                        this.logoImage = imageMock;
                    }
                    else if (item.type === 'person') {
                        this.personImage = imageMock;
                    }
                    else if (item.type === 'background') {
                        this.backgroundImage = imageMock;
                    }
                    else {
                        this.profileImages.push(imageMock);
                    }
                });
            }
        },

        load() {
            this.loading = true;
            let url = `/webapi/accounts/${this.accountId}/profiles/${this.profileId}/images`;
            axios.get(url, {
                headers: {
                    'DA-Accept-Language': this.lang,
                },
            }).then(response => {
                this.images = response.data.data;
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
