<template>
    <div class="affiliate-agreement px-2 py-0 text-center sm:px-0 lg:py-6">
        <affiliate-header />

        <v-sheet
            :class="{'pa-10': $vuetify.display.lgAndUp}"
            class="pa-5 mb-4 mt-2 rounded-md text-gray-600 lg:rounded-xl"
            data-cy="affiliate-agreement"
        >
            <template v-if="!hasProfile">
                <img
                    v-if="getLogo"
                    :src="getLogo"
                    class="mx-auto mb-6 h-20"
                    data-cy="affiliate-tenant-logo"
                >
                <img 
                    v-else
                    :src="require('@/assets/img/deinadieu_logo.svg')" 
                    class="mx-auto mb-6 h-20"
                    data-cy="affiliate-da-logo"
                    style="fill: white"
                >
            </template>

            <div class="mb-2 text-3xl font-medium text-black">
                <h1 v-if="isWhiteLabel && $te(`AffiliateToolPopover.${tool}.welcomeText`)">
                    {{ $t(`AffiliateToolPopover.${tool}.welcomeText`) }}
                </h1>
                <h1 v-else-if="$te(`AffiliateToolPopover.${tool}.headline`)">
                    {{ $t(`AffiliateToolPopover.${tool}.headline`) }}
                </h1>
                <h1 v-else>
                    {{ $t(`AffiliateToolPopover.welcomeText`) }}
                </h1>
            </div>

            <div class="mb-4 text-xl">
                {{ $t(`AffiliateToolPopover.${tool}.infoText`) }}
            </div>

            <div v-for="i in numRows" :key="`list-${i}`" class="mt-5 inline-block px-4 text-left sm:text-center">
                <v-icon class="-mt-1 mr-1" color="green">mdi-check-circle-outline</v-icon>
                {{ $t(`AffiliateToolPopover.${tool}.listText.${i}`) }}
            </div>

            <div class="mt-8 text-black">
                <v-checkbox
                    v-if="!isAuthenticated"
                    v-model="agreementAccepted"
                    :false-value="false"
                    :true-value="true"
                    class="mb-8 text-left"
                    data-cy="switch-affiliate-agreement"
                    hide-details
                >
                    <template #label>
                        <small v-if="showSimpleAcceptLabel" data-cy="accept-label-simple" class="pl-2" v-html="$t('Affiliate.simple.acceptLabel')" />
                        <small v-else-if="showAffiliateAcceptLabel" data-cy="accept-label-affiliate" class="pl-2" v-html="$t('Affiliate.affiliate.acceptLabel')" />
                        <small v-else data-cy="accept-label-general" class="pl-2" v-html="$t('Affiliate.general.acceptLabel')" />
                    </template>
                </v-checkbox>

                <v-btn
                    :disabled="!agreementAccepted"
                    class="text-none mx-auto rounded-lg"
                    color="primary"
                    data-cy="affiliate-btn-start"
                    variant="flat"
                    size="x-large"
                    @click="start"
                >
                    <template v-if="$te(`AffiliateToolPopover.${tool}.btnStart`)">{{ $t(`AffiliateToolPopover.${tool}.btnStart`) }}</template>
                    <template v-else>{{ $t('AffiliateToolPopover.btnStart') }}</template>
                </v-btn>
            </div>
        </v-sheet>

        <div v-if="!tool" data-cy="tool-undefined"><!-- keep this for testing --></div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AffiliateHeader from './AffiliateHeader.vue';

export default {
    name: 'Affiliate',

    components: {
        AffiliateHeader,
    },

    props: {
        tool: String,
    },

    data: () => {
        return {
            agreementAccepted: false,
        };
    },

    computed: {
        ...mapGetters('affiliateProfile', [
            'profile',
            'hasProfile',
        ]),

        ...mapGetters('env', [
            'isAuthenticated',
            'isWhiteLabel',
            'getLogo',
        ]),

        profileImageUrl() {
            return _.get(this.profile, 'logo.url');
        },

        hasProfileImage() {
            return !_.isEmpty(_.get(this.profile, 'person_image.url'));
        },

        numRows() {
            return _.get({
                testament: 3,
                patientDecree: 3,
                advanceCareDirective: 3,
                consulting: 3,
            }, this.tool);
        },

        columnWidthClass() {
            return !this.isWhitelabel && this.hasProfile ? 'w-1/2' : 'w-full';
        },

        showSimpleAcceptLabel() {
            return ! this.hasProfile && ['patientDecree', 'advanceCareDirective'].includes(this.tool);
        },

        showAffiliateAcceptLabel() {
            return this.hasProfile && ['patientDecree', 'advanceCareDirective'].includes(this.tool);
        },
    },

    watch: {
        // using a watcher to update agreementAccepted after login/logout
        isAuthenticated: {
            immediate: true,
            handler(value) {
                this.agreementAccepted = value
            },
        }
    },

    methods: {
        setAgreementAccepted(value) {
            return this.$store.dispatch(this.tool + '/setAgreementAccepted', value);
        },
        
        start() {
            this.setAgreementAccepted(this.agreementAccepted);
            this.$emit('start-tool');
        },
    },
};
</script>

<style lang="scss" scoped>
.affiliate-agreement :deep(label) {
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
}

.affiliate-agreement :deep(label) a {
    color: rgb(var(--v-theme-primary));
}
</style>