import { createStore } from 'vuex';
import modules from './modules';
import VuexPersistence from 'vuex-persist';

const debug = process.env.NODE_ENV !== 'production';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: [
        'advanceCareDirective',
        'consulting',
        'patientDecree',
        'testament',
    ],
});

const store = createStore({
    modules: modules,
    strict: debug,
    plugins: [vuexLocal.plugin],
});

export default store;